import React from 'react';
import {Map, TileLayer, Marker, Popup, Circle} from 'react-leaflet';
import Search from 'react-leaflet-search/lib';
import {IconSelector} from '../../components/IconSelector/IconSelector';
import TextButton from '../../components/TextButton';
import L from 'leaflet';
import {ControlLabel} from 'react-bootstrap';
import {ClipLoader} from 'react-spinners';

const EventMap = ({
  mapRef,
  geoLoc,
  zoom,
  newCoord,
  markers,
  showEvents,
  showVehicles,
  events,
  eventsLoading,
  onEventsChanged,
  onGeoLocChanged,
  onZoomChanged,
  onMapClick,
}) => {
  const geocoder = L.Control.Geocoder.nominatim();

  markers = showVehicles ? markers : markers.filter(m => m.relocate === true);

  // sets the center of the map to the location of the user
  const geoLocation = () => {
    const success = position => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      const info = {
        latLng: {lat: latitude, lng: longitude},
      };
      onGeoLocChanged(info);
    };

    if (!navigator.geolocation) {
      return alert('Geolocation is not supported by your browser');
    } else {
      return navigator.geolocation.getCurrentPosition(success, () =>
        alert('Unable to retrieve your location')
      );
    }
  };

  // sets the center of the map to the location where the user clicked
  const handleClickMapClick = e => {
    geocoder.reverse(e.latlng, mapRef.current.leafletElement.getZoom(), results => {
      var r = results[0];
      if (r) {
        onMapClick(r);
      } else {
        return alert('There is no address here...');
      }
    });
  };

  return (
    <div>
      <ControlLabel style={{marginTop: '5px'}}>
        <TextButton
          caption="Locate me"
          alt="Ask for geolocation"
          type="submit"
          onClick={() => {
            geoLocation();
          }}
        />
      </ControlLabel>
      <Map
        ref={mapRef}
        center={geoLoc}
        zoom={zoom}
        style={{width: '100%', height: '50vh', marginTop: 7.5}}
        onClick={handleClickMapClick}
        onDragEnd={e => {
          const mapCenter = e.target.getCenter();
          const info = {
            latLng: {
              lat: mapCenter.lat,
              lng: mapCenter.lng,
            },
          };
          onGeoLocChanged(info);
        }}
        onZoomEnd={e => onZoomChanged(e.target.getZoom())}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Search
          onChange={info => {
            onGeoLocChanged(info);
          }}
          position="topright"
          inputPlaceholder="Search for a location"
          closeResultsOnClick={true}
          openSearchOnLoad={false}
          showMarker={false}
          showPopup={false}
        ></Search>
        {newCoord.length > 0 && (
          <>
            {newCoord.map((coord, index) => {
              return (
                <Circle
                  key={index}
                  center={[coord.lat, coord.lng]}
                  radius={200}
                  pathOptions={{color: 'blue'}}
                />
              );
            })}
          </>
        )}
        {markers.length === 0 && newCoord.length < 1
          ? ''
          : markers.map((value, index) => {
              return (
                <>
                  <Marker
                    position={value.position}
                    icon={value.icon}
                    onMouseOver={e => {
                      e.target.openPopup();
                    }}
                    onMouseOut={e => {
                      e.target.closePopup();
                    }}
                  >
                    {value.relocate === true ? (
                      <Popup>
                        <b>{value.id}</b>
                        <br />
                        is being relocated to
                        <br />
                        <b>{value.place}</b>
                      </Popup>
                    ) : (
                      <Popup>{value.id}</Popup>
                    )}
                  </Marker>
                </>
              );
            })}
        {showEvents
          ? events.map((value, index) => {
              const icon = IconSelector({vehicleType: 'Event-Icon'});
              return (
                <Marker
                  key={index}
                  icon={icon}
                  position={{
                    lat: value.location[1],
                    lng: value.location[0],
                  }}
                >
                  <Popup>
                    <b>{value.title}</b>
                    <br />
                    {value.description}
                    <br />
                    On the{' '}
                    <b>
                      {`${new Date(value.start).toDateString()}
                                    `}
                    </b>
                    ,
                    <br />
                    {value.phq_attendance
                      ? `for ${value.phq_attendance} number of people.`
                      : ''}
                    <br />
                    Labels: {value.labels.join(', ')}
                    <TextButton
                      caption={
                        newCoord.find(
                          coord =>
                            coord.lat === value.location[1] &&
                            coord.lng === value.location[0]
                        )
                          ? 'Remove Event for Relocating Vehicles List'
                          : 'Add Event for Relocating Vehicles List'
                      }
                      alt="Ask for recommandation"
                      type="submit"
                      onClick={() => {
                        onEventsChanged(value);
                      }}
                    />
                  </Popup>
                </Marker>
              );
            })
          : null}
      </Map>
      {eventsLoading ? (
        <div
          style={{
            width: 150,
            position: 'absolute',
            bottom: -10,
            left: 10,
            zIndex: 1000,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
          }}
        >
          <ClipLoader size={30} color={'#686868'} />
          <p style={{color: '#686868'}}>loading events...</p>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default EventMap;
