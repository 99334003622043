import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import {
  fetchStationByIdFailure,
  fetchStationByIdStarted,
  fetchStationByIdSuccess,
} from '../stores/station/station.action';
import {getStationById} from '../contracts/Stations/getStationById';
import {getVehiclesByStation} from '../contracts/Stations/getVehiclesByStation';

export default function useFetchStationById(id) {
  const dispatch = useDispatch();
  const {byId, error, loading, lastUpdated} = useSelector(state => state.Stations);
  const data = byId[id];
  useEffect(() => {
    const source = axios.CancelToken.source();
    const TTL = 1000 * 60;

    const wasFetchedBefore = lastUpdated;
    const isExpired =
      wasFetchedBefore && new Date().getTime() - lastUpdated.getTime() > TTL;
    if ((wasFetchedBefore && !isExpired) || loading) return;

    const fetchStationById = async () => {
      try {
        dispatch(fetchStationByIdStarted());
        const station = await getStationById(id);
        const vehiclesInStation = await getVehiclesByStation(station.stationId);

        dispatch(
          fetchStationByIdSuccess({
            vehiclesInStation: vehiclesInStation,
            ...station,
          })
        );
      } catch (error) {
        dispatch(fetchStationByIdFailure(error));
      }
    };

    fetchStationById();

    return () => source.cancel();
  }, [dispatch, id, lastUpdated, loading]);

  return [data, error, loading];
}
