//@flow

import React from 'react';
import Card from '../../components/Card';
import {connect} from 'react-redux';
import {ServiceOfferForm} from '../../pages/ServiceOfferForm';

const ServiceOfferEditCard = ({serviceOffer, onSubmit}) => {
  if (!serviceOffer) {
    return (
      <Card>
        <Card.Header>Service Offer Details</Card.Header>
      </Card>
    );
  }

  const handleSubmit = values => {
    onSubmit(values);
  };

  return (
    <Card>
      <Card.Header>Service Offer Details</Card.Header>
      <Card.Content>
        <ServiceOfferForm
          onSubmit={values => handleSubmit(values)}
          initialValues={serviceOffer}
        />
      </Card.Content>
    </Card>
  );
};

const mapStateToProps = (state, ownProps) => ({
  serviceOffer: ownProps.serviceOffer,
});

export default connect(mapStateToProps)(ServiceOfferEditCard);
