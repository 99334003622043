// @flow

import type {Provider} from './provider.types';

export const FETCH_PROVIDERS_STARTED: 'FETCH_PROVIDERS_STARTED' =
  'FETCH_PROVIDERS_STARTED';
export const FETCH_PROVIDERS_SUCCESS: 'FETCH_PROVIDERS_SUCCESS' =
  'FETCH_PROVIDERS_SUCCESS';
export const FETCH_PROVIDERS_FAILURE: 'FETCH_PROVIDERS_FAILURE' =
  'FETCH_PROVIDERS_FAILURE';

export type FETCH_PROVIDERS_STARTED_ACTION = {
  type: typeof FETCH_PROVIDERS_STARTED,
};

export type FETCH_PROVIDERS_SUCCESS_ACTION = {
  type: typeof FETCH_PROVIDERS_SUCCESS,
  payload: {id: Provider},
  allIds: string[],
};

export type FETCH_PROVIDERS_FAILURE_ACTION = {
  type: typeof FETCH_PROVIDERS_FAILURE,
  payload: any,
};

export const FETCH_PROVIDER_BY_ID_STARTED = 'FETCH_PROVIDER_BY_ID_STARTED';
export const FETCH_PROVIDER_BY_ID_SUCCESS = 'FETCH_PROVIDER_BY_ID_SUCCESS';
export const FETCH_PROVIDER_BY_ID_FAILURE = 'FETCH_PROVIDER_BY_ID_FAILURE';

export type FETCH_PROVIDER_BY_ID_STARTED_ACTION = {
  type: typeof FETCH_PROVIDER_BY_ID_STARTED,
};

export type FETCH_PROVIDER_BY_ID_SUCCESS_ACTION = {
  type: typeof FETCH_PROVIDER_BY_ID_SUCCESS,
  payload: Provider,
  allIds: string[],
};

export type FETCH_PROVIDER_BY_ID_FAILURE_ACTION = {
  type: typeof FETCH_PROVIDER_BY_ID_FAILURE,
  payload: any,
};

export const ADD_PROVIDER_STARTED: 'ADD_PROVIDER_STARTED' = 'ADD_PROVIDER_STARTED';
export const ADD_PROVIDER_SUCCESS: 'ADD_PROVIDER_SUCCESS' = 'ADD_PROVIDER_SUCCESS';
export const ADD_PROVIDER_FAILURE: 'ADD_PROVIDER_FAILURE' = 'ADD_PROVIDER_FAILURE';

export type ADD_PROVIDER_STARTED_ACTION = {
  type: typeof ADD_PROVIDER_STARTED,
};

export type ADD_PROVIDER_SUCCESS_ACTION = {
  type: typeof ADD_PROVIDER_SUCCESS,
  payload: Provider,
};

export type ADD_PROVIDER_FAILURE_ACTION = {
  type: typeof ADD_PROVIDER_FAILURE,
  payload: any,
};

export const UPDATE_PROVIDER_STARTED: 'UPDATE_PROVIDER_STARTED' =
  'UPDATE_PROVIDER_STARTED';
export const UPDATE_PROVIDER_SUCCESS: 'UPDATE_PROVIDER_SUCCESS' =
  'UPDATE_PROVIDER_SUCCESS';
export const UPDATE_PROVIDER_FAILURE: 'UPDATE_PROVIDER_FAILURE' =
  'UPDATE_PROVIDER_FAILURE';

export type UPDATE_PROVIDER_STARTED_ACTION = {
  type: typeof UPDATE_PROVIDER_STARTED,
};

export type UPDATE_PROVIDER_SUCCESS_ACTION = {
  type: typeof UPDATE_PROVIDER_SUCCESS,
  payload: Provider,
};

export type UPDATE_PROVIDER_FAILURE_ACTION = {
  type: typeof UPDATE_PROVIDER_FAILURE,
  payload: any,
};

type Action =
  | FETCH_PROVIDERS_STARTED_ACTION
  | FETCH_PROVIDERS_SUCCESS_ACTION
  | FETCH_PROVIDERS_FAILURE_ACTION
  | FETCH_PROVIDER_BY_ID_STARTED_ACTION
  | FETCH_PROVIDER_BY_ID_SUCCESS_ACTION
  | FETCH_PROVIDER_BY_ID_FAILURE_ACTION
  | ADD_PROVIDER_STARTED_ACTION
  | ADD_PROVIDER_SUCCESS_ACTION
  | ADD_PROVIDER_FAILURE_ACTION
  | UPDATE_PROVIDER_STARTED_ACTION
  | UPDATE_PROVIDER_SUCCESS_ACTION
  | UPDATE_PROVIDER_FAILURE_ACTION;

type State = {
  byId: {
    id?: Provider,
  },
  allIds: string[],
  loading: boolean,
  error: any,
  lastUpdated?: Date,
};

const initialState: State = {
  byId: {},
  allIds: [],
  loading: false,
  error: null,
};

export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case FETCH_PROVIDERS_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PROVIDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        byId: action.payload,
        allIds: action.allIds,
        lastUpdated: new Date(),
      };
    case FETCH_PROVIDERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_PROVIDER_BY_ID_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PROVIDER_BY_ID_SUCCESS:
      const provider = action.payload;
      return {
        ...state,
        loading: false,
        error: null,
        byId: {...state.byId, [provider.providerId]: {...provider}},
        lastUpdated: new Date(),
      };
    case ADD_PROVIDER_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_PROVIDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        byId: {
          ...state.byId,
          [action.payload.providerId]: {
            ...action.payload,
          },
        },
        allIds: state.allIds.find(id => id === action.payload.providerId)
          ? state.allIds
          : [...state.allIds, action.payload.providerId],
      };
    case ADD_PROVIDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_PROVIDER_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_PROVIDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        byId: {...state.byId, [action.payload.providerId]: {...action.payload}},
      };
    case UPDATE_PROVIDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
