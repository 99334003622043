export const notificationsOptions = (type, title, message, action) => {
  const notificationsOps = {
    type: type,
    title: title,
    message: message,
    position: 'bc',
    autoDismiss: 5,
    action: action,
  };
  return notificationsOps;
};
