import defaultBackground from '../../assets/images/sidebar-1.jpg';
import {TinyColor} from '@ctrl/tinycolor';

export const SET_ENABLE_BACKGROUND_IMAGE = 'THEME_OPTIONS/SET_ENABLE_BACKGROUND_IMAGE';
export const SET_BACKGROUND_COLOR = 'THEME_OPTIONS/SET_BACKGROUND_COLOR';
export const SET_BACKGROUND_IMAGE = 'THEME_OPTIONS/SET_BACKGROUND_IMAGE';
export const SET_ACCENT_COLOR = 'THEME_OPTIONS/SET_ACCENT_COLOR';

export const ACCENT_COLORS = {
  PURPLE: '#0D4D99',
};

export const setEnableBackgroundImage = enableBackgroundImage => ({
  type: SET_ENABLE_BACKGROUND_IMAGE,
  enableBackgroundImage,
});

export const setBackgroundColor = backgroundColor => ({
  type: SET_BACKGROUND_COLOR,
  backgroundColor,
});

export const setBackgroundImage = backgroundImage => ({
  type: SET_BACKGROUND_IMAGE,
  backgroundImage,
});

export const setAccentColor = accentColor => ({
  type: SET_ACCENT_COLOR,
  accentColor,
});

export default function reducer(
  state = {
    highlightTextColor: ACCENT_COLORS.PURPLE,
    ctaButtonColor: ACCENT_COLORS.PURPLE,
    backgroundColor: ACCENT_COLORS.PURPLE,
    softBackgroundColor: new TinyColor(ACCENT_COLORS.PURPLE).brighten().toRgbString(),
    enableBackgroundImage: false,
    backgroundImage: defaultBackground,
  },
  action
) {
  switch (action.type) {
    case SET_ACCENT_COLOR:
      return {
        ...state,
        highlightTextColor: action.accentColor,
        ctaButtonColor: action.accentColor,
        backgroundColor: action.accentColor,
        semiTransparentBackgroundColor: new TinyColor(action.accentColor)
          .setAlpha(0.5)
          .toRgbString(),
        softBackgroundColor: new TinyColor(action.accentColor).brighten().toRgbString(),
      };

    case SET_ENABLE_BACKGROUND_IMAGE:
      return {
        ...state,
        enableBackgroundImage: action.enableBackgroundImage,
      };

    case SET_BACKGROUND_COLOR:
      return {
        ...state,
        backgroundColor: action.backgroundColor,
      };

    case SET_BACKGROUND_IMAGE:
      return {
        ...state,
        backgroundImage: action.backgroundImage,
      };
    default:
      return state;
  }
}
