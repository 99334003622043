import React from 'react';
import cx from 'classnames';

const TextBox = ({
  input,
  label,
  meta: {touched, error, warning},
  inputClassName,
  placeholder,
  helpText,
  disabled,
  value,
}) => (
  <div>
    <textarea
      {...input}
      className={cx(inputClassName, 'form-control', {
        error: !!error,
      })}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      rows={5}
    />

    {touched && error && (
      <label className="error" htmlFor={input.name}>
        {error}
      </label>
    )}

    {helpText && <span className="help-block">{helpText}</span>}
  </div>
);

export default TextBox;
