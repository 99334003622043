import React from 'react';
import {Field, reduxForm} from 'redux-form';
import renderField from '../../components/FormField';
import {ControlLabel, FormGroup} from 'react-bootstrap';
import TextButton from '../../components/TextButton';
import {connect} from 'react-redux';
import {addProvider} from '../../stores/provider/provider.actions';
import {showNotification} from '../../stores/ui/layout.reducer';
import {notificationsOptions} from '../../helpers';

const ProviderForm = ({handleSubmit, createProvider, showNotification}) => {
  const initProviders = async values => {
    const result = await createProvider({
      providerName: values.providerName,
    });

    let notificationsOps;
    if (result.type === 'ADD_PROVIDER_SUCCESS') {
      notificationsOps = notificationsOptions(
        'success',
        'Add Provider',
        'Provider added successfully'
      );
    } else if (result.type === 'ADD_PROVIDER_FAILURE') {
      notificationsOps = notificationsOptions(
        'error',
        'Create Provider',
        'Provider could not be added'
      );
    }
    showNotification(notificationsOps);
  };

  return (
    <form onSubmit={handleSubmit(initProviders)}>
      <FormGroup className="col-md-12 col-padding">
        <ControlLabel>provider name</ControlLabel>
        <Field type="text" name="providerName" validate={[]} component={renderField} />
      </FormGroup>
      <FormGroup className="text-center">
        <TextButton caption="Submit" type="submit" />
      </FormGroup>
    </form>
  );
};

const mapDispatchToProps = dispatch => ({
  createProvider: provider => dispatch(addProvider(provider)),
  showNotification: notificationsOps => dispatch(showNotification(notificationsOps)),
});

export default reduxForm({
  form: 'providerForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(connect(null, mapDispatchToProps)(ProviderForm));
