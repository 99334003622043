import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {usePrevious} from './usePrevious';
import axios from 'axios';
import {
  fetchServiceRequestsFailure,
  fetchServiceRequestsStarted,
  fetchServiceRequestsSuccess,
} from '../stores/serviceRequest/serviceRequest.actions';
import {schema, normalize} from 'normalizr';
import {getServiceRequests} from '../contracts/ServiceRequestRegistry/contractMethods/getServiceRequests';
import {getServiceRequestById} from '../contracts/ServiceRequestRegistry/contractMethods/getServiceRequestById';

const serviceRequestSchema = new schema.Entity(
  'serviceRequests',
  {},
  {idAttribute: 'serviceRequestId'}
);

const serviceRequestListSchema = new schema.Array(serviceRequestSchema);

export default function useFetchServiceRequests() {
  const dispatch = useDispatch();
  const {byId, error, loading, lastUpdated} = useSelector(state => state.ServiceRequests);
  const {role} = useSelector(state => state.User);
  const providerId = useSelector(state => state.User.selectedProvider?.providerId);
  const prevProviderId = usePrevious(providerId);
  const prevRole = usePrevious(role);

  useEffect(() => {
    const roleChanged = prevRole !== role;
    const providerChanged = prevProviderId !== providerId;

    const source = axios.CancelToken.source();
    const TTL = 1000 * 60;

    const wasFetchedBefore = lastUpdated;
    const isExpired =
      wasFetchedBefore && new Date().getTime() - lastUpdated.getTime() > TTL;
    if ((wasFetchedBefore && !isExpired && !roleChanged && !providerChanged) || loading)
      return;

    const fetchServiceRequests = async () => {
      dispatch(fetchServiceRequestsStarted());
      try {
        //getServiceRequests also returns the ids of deleted requests
        const serviceRequests = await getServiceRequests();

        const filtered_ServiceRequests = serviceRequests.filter(
          serviceRequest =>
            serviceRequest.requesterId === providerId || role === 'Service Provider'
        );

        const normalizedData = normalize(
          filtered_ServiceRequests,
          serviceRequestListSchema
        );
        dispatch(
          fetchServiceRequestsSuccess(
            normalizedData.entities['serviceRequests'] ?? {},
            normalizedData.result
          )
        );
      } catch (error) {
        dispatch(fetchServiceRequestsFailure(error));
      }
    };

    fetchServiceRequests();

    return () => source.cancel();
  }, [dispatch, lastUpdated, role, providerId, prevRole, prevProviderId, loading]);

  return [byId, error, loading];
}
