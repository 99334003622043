import React from 'react';
import {Col} from 'react-bootstrap';
import Card from '../../components/Card';
import {JobForm} from './index';
import {connect, useSelector} from 'react-redux';
import NotFound from '../../components/NotFound/NotFound';
import ServiceRequestDetailsCard from '../../components/ServiceRequestDetailsCard';
import {addJob} from '../../stores/job/job.actions';
import useFetchVehicleById from '../../hooks/useFetchVehicleById';
import {useHistory} from 'react-router-dom';
import {notificationsOptions} from '../../helpers';
import {showNotification} from '../../stores/ui/layout.reducer';

const JobFormPage = ({serviceRequest, createJob, showNotification}) => {
  const [vehicle, error, v_loading] = useFetchVehicleById(serviceRequest?.vehicleId);
  const {loading} = useSelector(state => state.Jobs);
  const history = useHistory();

  if (error || !serviceRequest) {
    return <NotFound entityName={'serviceRequest'} />;
  }

  if (loading) {
    return (
      <div className="main-content">
        <Card>
          <Card.Header>Loading...</Card.Header>
          <Card.Content isLoading={true} />
        </Card>
      </div>
    );
  }

  const serviceRequestId = serviceRequest.serviceRequestId;
  const vehicleId = vehicle.vehicleId;

  const handleSubmit = async values => {
    const job = {
      // remove . and parseInt afterwards correspondonds to mult with 100
      // price is stored in uint256 (cents) on blockchain
      price: parseInt(values.price.replace('.', ''), 10),
      description: values.description,
      serviceRequestId: serviceRequestId,
      vehicleId: vehicleId,
    };
    const result = await createJob(job);

    let notificationsOps;
    if (result.type === 'ADD_JOB_SUCCESS') {
      notificationsOps = notificationsOptions(
        'success',
        'Apply for service request',
        'Successfully applied for service request'
      );
      history.push(`/jobs`);
    } else if (result.type === 'ADD_JOB_FAILURE') {
      notificationsOps = notificationsOptions(
        'error',
        'Apply for service request',
        'Application on service request failed'
      );
    }
    showNotification(notificationsOps);
  };

  return (
    <div className="main-content">
      <Col lg={12} className="col-padding">
        <Col lg={6} className="col-padding">
          <Card>
            <Card.Header hasDivider={true}>
              Apply for service request {serviceRequest.serviceRequestId}
            </Card.Header>
            <Card.Content>
              <JobForm
                onSubmit={values => handleSubmit(values)}
                initialValues={{price: '0.00€'}}
              />
            </Card.Content>
          </Card>
        </Col>
        <Col lg={6} className="col-padding">
          <ServiceRequestDetailsCard serviceRequest={serviceRequest} />
        </Col>
      </Col>
    </div>
  );
};

const mapStateToProps = state => ({
  serviceRequest: state.ServiceRequests.selectedServiceRequest,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  createJob: job => dispatch(addJob(job)),
  showNotification: notificationsOps => dispatch(showNotification(notificationsOps)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobFormPage);
