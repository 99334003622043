// @flow

import React from 'react';
import {withRouter, useHistory} from 'react-router-dom';
import {connect, useSelector} from 'react-redux';
import {setMobileNavVisibility} from '../../stores/ui/layout.reducer';
import {Col, Row, Grid} from 'react-bootstrap';
import {SearchBar, ListView} from './index';
import {IconButton} from '../../components/IconButton/IconButton';
import useFetchServiceOffers from '../../hooks/useFetchServiceOffers.js';
import Card from '../../components/Card';
import NotFound from '../../components/NotFound';
const ServiceOffers = ({userRole, ...props}) => {
  const history = useHistory();
  const [serviceOffersById, error, isLoading] = useFetchServiceOffers();
  const {loading} = useSelector(state => state.User);

  const serviceOffers = serviceOffersById ? Object.values(serviceOffersById) : [];

  if ((!serviceOffersById && !error) || isLoading || loading) {
    return (
      <div className="main-content">
        <Col sm={12} className="col-padding">
          <Card>
            <Card.Header>Loading...</Card.Header>
            <Card.Content isLoading={isLoading || loading} />
          </Card>
        </Col>
      </div>
    );
  }

  if (error) return <NotFound entityName={'serviceOffers'} />;

  return (
    <div className="main-content">
      <Grid
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Row className="row-margin">
          <div
            style={{
              paddingLeft: 15,
              display: 'flex',
              flexWrap: 'nowrap',
              width: '100%',
              justifyContent: 'flex-start',
            }}
          >
            <SearchBar />
            <IconButton
              iconClass="pe-7s-plus"
              tooltipText="offer"
              style={{
                fontSize: 19,
                marginTop: 27,
              }}
              onClick={() => {
                return history.push('/serviceOffer/new');
              }}
            />
          </div>
        </Row>
        <Row className="row-margin">
          <Col xs={12} className="col-padding">
            <ListView data={serviceOffers} />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

const mapStateToProp = state => ({
  mobileNavVisibility: state.Layout.mobileNavVisibility,
  userRole: state.User.role,
});

const mapDispatchToProps = dispatch => ({
  hideMobileMenu: () => dispatch(setMobileNavVisibility(false)),
});

export default withRouter(connect(mapStateToProp, mapDispatchToProps)(ServiceOffers));
