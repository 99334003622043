// @flow

import React from 'react';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import Card from '../Card';
import StandardTable from './StandardTable';

type Props = {
  title: string,
  isLoading?: boolean,
};

const TableCard = ({title = 'Table', isLoading = false, ...rest}: Props) => {
  return (
    <Card>
      <Card.Header>{title}</Card.Header>
      <Card.Content isLoading={isLoading} style={{paddingLeft: 0, paddingRight: 0}}>
        <StandardTable {...rest} />
      </Card.Content>
    </Card>
  );
};

export default TableCard;
