//@flow

import React from 'react';
import {Col, Row} from 'react-bootstrap';
import Card from '../../components/Card';
import {connect, useSelector} from 'react-redux';
import IconButton from '../IconButton';

/**
 * A card component showing the details of a vehicle.
 * By clicking the details button, the user will be redirected to the details page.
 * The owner can request a service for the vehicle by using the request Button.
 * If no vehicle is passed via props, the card advises the user to select a vehicle
 */
const VehicleDetailsCard = ({handleRequestService, ...props}) => {
  const {selectedProvider} = useSelector(state => state.User);

  const content = [];
  const selectedVehicle = props.vehicle
    ? props.vehicle
    : {
        description: '',
        displayName: '',
        lockStatus: '',
        maintenanceStatus: '',
        price: '',
        provider: '',
        status: '',
        vehicleId: '',
        vehicleType: '',
        proiderId: '',
        currentPosition: {
          lat: '',
          lng: '',
        },
      };
  const role = useSelector(state => state.User.role);

  if (!props.vehicle)
    content.push(
      <div
        key={'placeholder'}
        style={{
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          right: '20%',
          top: 0,
          bottom: 0,
          margin: 'auto',
        }}
      >
        please select a vehicle
      </div>
    );

  Object.keys(selectedVehicle).forEach((key, index) => {
    if (key === 'icon') {
      return;
    }
    if (key !== 'currentPosition' && key !== 'vehicleIdHash') {
      content.push(
        <Row key={'vehicleProp' + index} className="row-margin">
          <Col xs={6} className="col-padding-zero">
            {key}
          </Col>
          <Col xs={6} style={{alignItems: 'center'}}>
            {selectedVehicle[key]}
          </Col>
        </Row>
      );
    }
  });

  content.push(
    <Row
      key={'pos'}
      style={{
        fontWeight: 200,
        fontFamily: 'Montserrat Alternates, sans-serif',
        marginBottom: 5,
      }}
    >
      <Col xs={6} className="col-padding-zero">
        Position
      </Col>
    </Row>
  );
  Object.keys(selectedVehicle.currentPosition).forEach((positionProp, index) => {
    content.push(
      <Row key={'posProp' + index} className="row-margin">
        <Col xs={6} className="col-padding-zero">
          {positionProp}
        </Col>
        <Col xs={6} style={{alignItems: 'center'}}>
          {selectedVehicle.currentPosition[positionProp]}
        </Col>
      </Row>
    );
  });

  const requestButton =
    props.vehicle &&
    handleRequestService &&
    role === 'Mobility Provider' &&
    selectedProvider?.providerId === props.vehicle.providerId ? (
      <IconButton
        iconClass="pe-7s-chat"
        tooltipText="request Service"
        onClick={() => handleRequestService()}
        style={{fontSize: 19}}
      />
    ) : null;

  return (
    <Card>
      <Card.Header>Vehicle Details</Card.Header>
      <Card.Content>
        <Col className="col-padidng" xs={12} style={{position: 'relative'}}>
          {content}
        </Col>
      </Card.Content>
      <Card.Legend>{requestButton}</Card.Legend>
    </Card>
  );
};

const mapStateToProps = (state, ownProps) => ({
  handleRequestService: ownProps.onRequestService,
});

export default connect(mapStateToProps)(VehicleDetailsCard);
