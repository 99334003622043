//@flow

import React, {useEffect, useState} from 'react';
import Card from '../Card';
import {Map, Marker, TileLayer, GeoJSON, LayersControl} from 'react-leaflet';
import {connect} from 'react-redux';
import {ClipLoader} from 'react-spinners';

const {Overlay} = LayersControl;

type MarkerInfo = {
  currentPosition: {
    lat: number,
    lng: number,
    id: string,
    displayName: string,
  },
  icon: DivIcon | Icon,
};

/**
 * For this component react-leaflet is used. Icon markers and polygons namely areas can be passed
 * via props to get rendered on the map.
 */

const MapCard = ({
  data,
  title,
  geoArea,
  mapHeight,
  selectedMarker,
  loading,
  loadingText,
  onSelectedMarkerChange,
}) => {
  const markers: MarkerInfo[] = data.markers !== undefined ? data.markers : [];
  const height = mapHeight;

  //takes a borderBox from a GeoJSON and returns the center of the box/area
  const calculateBBoxCenter = bbox => {
    const h = (bbox[2] + bbox[0]) / 2;
    const w = (bbox[3] + bbox[1]) / 2;
    return [w, h];
  };

  // centers the map depneding on selected marker position, or operation area
  let mapCenter;
  if (selectedMarker && selectedMarker.hasOwnProperty('currentPosition')) {
    mapCenter = [selectedMarker.currentPosition.lat, selectedMarker.currentPosition.lng];
  } else if (geoArea) {
    mapCenter = calculateBBoxCenter(geoArea.bbox);
  } else {
    mapCenter = [52.520008, 13.404954];
  }

  /* check if on SelectedMarkerChange is passed in props
       e.g. JobFormPage uses Mapcard with only one Marker */
  const selectMarker = marker => {
    if (!onSelectedMarkerChange) return null;
    onSelectedMarkerChange(marker);
  };

  const Markers = markers.map((marker, index) => {
    if (marker === undefined) return null;
    const pos = [marker.currentPosition.lat, marker.currentPosition.lng];
    return (
      <Marker
        key={index}
        icon={marker.icon}
        position={pos}
        onclick={() => selectMarker(marker)}
      ></Marker>
    );
  });

  const content = () => {
    return (
      <div style={{width: '100%', height: height + 100, position: 'relative'}}>
        <Map
          center={mapCenter}
          zoom={13}
          style={{width: '100%', height: height, marginTop: 7.5}}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          {geoArea ? (
            <LayersControl>
              <Overlay name={'GeoArea'} checked={true}>
                <GeoJSON
                  data={geoArea}
                  style={{color: '#ff7800', opacity: 0.65}}
                ></GeoJSON>
              </Overlay>
            </LayersControl>
          ) : (
            ''
          )}
          {Markers}
          {loading ? (
            <div
              style={{
                position: 'absolute',
                bottom: -10,
                left: 10,
                zIndex: 1000,
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'baseline',
              }}
            >
              <ClipLoader size={30} color={'#686868'} />
              <p style={{color: '#686868'}}>{loadingText}</p>
            </div>
          ) : (
            ''
          )}
        </Map>
      </div>
    );
  };
  return (
    <Card>
      <Card.Header>{title}</Card.Header>
      <Card.Content style={{width: '100%'}}>{content()}</Card.Content>
    </Card>
  );
};

const mapStateToProps = (state, ownProps) => ({
  selectedMarker: ownProps.selectedMarker,
  title: ownProps.title,
  data: ownProps.data,
  mapHeight: ownProps.mapHeight,
  onSelectedMarkerChange: ownProps.onSelectedMarkerChange,
});

export default connect(mapStateToProps)(MapCard);
