// @flow

import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import UserInfo from './UserInfo';
import Nav from './Nav';

/**
 * Sidebar includes projectHeader, user avatar, username and navigation list
 */
const SideBar = ({enableBackgroundImage, backgroundImage}) => {
  return (
    <div className="sidebar">
      <div className="brand">
        <Link to="/" className="brand-name">
          <img
            alt="CityBeamerLogo"
            src="CityBeamer_Logo_2.png"
            style={{
              width: '70%',
              height: '50%',
            }}
          />
        </Link>
      </div>

      <div
        className="sidebar-wrapper"
        style={{
          backgroundColor: 'white',
          backgroundImage: enableBackgroundImage
            ? `linear-gradient(to bottom, #ffffff80, #ffffff80), url(${backgroundImage})`
            : 'none',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
        }}
      >
        <UserInfo />
        <Nav />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  enableBackgroundImage: state.ThemeOptions.enableBackgroundImage,
  backgroundColor: state.ThemeOptions.backgroundColor,
  backgroundImage: state.ThemeOptions.backgroundImage,
  semiTransparentBackgroundColor: state.ThemeOptions.semiTransparentBackgroundColor,
});

export default withRouter(connect(mapStateToProps)(SideBar));
