import {
  ADD_SERVICE_REQUEST_FAILURE,
  ADD_SERVICE_REQUEST_STARTED,
  ADD_SERVICE_REQUEST_SUCCESS,
  FETCH_SERVICE_REQUESTS_FAILURE,
  FETCH_SERVICE_REQUESTS_STARTED,
  FETCH_SERVICE_REQUESTS_SUCCESS,
  FETCH_SERVICE_REQUEST_BY_ID_FAILURE,
  FETCH_SERVICE_REQUEST_BY_ID_STARTED,
  FETCH_SERVICE_REQUEST_BY_ID_SUCCESS,
  UPDATE_SERVICE_REQUEST_FAILURE,
  UPDATE_SERVICE_REQUEST_STARTED,
  UPDATE_SERVICE_REQUEST_SUCCESS,
  DELETE_SERVICE_REQUEST_STARTED,
  DELETE_SERVICE_REQUEST_FAILURE,
  DELETE_SERVICE_REQUEST_SUCCESS,
  UPDATE_SELECTED_SERVICE_REQUEST,
} from './serviceRequest.reducer';

import {createServiceRequest} from '../../contracts/ServiceRequestRegistry/contractMethods/createServiceRequest';
import {deleteServiceRequest as b_deleteServiceRequest} from '../../contracts/ServiceRequestRegistry/contractMethods/deleteServiceRequest';
import {updateServiceRequest as b_updateServiceRequest} from '../../contracts/ServiceRequestRegistry/contractMethods/updateServiceRequest';

//fetch ServiceRequests
export const fetchServiceRequestsStarted = () => ({
  type: FETCH_SERVICE_REQUESTS_STARTED,
});
export const fetchServiceRequestsSuccess = (serviceRequests, allIds) => ({
  type: FETCH_SERVICE_REQUESTS_SUCCESS,
  payload: serviceRequests,
  allIds: allIds,
});
export const fetchServiceRequestsFailure = error => ({
  type: FETCH_SERVICE_REQUESTS_FAILURE,
  payload: error,
});
//fetchServiceRequestsById
export const fetchServiceRequestByIdStarted = () => ({
  type: FETCH_SERVICE_REQUEST_BY_ID_STARTED,
});
export const fetchServiceRequestByIdSuccess = serviceRequest => ({
  type: FETCH_SERVICE_REQUEST_BY_ID_SUCCESS,
  payload: serviceRequest,
});
export const fetchServiceRequestByIdFailure = error => ({
  type: FETCH_SERVICE_REQUEST_BY_ID_FAILURE,
  payload: error,
});

export const addServiceRequestStarted = () => ({type: ADD_SERVICE_REQUEST_STARTED});
export const addServiceRequestSuccess = serviceRequest => ({
  type: ADD_SERVICE_REQUEST_SUCCESS,
  payload: serviceRequest,
});
export const addServiceRequestFailure = error => ({
  type: ADD_SERVICE_REQUEST_FAILURE,
  payload: error,
});

export const addServiceRequest = serviceRequest => {
  return async (dispatch, getState) => {
    const state = getState();
    const providerId = state.User.selectedProvider.providerId;
    try {
      dispatch(addServiceRequestStarted());
      const result = await createServiceRequest({providerId, ...serviceRequest});
      const serviceRequestId = result.events.ServiceRequestCreated.returnValues.requestId;

      const _serviceRequest = {
        serviceRequestId: serviceRequestId,
        description: serviceRequest.description,
        vehicleId: serviceRequest.vehicleId,
        type: serviceRequest.serviceType,
        price: `${serviceRequest.price / 100.0}€`,
        requesterId: providerId,
        jobIds: [],
      };
      dispatch(updateSelectedServiceRequest(_serviceRequest));
      return dispatch(addServiceRequestSuccess(_serviceRequest));
    } catch (error) {
      return dispatch(addServiceRequestFailure(error));
    }
  };
};

export const updateServiceRequestStarted = () => ({type: UPDATE_SERVICE_REQUEST_STARTED});
export const updateServiceRequestSuccess = serviceRequest => ({
  type: UPDATE_SERVICE_REQUEST_SUCCESS,
  payload: serviceRequest,
});
export const updateServiceRequestFailure = error => ({
  type: UPDATE_SERVICE_REQUEST_FAILURE,
  payload: error,
});

export const updateServiceRequest = serviceRequest => {
  return async (dispatch, getState) => {
    dispatch(updateServiceRequestStarted());
    try {
      await b_updateServiceRequest(
        serviceRequest.requestId,
        serviceRequest.description,
        serviceRequest.serviceType,
        serviceRequest.price
      );

      return dispatch(
        updateServiceRequestSuccess({
          requestId: serviceRequest.serviceRequestId,
          description: serviceRequest.description,
          vehicleId: serviceRequest.vehicleId,
          serviceType: serviceRequest.serviceType,
          price: `${serviceRequest.price / 100.0}€`,
          providerId: serviceRequest.providerId,
        })
      );
    } catch (error) {
      return dispatch(updateServiceRequestFailure(error));
    }
  };
};

export const deleteServiceRequestStarted = () => ({type: DELETE_SERVICE_REQUEST_STARTED});
export const deleteServiceRequestSuccess = serviceRequest => ({
  type: DELETE_SERVICE_REQUEST_SUCCESS,
  payload: serviceRequest,
});

export const deleteServiceRequestFailure = error => ({
  type: DELETE_SERVICE_REQUEST_FAILURE,
  payload: error,
});

export const deleteServiceRequest = serviceRequest => {
  return async (dispatch, getState) => {
    dispatch(deleteServiceRequestStarted());
    try {
      await b_deleteServiceRequest(serviceRequest.serviceRequestId);
      return dispatch(deleteServiceRequestSuccess(serviceRequest));
    } catch (error) {
      return dispatch(deleteServiceRequestFailure(error));
    }
  };
};

export const updateSelectedServiceRequest = serviceRequest => {
  return {
    type: UPDATE_SELECTED_SERVICE_REQUEST,
    payload: serviceRequest,
  };
};
