import {
  bikeMarker,
  bikeMarker_blue,
  bikeMarker_red,
  bikeMarker_orange,
  bikeMarkerJob,
  bikeMarkerJob_orange,
  bikeMarkerJob_red,
  bikeMarkerJob_blue,
  bikeMarkerJob_green,
  bikeMarkerJob_purple,
  bikeMarkerMaintain,
  scooterMarker,
  scooterMarker_blue,
  scooterMarker_orange,
  scooterMarker_red,
  scooterMarkerJob,
  scooterMarkerJob_orange,
  scooterMarkerJob_red,
  scooterMarkerJob_blue,
  scooterMarkerJob_purple,
  scooterMarkerJob_green,
  scooterMarkerMaintain,
  event_icon,
  station_icon,
} from '../Marker/Marker';

//vehicleType can be either E-Scooter or E-Bike, obj can be job or serviceRequest
export const IconSelector = ({vehicleType = 'E-Bike', obj, status}) => {
  const objString = obj ? ' ' + obj : '';
  const statusString = status ? ' ' + status : '';

  switch (vehicleType + objString + statusString) {
    case 'E-Bike':
      return bikeMarker;
    case 'E-Bike inUse':
      return bikeMarker_blue;
    case 'E-Bike idle':
      return bikeMarker_red;
    case 'E-Bike inMaintenance':
      return bikeMarker_orange;
    case 'E-Bike job':
      return bikeMarkerJob;
    case 'E-Bike job pending':
      return bikeMarkerJob_blue;
    case 'E-Bike job rejected':
      return bikeMarkerJob_orange;
    case 'E-Bike job accepted':
      return bikeMarkerJob_red;
    case 'E-Bike job started':
      return bikeMarkerJob_green;
    case 'E-Bike job finished':
      return bikeMarkerJob_purple;
    case 'E-Bike serviceRequest':
      return bikeMarkerMaintain;
    case 'E-Scooter':
      return scooterMarker;
    case 'E-Scooter inUse':
      return scooterMarker_blue;
    case 'E-Scooter idle':
      return scooterMarker_red;
    case 'E-Scooter inMaintenance':
      return scooterMarker_orange;
    case 'E-Scooter job':
      return scooterMarkerJob;
    case 'E-Scooter job pending':
      return scooterMarkerJob_blue;
    case 'E-Scooter job rejected':
      return scooterMarkerJob_orange;
    case 'E-Scooter job accepted':
      return scooterMarkerJob_red;
    case 'E-Scooter job started':
      return scooterMarkerJob_green;
    case 'E-Scooter job finshed':
      return scooterMarkerJob_purple;
    case 'E-Scooter serviceRequest':
      return scooterMarkerMaintain;
    case 'Event-Icon':
      return event_icon;
    case 'Station-Icon':
      return station_icon;
    default:
      return bikeMarker;
  }
};
