import {
  ADD_VEHICLE_FAILURE,
  ADD_VEHICLE_STARTED,
  ADD_VEHICLE_SUCCESS,
  FETCH_VEHICLES_FAILURE,
  FETCH_VEHICLES_STARTED,
  FETCH_VEHICLES_SUCCESS,
  FETCH_VEHICLE_BY_ID_FAILURE,
  FETCH_VEHICLE_BY_ID_STARTED,
  FETCH_VEHICLE_BY_ID_SUCCESS,
  UPDATE_VEHICLE_FAILURE,
  UPDATE_VEHICLE_STARTED,
  UPDATE_VEHICLE_SUCCESS,
  UPDATE_SELECTED_VEHICLE,
} from './vehicle.reducer';

import {createVehicle} from '../../contracts/VehicleRegistry/contractMethods/createVehicle';
//fetch Vehicles
export const fetchVehiclesStarted = () => ({
  type: FETCH_VEHICLES_STARTED,
});
export const fetchVehiclesSuccess = (vehicles, allIds) => ({
  type: FETCH_VEHICLES_SUCCESS,
  payload: vehicles,
});
export const fetchVehiclesFailure = error => ({
  type: FETCH_VEHICLES_FAILURE,
  payload: error,
});
//fetchVehiclesById
export const fetchVehicleByIdStarted = () => ({type: FETCH_VEHICLE_BY_ID_STARTED});
export const fetchVehicleByIdSuccess = vehicle => ({
  type: FETCH_VEHICLE_BY_ID_SUCCESS,
  payload: vehicle,
});
export const fetchVehicleByIdFailure = error => ({
  type: FETCH_VEHICLE_BY_ID_FAILURE,
  payload: error,
});

export const addVehicleStarted = () => ({type: ADD_VEHICLE_STARTED});
export const addVehicleSuccess = vehicle => ({
  type: ADD_VEHICLE_SUCCESS,
  payload: vehicle,
});
export const addVehicleFailure = error => ({
  type: ADD_VEHICLE_FAILURE,
  payload: error,
});

//helper function to parseLocation from string fromat to object with lat, lng props
const parseLocation = loc => {
  if (loc.split(',').length !== 2) {
    return {
      lat: 0,
      lng: 0,
    };
  }
  return {
    lat: loc.split(',')[0],
    lng: loc.split(',')[1],
  };
};

// calls backend route /createvehicle and adds new vehicle to redux state
export const addVehicle = vehicle => {
  return async (dispatch, getState) => {
    dispatch(addVehicleStarted());
    try {
      const state = getState();
      const providerId = state.User.selectedProvider.providerId;

      await createVehicle(vehicle.vehicleId, vehicle.location, providerId);
      return dispatch(
        addVehicleSuccess({
          vehicleId: vehicle.vehicleId,
          currentPosition: parseLocation(vehicle.location),
          providerId: providerId,
        })
      );
    } catch (error) {
      return dispatch(addVehicleFailure(error));
    }
  };
};

export const updateVehicleStarted = () => ({type: UPDATE_VEHICLE_STARTED});
export const updateVehicleSuccess = vehicle => ({
  type: UPDATE_VEHICLE_SUCCESS,
  payload: vehicle,
});
export const updateVehicleFailure = error => ({
  type: UPDATE_VEHICLE_FAILURE,
  payload: error,
});

export const updateVehicle = vehicle => {
  return async (dispatch, getState) => {
    dispatch(updateVehicleStarted());
    try {
      // TODO: Update next two lines if endpoint available
      // const response = await axios.post('api/vehicles', vehicle);
      const response = {data: vehicle};
      dispatch(updateVehicleSuccess(response.data));
    } catch (error) {
      dispatch(updateVehicleFailure(error));
    }
  };
};

export const updateSelectedVehicle = vehicle => {
  return {
    type: UPDATE_SELECTED_VEHICLE,
    payload: vehicle,
  };
};
