import React from 'react';
import ComponentRoleSwitcher from '../ComponentRoleSwitcher';
import {NavListItem} from './NavListItem';
import {MobilityProviderNav} from './MobilityProviderNav';
import {ServiceProviderNav} from './ServiceProviderNav';

export {NavListItem};

const Nav = () => {
  return (
    <ComponentRoleSwitcher
      mobilityProvider={<MobilityProviderNav />}
      serviceProvider={<ServiceProviderNav />}
    />
  );
};

export default Nav;
