import React from 'react';
import {connect} from 'react-redux';
import './jobs.scss';
import {useHistory} from 'react-router-dom';
import {Col, Row, Grid} from 'react-bootstrap';
import TableCard from '../../components/TableCard';
import useFetchJobs from '../../hooks/useFetchJobs';
import Card from '../../components/Card';
import NotFound from '../../components/NotFound';
import {updateSelectedJob} from '../../stores/job/job.actions';

const Jobs = ({selectedJob, updateSelectedJob}) => {
  const history = useHistory();
  const [jobsById, error, isLoading] = useFetchJobs();
  const jobs = jobsById ? Object.values(jobsById) : [];

  if ((!jobsById && !error) || isLoading) {
    return (
      <div className="main-content">
        <Card>
          <Card.Header>Loading...</Card.Header>
          <Card.Content isLoading={isLoading} />
        </Card>
      </div>
    );
  }

  if (error) {
    return <NotFound entityName={'jobs'} />;
  }

  return (
    <div className="main-content">
      <Grid
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Row>
          <Col xs={12} className="col-padding" style={{marginTop: 15}}>
            <TableCard
              title={'Job List'}
              data={jobs}
              labels={[
                {
                  field: 'jobId',
                  caption: 'Id',
                  dataSort: true,
                  width: '300px',
                  isKey: true,
                },
                {
                  field: 'vehicleId',
                  caption: 'vehicleId',
                  dataSort: true,
                  width: '50%',
                },
                {
                  field: 'price',
                  caption: 'price',
                  dataSort: true,
                  width: '50%',
                },
                {
                  field: 'status',
                  caption: 'Status',
                  dataSort: true,
                  width: '50%',
                },
              ]}
              keyClickUrl={row => `/jobs/${row.jobId}`}
              onRowClick={row => {
                updateSelectedJob(row);
                history.push('/jobs/' + row.jobId);
              }}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};
const mapStateToProps = state => ({
  state: state,
  softBackgroundColor: state.ThemeOptions.softBackgroundColor,
  backgroundColor: state.ThemeOptions.backgroundColor,
  selectedJob: state.Jobs.selectedJob,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateSelectedJob: job => dispatch(updateSelectedJob(job)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);
