import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {Col} from 'react-bootstrap';
import Card from '../../components/Card';
import {connect, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import MapCard from '../../components/MapCard/MapCard';
import NotFound from '../../components/NotFound/NotFound';
import VehicleDetailsCard from '../../components/VehicleDetailsCard';
import ComponentRoleSwitcher from '../../components/ComponentRoleSwitcher';
import ServiceRequestDetailsCard from '../../components/ServiceRequestDetailsCard';
import TableCard from '../../components/TableCard';
import useFetchServiceRequestById from '../../hooks/useFetchServiceRequestById';
import IconButton from '../../components/IconButton';
import {acceptJob, rejectJob} from '../../stores/job/job.actions';
import {
  deleteServiceRequest,
  updateServiceRequest,
} from '../../stores/serviceRequest/serviceRequest.actions';
import ServiceRequestEditCard from './ServiceRequestEditCard';
import {IconSelector} from '../../components/IconSelector/IconSelector';
import useFetchVehicleById from '../../hooks/useFetchVehicleById';
import useFetchJobs from '../../hooks/useFetchJobs';
import useFetchProviders from '../../hooks/useFetchProviders';
import {updateSelectedServiceRequest} from '../../stores/serviceRequest/serviceRequest.actions';
import {notificationsOptions} from '../../helpers';
import {showNotification} from '../../stores/ui/layout.reducer';

const ServiceRequestDetails = ({
  acceptJob,
  rejectJob,
  deleteServiceRequest,
  updateServiceRequest,
  updateSelectedServiceRequest,
  showNotification,
}) => {
  const history = useHistory();
  const [editMode, changeEditMode] = useState(false);
  const {selectedProvider} = useSelector(state => state.User);
  const providerId = selectedProvider?.providerId;

  const {id} = useParams();
  const [jobsById, j_error, j_isLoading] = useFetchJobs();
  const [serviceRequest, error, isLoading] = useFetchServiceRequestById(id);
  const [vehicle, v_error, v_isLoading] = useFetchVehicleById(serviceRequest?.vehicleId);
  const [providerById, p_error, p_isLoading] = useFetchProviders();

  if (
    (!serviceRequest && !error) ||
    (!vehicle && !v_error) ||
    (!providerById && !p_error) ||
    (!jobsById && !j_error) ||
    isLoading ||
    v_isLoading ||
    p_isLoading
  ) {
    return (
      <div className="main-content">
        <Card>
          <Card.Header>Loading...</Card.Header>
          <Card.Content isLoading={true} />
        </Card>
      </div>
    );
  }

  if (error) {
    return <NotFound entityName={'serviceRequest'} />;
  }

  var icon = IconSelector({vehicleType: vehicle.vehicleType, obj: 'serviceRequest'});
  const vehicleMarker = {...vehicle, icon: icon};

  var jobs = serviceRequest.jobIds.map(jobId => ({
    ...jobsById[jobId],
    provider: providerById[jobsById[jobId]?.contractorId]?.providerName,
  }));

  const acceptedJob = jobs.find(job => job.status === 'accepted');

  const handleDeleteServiceRequest = async () => {
    const result = await deleteServiceRequest({
      serviceRequestId: serviceRequest.serviceRequestId,
    });

    let notificationsOps;
    if (result.type === 'DELETE_SERVICE_REQUEST_SUCCESS') {
      notificationsOps = notificationsOptions(
        'success',
        'Delete service request',
        'Service request deleted successfully'
      );
      updateSelectedServiceRequest(undefined);
      history.push(`/serviceRequests`);
    } else if (result.type === 'DELETE_SERVICE_REQUEST_FAILURE') {
      notificationsOps = notificationsOptions(
        'error',
        'Delete service request',
        'Failed to delete service request'
      );
    }
    showNotification(notificationsOps);
  };

  const handleEditServiceRequest = () => {
    changeEditMode(true);
  };

  const handleSubmit = async values => {
    const _serviceRequest = {
      requestId: serviceRequest.serviceRequestId,
      description: values.description,
      serviceType: values.serviceType,
      price: parseInt(values.price.replace('.', ''), 10),
    };

    const result = await updateServiceRequest(_serviceRequest);
    changeEditMode(false);
    let notificationsOps;
    if (result.type === 'UPDATE_SERVICE_REQUEST_SUCCESS') {
      notificationsOps = notificationsOptions(
        'success',
        'Update service request',
        'Service request updated successfully'
      );
    } else if (result.type === 'UPDATE_SERVICE_REQUEST_FAILURE') {
      notificationsOps = notificationsOptions(
        'error',
        'Update service request',
        'Failed to update service request'
      );
    }
    showNotification(notificationsOps);
  };

  const handleAcceptJob = async job => {
    const result = await acceptJob({jobId: job.jobId});

    let notificationsOps;
    if (result.type === 'ACCEPT_JOB_SUCCESS') {
      notificationsOps = notificationsOptions(
        'success',
        'Accept application',
        'Application accepted successfully'
      );
    } else if (result.type === 'ACCEPT_JOB_FAILURE') {
      notificationsOps = notificationsOptions(
        'error',
        'Accept application',
        'Failed to accept application'
      );
    }
    showNotification(notificationsOps);
  };

  const handleRejectJob = async job => {
    const result = await rejectJob({jobId: job.jobId});

    let notificationsOps;
    if (result.type === 'REJECT_JOB_SUCCESS') {
      notificationsOps = notificationsOptions(
        'success',
        'Reject application',
        'Application rejected successfully'
      );
    } else if (result.type === 'REJECT_JOB_FAILURE') {
      notificationsOps = notificationsOptions(
        'error',
        'Reject application',
        'Failed to reject application'
      );
    }
    showNotification(notificationsOps);
  };

  const ServiceRequestCard = editMode ? (
    <ServiceRequestEditCard
      serviceRequest={serviceRequest}
      onSubmit={values => handleSubmit(values)}
    />
  ) : (
    <ServiceRequestDetailsCard
      jobs={jobs}
      serviceRequest={serviceRequest}
      onDeleteServiceRequest={() => handleDeleteServiceRequest()}
      onEditServiceRequest={() => handleEditServiceRequest()}
      onApplyForServiceRequest={() => {
        history.push({
          pathname: '/job/new',
          state: {serviceRequest: serviceRequest},
        });
      }}
    />
  );

  const rowButtons = acceptedJob
    ? []
    : [
        row =>
          row.status === 'rejected' ? null : (
            <IconButton
              iconClass="pe-7s-check"
              tooltipText={'accept Job'}
              onClick={event => {
                event.preventDefault();
                handleAcceptJob(row);
              }}
              size={'23px'}
              style={{fontSize: '15px'}}
            />
          ),
        (row, cell) =>
          row.status === 'rejected' ? null : (
            <IconButton
              iconClass="pe-7s-close-circle"
              tooltipText={'reject Job'}
              onClick={event => {
                event.preventDefault();
                handleRejectJob(row);
              }}
              size={'23px'}
              style={{fontSize: '15px'}}
            />
          ),
      ];

  const showApplicants = () => {
    const acceptedJobID = acceptedJob ? acceptedJob.jobId : null;
    return (
      <ComponentRoleSwitcher
        mobilityProvider={
          <TableCard
            title={'Applicants'}
            isLoading={j_isLoading}
            data={jobs}
            selectRow
            selected={[acceptedJobID]}
            labels={[
              {
                field: 'jobId',
                caption: 'Id',
                dataSort: true,
                width: '20%',
                isKey: true,
              },
              {
                field: 'provider',
                caption: 'provider',
                dataSort: true,
                width: '50%',
              },
              {
                field: 'price',
                caption: 'price',
                dataSort: true,
                width: '20%',
              },
              {
                field: 'status',
                caption: 'status',
                dataSort: true,
                width: '30%',
              },
            ]}
            keyClickUrl={row => `/jobs/${row.jobId}`}
            rowButtons={rowButtons}
          />
        }
        serviceProvider={null}
      />
    );
  };

  return (
    <div>
      <div className="main-content">
        <Col lg={6} className="col-padding">
          <Col lg={12} className="col-padding">
            {ServiceRequestCard}
          </Col>
          <Col lg={12} className="col-padding">
            <VehicleDetailsCard vehicle={vehicle} />
          </Col>
        </Col>
        <Col lg={6} className="col-padding">
          <Col lg={12} className="col-padding">
            {showApplicants()}
          </Col>
          <Col lg={12} className="col-padding">
            <MapCard
              title="Vehicle Map"
              data={{markers: [vehicleMarker]}}
              selectedMarker={vehicleMarker}
              mapHeight="50vh"
            />
          </Col>
        </Col>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  acceptJob: jobId => dispatch(acceptJob(jobId)),
  rejectJob: jobId => dispatch(rejectJob(jobId)),
  deleteServiceRequest: serviceRequest => dispatch(deleteServiceRequest(serviceRequest)),
  updateServiceRequest: serviceRequest => dispatch(updateServiceRequest(serviceRequest)),
  updateSelectedServiceRequest: serviceRequest =>
    dispatch(updateSelectedServiceRequest(serviceRequest)),
  showNotification: notificationsOps => dispatch(showNotification(notificationsOps)),
});

export default connect(null, mapDispatchToProps)(ServiceRequestDetails);
