import React from 'react';
import {connect, useSelector} from 'react-redux';
import '../Dashboard.scss';
import {withRouter} from 'react-router-dom';
import {Col, Row, Grid} from 'react-bootstrap';
import TableCard from '../../../components/TableCard';
import {updateSelectedJob} from '../../../stores/job/job.actions';
import PieCard from '../../../components/PieCard/PieCard';
import useFetchJobs from '../../../hooks/useFetchJobs';
import NotFound from '../../../components/NotFound';
import Card from '../../../components/Card';
import MapCard from '../../../components/MapCard';

import {IconSelector} from '../../../components/IconSelector/IconSelector';
import useFetchVehicles from '../../../hooks/useFetchVehicles';
import useFetchServiceRequests from '../../../hooks/useFetchServiceRequests';

const ServiceProviderDashboard = ({selectedJob, updateSelectedJob}) => {
  const [vehiclesById, v_error, v_isLoading] = useFetchVehicles();
  const vehicles = vehiclesById ? Object.values(vehiclesById) : [];

  const [jobsById, j_error, j_isLoading] = useFetchJobs();
  const jobs = jobsById ? Object.values(jobsById) : [];

  const [serviceRequestsById, s_error, s_isLoading] = useFetchServiceRequests();
  const serviceRequests = serviceRequestsById ? Object.values(serviceRequestsById) : [];

  const {loading} = useSelector(state => state.User);

  if (
    (!jobsById && !j_error) ||
    (!vehicles && !v_error) ||
    (!serviceRequestsById && !s_error) ||
    v_isLoading ||
    j_isLoading ||
    s_isLoading ||
    loading
  ) {
    return (
      <div className="main-content">
        <Col sm={12} className="col-padding">
          <Card>
            <Card.Header>Loading...</Card.Header>
            <Card.Content isLoading={v_isLoading || j_isLoading || s_isLoading} />
          </Card>
        </Col>
      </div>
    );
  }

  if (s_error || v_error || j_error) {
    return <NotFound entityName={'jobs'} />;
  }

  const jobsPending = jobs.filter(job => job.status === 'pending');
  const jobsRejected = jobs.filter(job => job.status === 'rejected');
  const jobsAccepted = jobs.filter(job => job.status === 'accepted');
  const jobsStarted = jobs.filter(job => job.status === 'started');
  const jobsFinished = jobs.filter(job => job.status === 'finished');

  const jobMarkers = jobs.map(job => {
    const vehicle = vehiclesById[job.vehicleId];
    const vehicleType = vehicle.vehicleType;
    var icon = IconSelector({vehicleType: vehicleType, obj: 'job', status: job.status});
    const currentPosition = vehicle.currentPosition;
    return {...job, currentPosition, icon};
  });

  const selectedJobMarker = selectedJob
    ? jobMarkers.find(jobMarker => jobMarker.jobId === selectedJob.jobId)
    : null;

  return (
    <div className="main-content">
      <Grid
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Col sm={12} className="col-padding">
          <TableCard
            title={'Service Requests'}
            data={serviceRequests}
            labels={[
              {
                field: 'serviceRequestId',
                caption: 'Id',
                dataSort: true,
                width: '300px',
                isKey: true,
              },
              {
                field: 'vehicleId',
                caption: 'vehicleId',
                dataSort: true,
                width: '300px',
              },
              {
                field: 'type',
                caption: 'type',
                dataSort: true,
                width: '50%',
              },
              {
                field: 'price',
                caption: 'price',
                dataSort: true,
                width: '50%',
              },
            ]}
            keyClickUrl={row => `/serviceRequests/${row.serviceRequestId}`}
          />
        </Col>
        <Row>
          <Col xs={9} className="col-padding" style={{marginTop: 15}}>
            <TableCard
              title={'Jobs'}
              data={jobs}
              labels={[
                {
                  field: 'jobId',
                  caption: 'ID',
                  dataSort: true,
                  width: '300px',
                  isKey: true,
                },
                {
                  field: 'vehicleId',
                  caption: 'vehicleId',
                  dataSort: true,
                  width: '50%',
                },
                {
                  field: 'price',
                  caption: 'price',
                  dataSort: true,
                  width: '50%',
                },
                {
                  field: 'status',
                  caption: 'Status',
                  dataSort: true,
                  width: '50%',
                },
              ]}
              keyClickUrl={row => `/jobs/${row.jobId}`}
              onRowClick={row => updateSelectedJob(row)}
              selected={selectedJob ? [selectedJob.jobId] : null}
            />
          </Col>
          <Col xs={3} className="col-padding" style={{marginTop: 15}}>
            <PieCard
              isDonut={true}
              labels={['pending', 'accepted', 'rejected', 'finished', 'started']}
              data={[
                jobsPending.length,
                jobsAccepted.length,
                jobsRejected.length,
                jobsFinished.length,
                jobsStarted.length,
              ]}
              caption={'Jobs by status'}
              isLoading={j_isLoading}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="col-padding" style={{marginTop: 15}}>
            <MapCard
              title="Map"
              data={{markers: jobMarkers}}
              selectedMarker={selectedJobMarker}
              mapHeight="60vh"
              onSelectedMarkerChange={jobMarker => {
                const nextSelectedJob = jobs.find(
                  jobt => jobt.vehicleId === jobMarker.vehicleId
                );
                updateSelectedJob(nextSelectedJob);
              }}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => ({
  softBackgroundColor: state.ThemeOptions.softBackgroundColor,
  backgroundColor: state.ThemeOptions.backgroundColor,
  selectedJob: state.Jobs.selectedJob,
});

const mapDispatchToProps = dispatch => ({
  updateSelectedJob: job => dispatch(updateSelectedJob(job)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ServiceProviderDashboard)
);
