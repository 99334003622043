// @flow

import React from 'react';
import {useSelector} from 'react-redux';
import {USER_ROLES} from '../../stores/user/user.reducer';

/**
 * this component is used to render different views for different roles.
 * e.g. mobilityProviderDashboard and serviceProviderDashboard
 */
const ComponentRoleSwitcher = ({mobilityProvider, serviceProvider}) => {
  const userRole = useSelector(state => state.User.role);
  if (userRole === USER_ROLES.MOBILITY_PROVIDER) {
    return mobilityProvider;
  } else if (userRole === USER_ROLES.SERVICE_PROVIDER) {
    return serviceProvider;
  } else {
    return <p>Unknown User Role</p>;
  }
};

export default ComponentRoleSwitcher;
