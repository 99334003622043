import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import {
  fetchServiceRequestByIdFailure,
  fetchServiceRequestByIdStarted,
  fetchServiceRequestByIdSuccess,
} from '../stores/serviceRequest/serviceRequest.actions';
import {getServiceRequestById} from '../contracts/ServiceRequestRegistry/contractMethods/getServiceRequestById';

export default function useFetchServiceRequestById(id) {
  const dispatch = useDispatch();
  const {byId, error, loading, lastUpdated} = useSelector(state => state.ServiceRequests);
  const data = byId[id];
  useEffect(() => {
    const source = axios.CancelToken.source();
    const TTL = 1000 * 60;

    const wasFetchedBefore = lastUpdated;
    const isExpired =
      wasFetchedBefore && new Date().getTime() - lastUpdated.getTime() > TTL;
    if ((wasFetchedBefore && !isExpired) || loading) return;

    const fetchServiceRequestById = async () => {
      try {
        dispatch(fetchServiceRequestByIdStarted());
        let serviceRequest = await getServiceRequestById(id);
        //serviceRequest.jobIds = await getJobIdsByRequest(id);
        dispatch(fetchServiceRequestByIdSuccess(serviceRequest));
      } catch (error) {
        dispatch(fetchServiceRequestByIdFailure(error));
      }
    };

    fetchServiceRequestById();

    return () => source.cancel();
  }, [dispatch, id, lastUpdated, loading]);

  return [data, error, loading];
}
