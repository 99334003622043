// @flow

import type {ServiceOffer} from './serviceOffer.types';

export const FETCH_SERVICE_OFFERS_STARTED: 'FETCH_SERVICE_OFFERS_STARTED' =
  'FETCH_SERVICE_OFFERS_STARTED';
export const FETCH_SERVICE_OFFERS_SUCCESS: 'FETCH_SERVICE_OFFERS_SUCCESS' =
  'FETCH_SERVICE_OFFERS_SUCCESS';
export const FETCH_SERVICE_OFFERS_FAILURE: 'FETCH_SERVICE_OFFERS_FAILURE' =
  'FETCH_SERVICE_OFFERS_FAILURE';

export type FETCH_SERVICE_OFFERS_STARTED_ACTION = {
  type: typeof FETCH_SERVICE_OFFERS_STARTED,
};

export type FETCH_SERVICE_OFFERS_SUCCESS_ACTION = {
  type: typeof FETCH_SERVICE_OFFERS_SUCCESS,
  payload: {id: ServiceOffer},
  allIds: string[],
};

export type FETCH_SERVICE_OFFERS_FAILURE_ACTION = {
  type: typeof FETCH_SERVICE_OFFERS_FAILURE,
  payload: any,
};

export const FETCH_SERVICE_OFFER_BY_ID_STARTED = 'FETCH_SERVICE_OFFER_BY_ID_STARTED';
export const FETCH_SERVICE_OFFER_BY_ID_SUCCESS = 'FETCH_SERVICE_OFFER_BY_ID_SUCCESS';
export const FETCH_SERVICE_OFFER_BY_ID_FAILURE = 'FETCH_SERVICE_OFFER_BY_ID_FAILURE';

export type FETCH_SERVICE_OFFER_BY_ID_STARTED_ACTION = {
  type: typeof FETCH_SERVICE_OFFER_BY_ID_STARTED,
};

export type FETCH_SERVICE_OFFER_BY_ID_SUCCESS_ACTION = {
  type: typeof FETCH_SERVICE_OFFER_BY_ID_SUCCESS,
  payload: {serviceOfferId: string},
  allIds: string[],
};

export type FETCH_SERVICE_OFFER_BY_ID_FAILURE_ACTION = {
  type: typeof FETCH_SERVICE_OFFER_BY_ID_FAILURE,
  payload: any,
};

export const ADD_SERVICE_OFFER_STARTED: 'ADD_SERVICE_OFFER_STARTED' =
  'ADD_SERVICE_OFFER_STARTED';
export const ADD_SERVICE_OFFER_SUCCESS: 'ADD_SERVICE_OFFER_SUCCESS' =
  'ADD_SERVICE_OFFER_SUCCESS';
export const ADD_SERVICE_OFFER_FAILURE: 'ADD_SERVICE_OFFER_FAILURE' =
  'ADD_SERVICE_OFFER_FAILURE';

export type ADD_SERVICE_OFFER_STARTED_ACTION = {
  type: typeof ADD_SERVICE_OFFER_STARTED,
};

export type ADD_SERVICE_OFFER_SUCCESS_ACTION = {
  type: typeof ADD_SERVICE_OFFER_SUCCESS,
  payload: ServiceOffer,
};

export type ADD_SERVICE_OFFER_FAILURE_ACTION = {
  type: typeof ADD_SERVICE_OFFER_FAILURE,
  payload: any,
};

export const UPDATE_SERVICE_OFFER_STARTED: 'UPDATE_SERVICE_OFFER_STARTED' =
  'UPDATE_SERVICE_OFFER_STARTED';
export const UPDATE_SERVICE_OFFER_SUCCESS: 'UPDATE_SERVICE_OFFER_SUCCESS' =
  'UPDATE_SERVICE_OFFER_SUCCESS';
export const UPDATE_SERVICE_OFFER_FAILURE: 'UPDATE_SERVICE_OFFER_FAILURE' =
  'UPDATE_SERVICE_OFFER_FAILURE';

export type UPDATE_SERVICE_OFFER_STARTED_ACTION = {
  type: typeof UPDATE_SERVICE_OFFER_STARTED,
};

export type UPDATE_SERVICE_OFFER_SUCCESS_ACTION = {
  type: typeof UPDATE_SERVICE_OFFER_SUCCESS,
  payload: ServiceOffer,
};

export type UPDATE_SERVICE_OFFER_FAILURE_ACTION = {
  type: typeof UPDATE_SERVICE_OFFER_FAILURE,
  payload: any,
};

export const DELETE_SERVICE_OFFER_STARTED: 'DELETE_SERVICE_OFFER_STARTED' =
  'DELETE_SERVICE_OFFER_STARTED';
export const DELETE_SERVICE_OFFER_SUCCESS: 'DELETE_SERVICE_OFFER_SUCCESS' =
  'DELETE_SERVICE_OFFER_SUCCESS';
export const DELETE_SERVICE_OFFER_FAILURE: 'DELETE_SERVICE_OFFER_FAILURE' =
  'DELETE_SERVICE_OFFER_FAILURE';

export type DELETE_SERVICE_OFFER_STARTED_ACTION = {
  type: typeof DELETE_SERVICE_OFFER_STARTED,
};

export type DELETE_SERVICE_OFFER_SUCCESS_ACTION = {
  type: typeof DELETE_SERVICE_OFFER_SUCCESS,
  payload: ServiceOffer,
};

export type DELETE_SERVICE_OFFER_FAILURE_ACTION = {
  type: typeof DELETE_SERVICE_OFFER_FAILURE,
  payload: any,
};

type Action =
  | FETCH_SERVICE_OFFERS_STARTED_ACTION
  | FETCH_SERVICE_OFFERS_SUCCESS_ACTION
  | FETCH_SERVICE_OFFERS_FAILURE_ACTION
  | FETCH_SERVICE_OFFER_BY_ID_STARTED_ACTION
  | FETCH_SERVICE_OFFER_BY_ID_SUCCESS_ACTION
  | FETCH_SERVICE_OFFER_BY_ID_FAILURE_ACTION
  | ADD_SERVICE_OFFER_STARTED_ACTION
  | ADD_SERVICE_OFFER_SUCCESS_ACTION
  | ADD_SERVICE_OFFER_FAILURE_ACTION
  | UPDATE_SERVICE_OFFER_STARTED_ACTION
  | UPDATE_SERVICE_OFFER_SUCCESS_ACTION
  | UPDATE_SERVICE_OFFER_FAILURE_ACTION
  | DELETE_SERVICE_OFFER_STARTED_ACTION
  | DELETE_SERVICE_OFFER_SUCCESS_ACTION
  | DELETE_SERVICE_OFFER_FAILURE_ACTION;

type State = {
  byId: {
    id?: string,
  },
  allIds: string[],
  loading: boolean,
  error: any,
  lastUpdated?: Date,
};

const initialState: State = {
  byId: {},
  allIds: [],
  loading: false,
  error: null,
};

export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case FETCH_SERVICE_OFFERS_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_SERVICE_OFFERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        byId: action.payload,
        allIds: action.allIds,
        lastUpdated: new Date(),
      };
    case FETCH_SERVICE_OFFERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_SERVICE_OFFER_BY_ID_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_SERVICE_OFFER_BY_ID_SUCCESS:
      const serviceOffer = action.payload;
      return {
        ...state,
        loading: false,
        error: null,
        byId: {...state.byId, [serviceOffer.serviceOfferId]: {...serviceOffer}},
        lastUpdated: new Date(),
      };
    case ADD_SERVICE_OFFER_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_SERVICE_OFFER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        byId: {
          ...state.byId,
          [action.payload.serviceOfferId]: {
            ...action.payload,
          },
        },
        allIds: state.allIds.find(id => id === action.payload.serviceOfferId)
          ? state.allIds
          : [...state.allIds, action.payload.serviceOfferId],
      };
    case ADD_SERVICE_OFFER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_SERVICE_OFFER_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_SERVICE_OFFER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        byId: {...state.byId, [action.payload.serviceOfferId]: {...action.payload}},
      };
    case UPDATE_SERVICE_OFFER_FAILURE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case DELETE_SERVICE_OFFER_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_SERVICE_OFFER_SUCCESS:
      const entries = Object.entries(state.byId).filter(
        entry => entry[0] !== action.payload.serviceOfferId
      );
      const newById = Object.fromEntries(entries);

      const newAllIds = state.allIds.filter(id => id !== action.payload.serviceOfferId);
      return {
        ...state,
        loading: false,
        error: null,
        byId: newById,
        allIds: newAllIds,
      };
    case DELETE_SERVICE_OFFER_FAILURE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    default:
      return state;
  }
};
