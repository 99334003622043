// @flow

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import TableCard from '../../components/TableCard';
import {setMobileNavVisibility} from '../../stores/ui/layout.reducer';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';

type Props = {
  title: string,
  isLoading?: boolean,
};
const ListView = ({title = 'Table', isLoading = false, ...props}: Props) => {
  const history = useHistory();
  return (
    <TableCard
      title={'ServiceOffers'}
      data={props.data}
      labels={[
        {
          field: 'serviceOfferId',
          caption: 'Id',
          dataSort: true,
          width: '160px',
          isKey: true,
        },
        {
          field: 'title',
          caption: 'title',
          dataSort: true,
          width: '50%',
        },
        {
          field: 'serviceType',
          caption: 'serviceType',
          dataSort: true,
          width: '50%',
        },
        {
          field: 'operationArea',
          caption: 'operationArea',
          dataSort: true,
          width: '50%',
        },
      ]}
      keyClickUrl={row => `/serviceOffers/${row.serviceOfferId}`}
      onRowClick={row => history.push(`/serviceOffers/${row.serviceOfferId}`)}
    />
  );
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  hideMobileMenu: () => dispatch(setMobileNavVisibility(false)),
});

export default withRouter(connect(undefined, mapDispatchToProps)(ListView));
