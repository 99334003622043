import axios from 'axios';

export const getRelocationList = async (newCoord, ppk, budget, providerId) => {
  /**
  * the [POST] route '/api/services/relocation' is defined in the cb-2-backend repo
  * in file ./src/controllers/services.controller.ts. 
  * See "interface RelocationRequest"
  * 
  * Obtain an optimized list of relocation jobs (relocate vehicle at source location to target location)
  * The list is optimized in the sense that relocation jobs are minimized on their (!) total relocation cost.
  * See ./src/controllers/services,controller.ts and ./src/utils/transportationRelocationSolver
  *  
  * @param {JSONObject} newCoord: array of target locations to relocate to, target locations are of the format:
  *  @param {number} lat: latitude of the target location
  *  @param {number} lng: longitude of the target location
  *  @param {int} demand: demand at target location, how many vehicles should be moved to the target location
  *  @param {number} id: vehicle id
  * @param {string} ppk: price per kilometer to be paid per vehicle for relocation, the price is fixed
  * @param {string} budget: budget available for relocation, in EUR (format 100.01)
  * @param {number} providerId number: id of the mobility provider, only relocate vehicles by a single provider
  * @returns {JSONObject} : information used to derive service requests
   * 	@param {JSONObject} bestVehicles : objects that correspond to relocation service requests of a single vehicle
   *		@param {JSONObject} currentPosition: current position of the vehicle to be relocated
   *			@param {number} lat : latitue of current position
   *			@param {number} lng : longitude of current position
   *		@param {boolean} lockStatus : lock status of the vehicle
   *		@param {string} maintenanceStatus: maintenance status of the vehicle
   *		@param {string} status : status of the vehicle
   *		@param {string} vehicleId : identifier of the vehicle
   *		@param {string} providerId : identifier of the provider who owns the vehicle
   *		@param {string} provider : clear name of the provider
   *		@param {number} distance : distance to target location of relocation
   *		@param {string} eventId : identifier of the event that is associated with the target location
   *	@param {number} totalPrice : total price in [EURcents] for all relocation service requests (price = distance * ppk)

  * example response: {
  *								bestVehicles: [
  *										{
  *											currentPosition: [Object],
  *											lockStatus: true,
  *											maintenanceStatus: '',
  *											status: 'pending',
  *											vehicleId: 'testvec-1',
  *											providerId: '12',
  *											provider: 'test-provider',
  *											distance: 2652.2312,
  *											eventId: 'Mamma Mia! in Berlin 2021'
  *										}
  * 									],
  *									totalPrice: 5304.4624
  *								 }
  */

  const rsp = await axios.post('/api/services/relocation', {
    targets: newCoord,
    ppk: ppk,
    budget: budget,
    providerId,
  });
  const getVehicles = rsp.data.bestVehicles ?? [];
  const vehicleList = getVehicles.map((v, index) => ({
    ...v,
    relocationPrice: `${((getVehicles[index].distance * ppk) / 100).toFixed(2)}€`,
  }));

  return vehicleList;
};
