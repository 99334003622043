// @flow

import React from 'react';
import {
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Radio,
  Row,
  Grid,
} from 'react-bootstrap';
import Card from '../../components/Card';
import {connect, useSelector} from 'react-redux';
import {
  setBackgroundImage,
  setEnableBackgroundImage,
} from '../../stores/ui/theme.reducer';
import {setBackendBaseUrl} from '../../stores/user/setting.reducer';
import {USER_ROLES} from '../../stores/user/user.reducer';
import {
  setUserRoleAndUpdateAccentColor,
  updateSelectedProviderRole,
} from '../../stores/user/user.actions';
import TableCard from '../../components/TableCard';
import useFetchRolesByAddress from '../../hooks/useFetchRolesByAddress';
import ProviderForm from './ProviderForm';
import GrantMemberForm from './GrantMemberForm';
import ChangeProviderNameForm from './ChangeProviderNameForm';

const Settings = ({
  enableBackgroundImage,
  backgroundImage,
  backendBaseUrl,
  userRole,
  setEnableBackgroundImage,
  setBackgroundImage,
  setBackendBaseUrl,
  setUserRole,
  updateSelectedProvider,
}) => {
  const [providersById, error, isLoading] = useFetchRolesByAddress();
  const providers = providersById ? Object.values(providersById) : [];
  const {selectedProvider} = useSelector(state => state.User);
  const {loading} = useSelector(state => state.Providers);

  const handleBackgroundImage = backgroundImage => {
    setEnableBackgroundImage(true);
    setBackgroundImage(backgroundImage);
  };

  const handleNoneBackgroundImage = () => {
    setEnableBackgroundImage(false);
  };

  const providerCards = () => {
    return (
      <div>
        <Row>
          <Col xs={12} className="col-padding" style={{marginTop: 15}}>
            <Card>
              <Card.Header>
                Create provider card
                <div>
                  <p style={{marginTop: 8, fontSize: 12}}>
                    Use this card to create a new provider. Your account is then
                    authorized to add more accounts to the provider using the grant member
                    role Card.
                  </p>
                </div>
              </Card.Header>
              <Card.Content isLoading={loading}>
                <ProviderForm />
              </Card.Content>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="col-padding" style={{marginTop: 15}}>
            <Card>
              <Card.Header>
                Rename provider card
                <div>
                  <p style={{marginTop: 8, fontSize: 12}}>
                    Use this card to give an existing provider a new name. From the list
                    above, select the provider you want to rename and fill in the form
                    field with the new name.
                  </p>
                </div>
              </Card.Header>
              <Card.Content isLoading={loading}>
                <ChangeProviderNameForm />
              </Card.Content>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="col-padding" style={{marginTop: 15}}>
            <Card>
              <Card.Header>
                Grant member role card
                <div>
                  <p style={{marginTop: 8, fontSize: 12}}>
                    Use this card to add an account to a provider. Only creators of
                    providers are authorized to assign the membership role to other
                    accounts. Fill the form field with the Ethereum address of the account
                    you want to add to the selected provider.
                  </p>
                </div>
              </Card.Header>
              <Card.Content isLoading={loading}>
                <GrantMemberForm />
              </Card.Content>
            </Card>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div className="main-content">
      <Col sm={12} className="col-padding">
        <Card>
          <Card.Header>User Settings</Card.Header>
          <Card.Content>
            <Grid
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Row>
                <FormGroup controlId="formControlsSelect">
                  <ControlLabel>API Backend</ControlLabel>
                  <FormControl
                    componentClass="select"
                    placeholder="select"
                    onChange={e => setBackendBaseUrl(e.target.value)}
                    value={backendBaseUrl}
                  >
                    {React.Children.map(
                      process.env.REACT_APP_API_BASEURL.split(','),
                      child => {
                        return <option value={child}>{child}</option>;
                      }
                    )}
                  </FormControl>
                </FormGroup>
              </Row>
              <Row>
                <FormGroup>
                  <ControlLabel>User Role</ControlLabel>
                  <br />
                  <Radio
                    name="userRole"
                    inline
                    onChange={() => setUserRole(USER_ROLES.MOBILITY_PROVIDER)}
                    checked={userRole === USER_ROLES.MOBILITY_PROVIDER}
                  >
                    Mobility Provider
                  </Radio>
                  <Radio
                    name="userRole"
                    inline
                    onChange={() => setUserRole(USER_ROLES.SERVICE_PROVIDER)}
                    checked={userRole === USER_ROLES.SERVICE_PROVIDER}
                  >
                    Service Provider
                  </Radio>
                </FormGroup>
              </Row>
              <Row>
                <FormGroup>
                  <ControlLabel>Background Image</ControlLabel>
                  <br />
                  {React.Children.map(
                    [
                      require('../../assets/images/sidebar-1.jpg'),
                      require('../../assets/images/sidebar-2.jpg'),
                      require('../../assets/images/sidebar-3.jpg'),
                      require('../../assets/images/sidebar-4.jpg'),
                      require('../../assets/images/sidebar-5.jpg'),
                    ],
                    child => {
                      return (
                        <Radio
                          name="radioGroup"
                          inline
                          onChange={() => handleBackgroundImage(child)}
                          checked={enableBackgroundImage && backgroundImage === child}
                        >
                          <img
                            alt={child}
                            src={child}
                            width={100}
                            height={150}
                            style={{border: '1px solid grey'}}
                          />
                        </Radio>
                      );
                    }
                  )}
                  <Radio
                    name="radioGroup"
                    inline
                    onChange={handleNoneBackgroundImage}
                    checked={!enableBackgroundImage}
                  >
                    <div style={{border: '1px solid lightgrey', width: 100, height: 150}}>
                      None
                    </div>
                  </Radio>
                </FormGroup>
              </Row>
              <Row>
                <Col sm={12} className="col-padding">
                  <TableCard
                    isLoading={isLoading}
                    title={'Providers linked to account'}
                    data={providers}
                    labels={[
                      {
                        field: 'providerId',
                        caption: 'Id',
                        dataSort: true,
                        width: '10%',
                        isKey: true,
                      },
                      {
                        field: 'providerName',
                        caption: 'providerName',
                        dataSort: true,
                        width: '40%',
                      },
                      {
                        field: 'roleHash',
                        caption: 'roleHash',
                        dataSort: true,
                        width: '80%',
                      },
                      {
                        field: 'role',
                        caption: 'roleType',
                        dataSort: true,
                        width: '40%',
                      },
                    ]}
                    onRowClick={row => {
                      updateSelectedProvider(row);
                    }}
                    selected={[selectedProvider?.providerId]}
                  />
                </Col>
              </Row>
              {providerCards()}
            </Grid>
          </Card.Content>
        </Card>
      </Col>
    </div>
  );
};

const mapStateToProps = state => ({
  enableBackgroundImage: state.ThemeOptions.enableBackgroundImage,
  backgroundImage: state.ThemeOptions.backgroundImage,
  backendBaseUrl: state.Settings.backendBaseUrl,
  userRole: state.User.role,
});

const mapDispatchToProps = dispatch => ({
  setEnableBackgroundImage: isEnabled => dispatch(setEnableBackgroundImage(isEnabled)),
  setBackgroundImage: url => dispatch(setBackgroundImage(url)),
  setBackendBaseUrl: baseUrl => dispatch(setBackendBaseUrl(baseUrl)),
  setUserRole: role => dispatch(setUserRoleAndUpdateAccentColor(role)),
  updateSelectedProvider: provider => dispatch(updateSelectedProviderRole(provider)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
