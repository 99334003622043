import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import {fetchRolesByAddress} from '../stores/user/user.actions';

export default function useFetchRolesByAddress() {
  const dispatch = useDispatch();
  const {byId, error, loading, lastUpdated} = useSelector(state => state.User);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const TTL = 1000 * 60;

    const isExpired =
      typeof lastUpdated?.getTime === 'function'
        ? new Date().getTime() - lastUpdated.getTime() > TTL
        : true;
    if (!isExpired || loading) return;

    dispatch(fetchRolesByAddress());

    return () => source.cancel();
  }, [dispatch, lastUpdated, loading]);

  return [byId, error, loading];
}
