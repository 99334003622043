import React from 'react';
import {Col, Row, Grid} from 'react-bootstrap';
import Card from '../../components/Card';
import {ServiceOfferForm} from './index';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {addServiceOffer} from '../../stores/serviceOffer/serviceOffer.actions';
import useFetchServiceOffers from '../../hooks/useFetchServiceOffers';
import {notificationsOptions} from '../../helpers';
import {showNotification} from '../../stores/ui/layout.reducer';

const ServiceOfferFormPage = ({addServiceOffer, showNotification}) => {
  const history = useHistory();

  const [serviceOffer, error, isLoading] = useFetchServiceOffers();

  if ((!serviceOffer && !error) || isLoading) {
    return (
      <div className="main-content">
        <Card>
          <Card.Header>Loading...</Card.Header>
          <Card.Content isLoading={isLoading} />
        </Card>
      </div>
    );
  }

  const handleSubmit = async values => {
    const serviceOffer = {
      title: values.title,
      serviceType: values.serviceType,
      operationArea: values.operationArea,
      description: values.description,
    };
    const result = await addServiceOffer(serviceOffer);

    let notificationsOps;
    if (result.type === 'ADD_SERVICE_OFFER_SUCCESS') {
      notificationsOps = notificationsOptions(
        'success',
        'Create service offer',
        'Service offer created successfully'
      );
      history.push(`/serviceOffers/${result.payload.serviceOfferId}`);
    } else if (result.type === 'ADD_SERVICE_OFFER_FAILURE') {
      notificationsOps = notificationsOptions(
        'error',
        'Create service offer',
        'Creation of service offer failed'
      );
    }
    showNotification(notificationsOps);
  };

  return (
    <div className="main-content">
      <div
        style={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 2000,
          top: '30%',
          width: '50%',
          display: 'flex',
          justifyContent: 'center',
        }}
      ></div>
      <Grid
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Row className="row-margin">
          <Col lg={12} className="col-padding">
            <Card>
              <Card.Header hasDivider={true}>create service offer</Card.Header>
              <Card.Content>
                <ServiceOfferForm
                  onSubmit={values => handleSubmit(values)}
                  initialValues={{}}
                />
              </Card.Content>
            </Card>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => ({
  error: state => state.ServiceOffers.error,
});

const mapDispatchToProps = dispatch => ({
  addServiceOffer: serviceOffer => dispatch(addServiceOffer(serviceOffer)),
  showNotification: notificationsOps => dispatch(showNotification(notificationsOps)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceOfferFormPage);
