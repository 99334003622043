//@flow

import React from 'react';
import Card from '../../components/Card';
import {connect} from 'react-redux';
import {JobForm} from '../../pages/JobForm';

const JobEditCard = ({job, onSubmit}) => {
  if (!job) {
    return (
      <Card>
        <Card.Header>Job Details</Card.Header>
      </Card>
    );
  }

  const handleSubmit = values => {
    onSubmit(values);
  };

  return (
    <Card>
      <Card.Header>Job Details</Card.Header>
      <Card.Content>
        <JobForm onSubmit={values => handleSubmit(values)} initialValues={job} />
      </Card.Content>
    </Card>
  );
};

const mapStateToProps = (state, ownProps) => ({
  job: ownProps.job,
});

export default connect(mapStateToProps)(JobEditCard);
