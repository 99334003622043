/* Use nominatim openstreetmap to get an geo area in  geoJSON format. Nominatim may return more than one result. (also Points (Coordinates) and not only polygons).
  We search for Polygons and MultiPolygons in the response and return the first match*/

import {useEffect, useState} from 'react';
import axios from 'axios';

export default function useGeoArea(operationArea) {
  const [geoArea, setGeoArea] = useState(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getAreaPolygon = async area => {
      const resp = await axios.get(
        `https://nominatim.openstreetmap.org/search?format=geojson&polygon_geojson=1&q=${area}`
      );
      //search for polygon structure in response
      const features = resp.data.features;
      for (let f of features) {
        if (f.geometry.type === 'Polygon' || f.geometry.type === 'MultiPolygon') {
          setGeoArea(f);
          setLoading(false);
          break;
        }
      }
    };
    if (operationArea) {
      setLoading(true);
      getAreaPolygon(operationArea);
    }
  }, [operationArea]);
  return [geoArea, setGeoArea, loading];
}
