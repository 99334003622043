import axios from 'axios';

export const createServiceRequest = async serviceRequest => {
  console.log(serviceRequest);
  const resp = await axios.post('api/contracts/createservicerequest', {
    description: serviceRequest.description,
    vehicleId: serviceRequest.vehicleId,
    serviceType: serviceRequest.serviceType,
    price: serviceRequest.price,
    providerId: serviceRequest.providerId,
  });

  return resp.data;
};
