import React, {useState} from 'react';
import {Collapse} from 'react-bootstrap';
import {connect, useSelector} from 'react-redux';
import cx from 'classnames';
import {NavListItem} from './Nav';

const UserInfo = ({role}) => {
  const [isUserMenuVisible, setUserMenuVisible] = useState(false);
  const {familyName, givenName, imageUrl} = useSelector(state => state.User);
  const Username = <div className="username">{`${givenName} ${familyName ?? ' '}`}</div>;
  return (
    <div className={'user-wrapper'}>
      <div className="user">
        <img src={imageUrl} alt="User Avatar" className="photo" />
        <div className="userinfo">
          {Username}
          <div className="title">{role}</div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  role: state.User.role,
});

export default connect(mapStateToProps)(UserInfo);
