import React from 'react';
import {Field, reduxForm} from 'redux-form';
import renderField from '../../components/FormField';
import {ControlLabel, FormGroup} from 'react-bootstrap';
import TextButton from '../../components/TextButton';
import {grantProviderMember} from '../../contracts/ProviderRegistry/contractMethods/grantProviderMember';
import {connect, useSelector} from 'react-redux';
import {showNotification} from '../../stores/ui/layout.reducer';
import {notificationsOptions} from '../../helpers';

const GrantMemberForm = ({handleSubmit, showNotification}) => {
  const {selectedProvider} = useSelector(state => state.User);

  const grantMember = async ({accountAddress}) => {
    try {
      await grantProviderMember(selectedProvider?.providerId, accountAddress);
      const notificationsOps = notificationsOptions(
        'success',
        'Grant member role',
        'granted member role successfully'
      );
      showNotification(notificationsOps);
    } catch (error) {
      const notificationsOps = notificationsOptions(
        'error',
        'Grant member role',
        'Grant member role failed'
      );
      showNotification(notificationsOps);
    }
  };

  return (
    <form onSubmit={handleSubmit(grantMember)}>
      <FormGroup className="col-md-12 col-padding">
        <ControlLabel>accountAddress</ControlLabel>
        <Field type="text" name="accountAddress" validate={[]} component={renderField} />
      </FormGroup>
      <FormGroup className="text-center">
        <TextButton caption="Submit" type="submit" />
      </FormGroup>
    </form>
  );
};

const mapDispatchToProps = dispatch => ({
  showNotification: notificationsOps => dispatch(showNotification(notificationsOps)),
});

export default reduxForm({
  form: 'grantMemberForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(connect(null, mapDispatchToProps)(GrantMemberForm));
