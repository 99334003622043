import React from 'react';
import {Field, reduxForm, change, initialize} from 'redux-form';
import renderField from '../../components/FormField';
import {ControlLabel, FormGroup} from 'react-bootstrap';
import TextButton from '../../components/TextButton';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

const unit = '€';

const ServiceRequestForm = ({handleSubmit, initialValues, price, ...props}) => {
  const addUnit = (e, newValue) => {
    e.preventDefault();
    const i = newValue.indexOf('.');
    let asFloat;
    if (newValue.length === 0) asFloat = '0.00';
    else if (i < 0) asFloat = newValue + '.00';
    else if (newValue.length - 1 === i) asFloat = newValue + '00';
    else if (i === newValue.length - 2) asFloat = newValue + '0';
    else asFloat = newValue;

    props.change('serviceRequestForm', 'price', asFloat + unit);
  };

  const removeUnit = event => {
    const value = event.target.value;
    if (value !== undefined && value.length > 0)
      props.change('serviceRequestForm', 'price', value.substring(0, value.length - 1));
  };

  const checkIfNumber = (e, value, prevValue) => {
    if (typeof value === 'undefined') return '0.00€';
    const i = value.indexOf('.');
    const decimals = i > 0 ? value.length - 1 - value.indexOf('.') : 0;
    const tryToAddDigit = value.length > prevValue.length;

    return isNaN(value) ||
      (isNaN(parseFloat(value)) && value !== '') ||
      (decimals > 2 && tryToAddDigit)
      ? e.preventDefault()
      : value;
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormGroup className="col-md-12 col-padding">
        <ControlLabel>description</ControlLabel>
        <Field type="textbox" name="description" validate={[]} component={renderField} />
      </FormGroup>
      <FormGroup className="col-md-6 col-padding">
        <ControlLabel>serviceType</ControlLabel>
        <Field
          className="form-control"
          name="serviceType"
          component="select"
          validate={[]}
        >
          <option disabled hidden label="please choose" value={''} />
          <option value="relocation">relocation</option>
          <option value="maintenance">maintenance</option>
          <option value="others">others</option>
        </Field>
      </FormGroup>
      <FormGroup className="col-md-6 col-padding">
        <ControlLabel>max offer</ControlLabel>
        <Field
          type="text"
          name="price"
          placeholder="00.00€"
          onChange={(e, value, prevValue) => checkIfNumber(e, value, prevValue)}
          onFocus={e => removeUnit(e)}
          onBlur={(e, newValue) => addUnit(e, newValue)}
          component={renderField}
        />
      </FormGroup>
      <FormGroup className="col-md-12 col-padding text-center">
        <TextButton caption="Submit" type="submit" />
      </FormGroup>
    </form>
  );
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({change, initialize}, dispatch);
};

const mapStateToProps = state => ({
  price: state.form.serviceRequestForm,
});

export default reduxForm({
  form: 'serviceRequestForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(connect(mapStateToProps, mapDispatchToProps)(ServiceRequestForm));
