//@flow

import React, {ReactChildren} from 'react';
import SpinnerContainer from '../SpinnerContainer/SpinnerContainer';

const Header = ({children, subtitle, hasDivider = false}) => {
  return (
    <div
      className="header"
      style={hasDivider ? {borderBottom: '1px solid #ddd', marginBottom: '10px'} : null}
    >
      <h5 className="title">{children}</h5>
      <p className="category">{subtitle}</p>
    </div>
  );
};
Header.displayName = 'Header';

type Props = {
  isLoading?: boolean,
  style?: {},
  children?: ReactChildren,
};

const Content = ({children, isLoading = false, style = {}}: Props) => {
  return (
    <div className="content" style={style}>
      <SpinnerContainer isLoading={isLoading}>{children}</SpinnerContainer>
    </div>
  );
};
Content.displayName = 'Content';

const Footer = ({children}) => {
  return (
    <div className="footer">
      <div className="legend">
        {React.Children.map(children, child => {
          return <div className="item">{child}</div>;
        })}
      </div>
    </div>
  );
};
Footer.displayName = 'Legend';

const Card = ({children}) => {
  return (
    <div className="card">
      {React.Children.map(children, child => {
        if (child.type.displayName === 'Header') {
          return child;
        }
        if (child.type.displayName === 'Content') {
          return child;
        }
        if (child.type.displayName === 'Legend') {
          return child;
        }
        return child;
      })}
    </div>
  );
};

Card.Header = Header;
Card.Content = Content;
Card.Legend = Footer;

export default Card;
