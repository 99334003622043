// @flow

import type {Job} from './job.types';

export const FETCH_JOBS_STARTED: 'FETCH_JOBS_STARTED' = 'FETCH_JOBS_STARTED';
export const FETCH_JOBS_SUCCESS: 'FETCH_JOBS_SUCCESS' = 'FETCH_JOBS_SUCCESS';
export const FETCH_JOBS_FAILURE: 'FETCH_JOBS_FAILURE' = 'FETCH_JOBS_FAILURE';

export type FETCH_JOBS_STARTED_ACTION = {
  type: typeof FETCH_JOBS_STARTED,
};

export type FETCH_JOBS_SUCCESS_ACTION = {
  type: typeof FETCH_JOBS_SUCCESS,
  payload: {id: Job},
  allIds: string[],
};

export type FETCH_JOBS_FAILURE_ACTION = {
  type: typeof FETCH_JOBS_FAILURE,
  payload: any,
};

export const FETCH_JOB_BY_ID_STARTED = 'FETCH_JOB_BY_ID_STARTED';
export const FETCH_JOB_BY_ID_SUCCESS = 'FETCH_JOB_BY_ID_SUCCESS';
export const FETCH_JOB_BY_ID_FAILURE = 'FETCH_JOB_BY_ID_FAILURE';

export type FETCH_JOB_BY_ID_STARTED_ACTION = {
  type: typeof FETCH_JOB_BY_ID_STARTED,
};

export type FETCH_JOB_BY_ID_SUCCESS_ACTION = {
  type: typeof FETCH_JOB_BY_ID_SUCCESS,
  payload: [{id: Job}],
  allIds: string[],
};

export type FETCH_JOB_BY_ID_FAILURE_ACTION = {
  type: typeof FETCH_JOB_BY_ID_FAILURE,
  payload: any,
};

export const ADD_JOB_STARTED: 'ADD_JOB_STARTED' = 'ADD_JOB_STARTED';
export const ADD_JOB_SUCCESS: 'ADD_JOB_SUCCESS' = 'ADD_JOB_SUCCESS';
export const ADD_JOB_FAILURE: 'ADD_JOB_FAILURE' = 'ADD_JOB_FAILURE';

export type ADD_JOB_STARTED_ACTION = {
  type: typeof ADD_JOB_STARTED,
};

export type ADD_JOB_SUCCESS_ACTION = {
  type: typeof ADD_JOB_SUCCESS,
  payload: Job,
};

export type ADD_JOB_FAILURE_ACTION = {
  type: typeof ADD_JOB_FAILURE,
  payload: any,
};

export const UPDATE_JOB_STARTED: 'UPDATE_JOB_STARTED' = 'UPDATE_JOB_STARTED';
export const UPDATE_JOB_SUCCESS: 'UPDATE_JOB_SUCCESS' = 'UPDATE_JOB_SUCCESS';
export const UPDATE_JOB_FAILURE: 'UPDATE_JOB_FAILURE' = 'UPDATE_JOB_FAILURE';

export type UPDATE_JOB_STARTED_ACTION = {
  type: typeof UPDATE_JOB_STARTED,
};

export type UPDATE_JOB_SUCCESS_ACTION = {
  type: typeof UPDATE_JOB_SUCCESS,
  payload: Job,
};

export type UPDATE_JOB_FAILURE_ACTION = {
  type: typeof UPDATE_JOB_FAILURE,
  payload: any,
};

export const DELETE_JOB_STARTED: 'DELETE_JOB_STARTED' = 'DELETE_JOB_STARTED';
export const DELETE_JOB_SUCCESS: 'DELETE_JOB_SUCCESS' = 'DELETE_JOB_SUCCESS';
export const DELETE_JOB_FAILURE: 'DELETE_JOB_FAILURE' = 'DELETE_JOB_FAILURE';

export type DELETE_JOB_STARTED_ACTION = {
  type: typeof DELETE_JOB_STARTED,
};

export type DELETE_JOB_SUCCESS_ACTION = {
  type: typeof DELETE_JOB_SUCCESS,
  payload: Job,
};

export type DELETE_JOB_FAILURE_ACTION = {
  type: typeof DELETE_JOB_FAILURE,
  payload: any,
};

export const ACCEPT_JOB_STARTED: 'ACCEPT_JOB_STARTED' = 'ACCEPT_JOB_STARTED';
export const ACCEPT_JOB_SUCCESS: 'ACCEPT_JOB_SUCCESS' = 'ACCEPT_JOB_SUCCESS';
export const ACCEPT_JOB_FAILURE: 'ACCEPT_JOB_FAILURE' = 'ACCEPT_JOB_FAILURE';

export type ACCEPT_JOB_STARTED_ACTION = {
  type: typeof ACCEPT_JOB_STARTED,
};

export type ACCEPT_JOB_SUCCESS_ACTION = {
  type: typeof ACCEPT_JOB_SUCCESS,
  payload: Job,
};

export type ACCEPT_JOB_FAILURE_ACTION = {
  type: typeof ACCEPT_JOB_FAILURE,
  payload: any,
};

export const REJECT_JOB_STARTED: 'REJECT_JOB_STARTED' = 'REJECT_JOB_STARTED';
export const REJECT_JOB_SUCCESS: 'REJECT_JOB_SUCCESS' = 'REJECT_JOB_SUCCESS';
export const REJECT_JOB_FAILURE: 'REJECT_JOB_FAILURE' = 'REJECT_JOB_FAILURE';

export type REJECT_JOB_STARTED_ACTION = {
  type: typeof REJECT_JOB_STARTED,
};

export type REJECT_JOB_SUCCESS_ACTION = {
  type: typeof REJECT_JOB_SUCCESS,
  payload: Job,
};

export type REJECT_JOB_FAILURE_ACTION = {
  type: typeof REJECT_JOB_FAILURE,
  payload: any,
};

export const UPDATE_SELECTED_JOB: 'UPDATE_SELECTED_JOB' = 'UPDATE_SELECTED_JOB';

export type UPDATE_SELECTED_JOB_ACTION = {
  type: typeof UPDATE_SELECTED_JOB,
  payload: Job,
};

type Action =
  | FETCH_JOBS_STARTED_ACTION
  | FETCH_JOBS_SUCCESS_ACTION
  | FETCH_JOBS_FAILURE_ACTION
  | FETCH_JOB_BY_ID_STARTED_ACTION
  | FETCH_JOB_BY_ID_SUCCESS_ACTION
  | FETCH_JOB_BY_ID_FAILURE_ACTION
  | ADD_JOB_STARTED_ACTION
  | ADD_JOB_SUCCESS_ACTION
  | ADD_JOB_FAILURE_ACTION
  | UPDATE_JOB_STARTED_ACTION
  | UPDATE_JOB_SUCCESS_ACTION
  | UPDATE_JOB_FAILURE_ACTION
  | DELETE_JOB_STARTED_ACTION
  | DELETE_JOB_SUCCESS_ACTION
  | DELETE_JOB_FAILURE_ACTION
  | ACCEPT_JOB_STARTED_ACTION
  | ACCEPT_JOB_SUCCESS_ACTION
  | ACCEPT_JOB_FAILURE_ACTION
  | REJECT_JOB_STARTED_ACTION
  | REJECT_JOB_SUCCESS_ACTION
  | REJECT_JOB_FAILURE_ACTION
  | UPDATE_SELECTED_JOB_ACTION;

type State = {
  byId: {
    id?: Job,
  },
  allIds: string[],
  loading: boolean,
  error: any,
  lastUpdated?: Date,
  selectedJob?: Job,
};

const initialState: State = {
  byId: {},
  allIds: [],
  loading: false,
  error: null,
};

export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case FETCH_JOBS_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_JOBS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        byId: action.payload,
        allIds: [],
        lastUpdated: new Date(),
      };
    case FETCH_JOBS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_JOB_BY_ID_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_JOB_BY_ID_SUCCESS:
      const job = action.payload;
      return {
        ...state,
        loading: false,
        error: null,
        byId: {...state.byId, [job.jobId]: {...job}},
        lastUpdated: new Date(),
      };
    case ADD_JOB_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        byId: {
          ...state.byId,
          [action.payload.jobId]: {
            ...action.payload,
            relatedViolations: [],
            comments: [],
            attachments: [],
          },
        },
        allIds: state.allIds.find(id => id === action.payload.jobId)
          ? state.allIds
          : [...state.allIds, action.payload.jobId],
      };
    case ADD_JOB_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_JOB_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        byId: {...state.byId, [action.payload.jobId]: {...action.payload}},
      };
    case UPDATE_JOB_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_JOB_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_JOB_SUCCESS:
      let rest;
      rest = state.byId;
      delete rest[action.payload.jobId];

      return {
        ...state,
        loading: false,
        error: null,
        byId: rest,
        allIds: Object.keys(rest),
      };
    case DELETE_JOB_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ACCEPT_JOB_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ACCEPT_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        byId: {
          ...state.byId,
          [action.payload.jobId]: {
            ...state.byId[action.payload.jobId],
            ...action.payload,
          },
        },
      };
    case ACCEPT_JOB_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case REJECT_JOB_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REJECT_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        byId: {
          ...state.byId,
          [action.payload.jobId]: {
            ...state.byId[action.payload.jobId],
            ...action.payload,
          },
        },
      };
    case REJECT_JOB_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_SELECTED_JOB:
      return {
        ...state,
        loading: false,
        error: null,
        selectedJob: action.payload,
      };
    default:
      return state;
  }
};
