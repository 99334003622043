import React from 'react';
import {Link, useLocation} from 'react-router-dom';

export const NavListItem = ({caption, to, iconClass}) => {
  const {pathname} = useLocation();
  const isActive = pathname === to ? true : false;
  return (
    <li className={isActive ? 'activeNav' : null}>
      <Link to={to}>
        {
          <>
            <i
              hidden={!iconClass}
              className={isActive ? `${iconClass} pe-inverse` : iconClass}
            />
            <p className={isActive ? 'activeNavText' : null}>{caption}</p>
          </>
        }
      </Link>
    </li>
  );
};
