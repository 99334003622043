import axios from 'axios';

export const b_updateServiceOffer = async (
  serviceOfferId,
  newTitle,
  newDescription,
  newServiceType,
  newOperationArea
) => {
  const resp = await axios.post('api/contracts/updateserviceoffer/' + serviceOfferId, {
    title: newTitle,
    description: newDescription,
    serviceType: newServiceType,
    operationArea: newOperationArea,
  });
  return resp.data;
};
