import React, {useState, useRef, useEffect} from 'react';
import {connect, useSelector} from 'react-redux';
import './optimalRelocations.scss';
import {useHistory} from 'react-router-dom';
import {Col, Row, Grid} from 'react-bootstrap';
import TableCard from '../../components/TableCard';
import {updateSelectedVehicle} from '../../stores/vehicle/vehicle.actions';
import {ControlLabel} from 'react-bootstrap';
import 'leaflet-control-geocoder/dist/Control.Geocoder.js';
import {addServiceRequest} from '../../stores/serviceRequest/serviceRequest.actions';
import TextButton from '../../components/TextButton';
import {Button} from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import useEvents from '../../hooks/useEvents';
import OptimalReolcationForm from './optimalRelocationForm';
import VehiclesPerEventForm from './VehiclesPerEventForm';
import {getRelocationList} from './api';
import EventMap from './EventMap';
import {showNotification} from '../../stores/ui/layout.reducer';
import {notificationsOptions} from '../../helpers';
import useFetchVehicles from '../../hooks/useFetchVehicles';
import {IconSelector} from '../../components/IconSelector/IconSelector';
import {usePrevious} from '../../hooks/usePrevious';

const OptimalRelocations = ({
  selectedVehicle,
  updateSelectedVehicle,
  addServiceRequest,
  showNotification,
}) => {
  const [vehiclesById, v_error, v_isLoading] = useFetchVehicles();
  const vehicles = vehiclesById ? Object.values(vehiclesById) : [];
  // fetch vehicles owned by selected provider and show them on the map as white markers

  let vehicleMarker = [];
  vehicleMarker = vehicles.map(vehicle => {
    var icon = IconSelector({vehicleType: vehicle.vehicleType});
    return {
      position: [vehicle.currentPosition.lat, vehicle.currentPosition.lng],
      id: vehicle.vehicleId,
      icon: icon,
      relocate: false,
    };
  });

  const {selectedProvider} = useSelector(state => state.User);
  const providerId = selectedProvider?.providerId;
  const [vehiclesToMove, setVehiclesToMove] = useState([]);
  const history = useHistory();
  const [newCoord, setNewCoord] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [showEvents, setShowEvents] = useState(true);
  const [showVehicles, setShowVehicles] = useState(true);
  const [geoLoc, setGeoLoc] = useState([52.520007, 13.404954]); // map center coordinates
  const [zoom, setZoom] = useState(11); // map center coordinates
  const [events_, loading] = useEvents(geoLoc);
  const [newEvent, setNewEvent] = useState([]);
  const action = {delete: 'delete'};
  const mapRef = useRef(null);
  const [waiting, setWaiting] = useState(false);
  const events = events_ ? events_ : [];

  const closePopups = () => {
    mapRef.current.leafletElement.closePopup();
  };

  // update markers when useFetchVehicles finished
  const prev = usePrevious(markers);
  useEffect(() => {
    if (!prev || vehicleMarker.length !== prev.length) {
      setMarkers(vehicleMarker);
    }
  }, [prev, vehicleMarker]);

  const handleSubmit = async values => {
    /** Action of "Submit"-Button in the "Additional Services" Tab that is available upon
     *  filling in max. budget and price per kilometer in the component defined by ./optimalRelocationsForm.js
     *
     *  Stores a list of vehicle in vehiclesToMove. The list contains the most suitable vehicles
     *  for relocation depending on the request. The requests consists of an EventLocation, a budget the provider is willing to pay,
     *  a price per kilometer and the the number of vehicles demanded for each event
     *
     * @param {JSONObject} values :
     *  @param {} price : price per kilometer for relocation [inserted into the ./optimalRelocationsForm.js]
     *  @param {} budget : total budget available for relocation [inserted into the ./optimalRelocationsForm.js]
     */
    // Abort and show alert, if <newCoord> is an empty array
    if (newCoord.length < 1) {
      alert('Please specify a new location for your assets by clicking on the map');
      return;
    }

    // request candidate list of relocation service requests from backend
    setWaiting(true);
    const vehicleList = await getRelocationList(
      newCoord,
      parseInt(values.price.replace('.', ''), 10),
      parseInt(values.budget.replace('.', ''), 10),
      providerId
    );
    setWaiting(false);
    // update state VehiclesToMove
    setVehiclesToMove(vehicleList);

    // update the bg color from white to blue of markers (vehicles) the user wants to relocate
    const markersCopy = markers;
    vehicleList.forEach(bestVehicle => {
      var icon = IconSelector({vehicleType: bestVehicle.type, status: 'inUse'}); // use "inUse" for blue markers
      const indexOfMarker = markers.findIndex(m => {
        return bestVehicle.vehicleId === m.id;
      });
      markersCopy[indexOfMarker] = {
        ...markersCopy[indexOfMarker],
        icon: icon,
        relocate: true,
        place: bestVehicle.eventId,
      };
    });
    setMarkers(markersCopy);
  };

  /* The providers selection of vehicles 
  A request is created for every vehicle that the provider requests to send to an event.
  */
  const confirmJobs = async () => {
    setWaiting(true);
    let successful_tx = 0;
    let failed_tx = 0;

    for (let vehicleToMoveCounter in vehiclesToMove) {
      const vehicleToMove = vehiclesToMove[vehicleToMoveCounter];
      const serviceRequests = newCoord.map(coord => {
        let numberedPrice = parseInt(vehicleToMove.relocationPrice.replace(/€|\./g, ''));

        return {
          description: `Please relocate vehicle to [${coord.id}].`,
          price: numberedPrice,
          serviceType: 'Relocation',
          vehicleId: vehicleToMove.vehicleId,
          providerId: providerId,
          deleted: false,
        };
      });

      await Promise.all(
        serviceRequests.map(async (serviceRequest, index) => {
          const result = await addServiceRequest(serviceRequest);
          if (result.type === 'ADD_SERVICE_REQUEST_SUCCESS') {
            successful_tx++;
          } else if (result.type === 'ADD_SERVICE_REQUEST_FAILURE') {
            failed_tx++;
          }
        })
      );
    }
    setWaiting(false);
    let notificationsOps;
    if (failed_tx > 0) {
      notificationsOps = notificationsOptions(
        'error',
        'Create relocation requests',
        `Could not create ${failed_tx} out of ${successful_tx +
          failed_tx} relocation requests`
      );
    } else {
      notificationsOps = notificationsOptions(
        'success',
        'Create relocation requests',
        `${successful_tx} out of ${successful_tx +
          failed_tx} relocation requests created successfully`
      );
    }
    showNotification(notificationsOps);
    history.push('/serviceRequests');
  };

  /* Adds or removes the location of an event to/from a list, that contains all the events for which the provider is requesting vehicles  */
  const addOrRemoveNewCoords = (lat, lng, addrOrName, numberOfVehicles) => {
    let newCoords = newCoord.filter(coord => coord.lat !== lat && coord.lng !== lng);
    // Remove coord on double click
    if (newCoords.length === newCoord.length) {
      newCoords.push({
        lat: lat,
        lng: lng,
        id: addrOrName,
        demand: numberOfVehicles,
      });
    }
    return newCoords;
  };

  const addRemoveEvent = value => {
    let newEvents = newEvent.filter(event => event.id !== value.id);
    if (newEvents.length === newEvent.length) {
      newEvents.push(value);
    }
    return newEvents;
  };

  // removes a vehicle from the list, which contains all the vehicles the provider is requesting to relocate
  const deleteVehicle = (row, cell) => {
    const handleClickDeleteVehicle = id => {
      setVehiclesToMove(vehiclesToMove.filter(e => e.vehicleId !== id));

      // update the bg color from blue to white of the marker which belongs to the vehicle the user removed from the relocation table
      const markersCopy = markers;
      const indexOfMarker = markers.findIndex(m => {
        return id === m.id;
      });
      var icon = IconSelector({vehicleType: markersCopy[indexOfMarker].vehicleType});

      markersCopy[indexOfMarker] = {
        ...markersCopy[indexOfMarker],
        icon: icon,
        relocate: false,
      };
      setMarkers(markersCopy);
    };
    return (
      <Button variant="danger" onClick={() => handleClickDeleteVehicle(row.vehicleId)}>
        Delete
      </Button>
    );
    //
  };

  // sums up the relocation costs of all vehicles and returns the sum
  const total = () => {
    let tot = 0;
    vehiclesToMove.map((index, value) => {
      let price = index.relocationPrice.split('€');
      tot = tot + parseFloat(parseFloat(price[0]).toFixed(2));
    });

    return tot;
  };

  const updateCoordAndEvent = row => {
    setNewCoord(
      addOrRemoveNewCoords(row.location[1], row.location[0], row.title, undefined)
    );
    setNewEvent(addRemoveEvent(row));
  };

  // removes the event with the given id from the list
  const removeCoord = id => {
    const updatedCoord = newCoord.filter(coord => coord.id !== id);
    setNewCoord(updatedCoord);
  };

  const handleGeoLocChanged = info => {
    setGeoLoc([info.latLng.lat, info.latLng.lng]);
  };

  const handleEventsChanged = value => {
    setNewCoord(
      addOrRemoveNewCoords(value.location[1], value.location[0], value.title, undefined)
    );
    setNewEvent(addRemoveEvent(value));
    closePopups();
  };

  return (
    <div className="main-content">
      <Grid
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Row>
          <Col xs={6} lg={4} className="col-padding" style={{marginTop: 15}}>
            <>
              {newCoord.length < 1 ? (
                <ControlLabel>
                  Please ick
                  <span>
                    a <strong>Target</strong> location or an <strong>Event</strong>
                  </span>
                  by Clicking on the Map
                </ControlLabel>
              ) : (
                newCoord.map((coord, index) => {
                  return (
                    <VehiclesPerEventForm
                      key={index}
                      form={`vehiclesPerEventFor_${index}`}
                      coord={coord}
                      newCoord={newCoord}
                      onNumOfVehicleChanged={newCoord => {
                        setNewCoord(newCoord);
                      }}
                      onRemoveCoord={coordId => {
                        removeCoord(coordId);
                      }}
                    />
                  );
                })
              )}
              <OptimalReolcationForm
                initialValues={{price: '0.00€', budget: '100.00€'}}
                onSubmit={values => handleSubmit(values)}
              />
            </>
          </Col>
          <Col xs={12} lg={8} className="col-padding" style={{marginTop: 15}}>
            <EventMap
              onGeoLocChanged={info => handleGeoLocChanged(info)}
              onZoomChanged={zoom => setZoom(zoom)}
              mapRef={mapRef}
              geoLoc={geoLoc}
              zoom={zoom}
              newCoord={newCoord}
              markers={markers}
              events={events}
              showEvents={showEvents}
              eventsLoading={loading}
              showVehicles={showVehicles}
              onMapClick={r => {
                setNewCoord(
                  addOrRemoveNewCoords(r.center.lat, r.center.lng, r.name, undefined)
                );
              }}
              onEventsChanged={value => handleEventsChanged(value)}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={0} lg={4}>
            {vehiclesToMove.length > 0 && (
              <Row>
                <Col className="col-padding" style={{marginTop: 15}}>
                  <TextButton
                    caption={'Approve Vehicles for Relocation (Total: ' + total() + '€)'}
                    alt="Confirm the cration of the jobs"
                    type="submit"
                    onClick={() => confirmJobs()}
                  />
                </Col>
              </Row>
            )}
          </Col>
          <Col xs={12} lg={8}>
            <div style={{marginTop: 12, display: 'flex'}}>
              <div>
                <ControlLabel style={{marginTop: '5px', marginRight: 8}}>
                  Show Events On Map?
                </ControlLabel>
                <BootstrapSwitchButton
                  checked={true}
                  size="sm"
                  onChange={checked => {
                    setShowEvents(checked);
                  }}
                />
              </div>
              <div>
                <ControlLabel style={{marginTop: '5px', marginRight: 8, marginLeft: 16}}>
                  Show Vehicles On Map?
                </ControlLabel>
                <BootstrapSwitchButton
                  checked={true}
                  size="sm"
                  onChange={checked => {
                    setShowVehicles(checked);
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="col-padding" style={{marginTop: 15}}>
            <TableCard
              isLoading={waiting}
              title={vehiclesToMove.length > 0 ? 'Vehicle List To Move' : 'Next Events'}
              data={vehiclesToMove.length > 0 ? vehiclesToMove : events}
              rowButtons={vehiclesToMove.length > 0 ? [deleteVehicle] : ''}
              labels={
                vehiclesToMove.length > 0
                  ? [
                      {
                        field: 'vehicleId',
                        caption: 'ID',
                        dataSort: true,
                        width: '75%',
                        isKey: true,
                      },
                      {
                        field: 'displayName',
                        caption: 'name',
                        dataSort: true,
                        width: '75%',
                      },
                      {
                        field: 'provider',
                        caption: 'provider',
                        dataSort: true,
                        width: '75%',
                      },
                      {
                        field: 'status',
                        caption: 'Status',
                        dataSort: true,
                        width: '75%',
                      },
                      {
                        field: 'relocationPrice',
                        caption: 'Relocation price',
                        dataSort: true,
                        width: '100%',
                      },
                    ]
                  : [
                      {
                        field: 'title',
                        caption: 'name',
                        dataSort: true,
                        width: '75%',
                        isKey: true,
                      },
                      {
                        field: 'description',
                        caption: 'description',
                        dataSort: true,
                        width: '75%',
                      },
                      {
                        field: 'start',
                        caption: 'date',
                        dataSort: true,
                        width: '75%',
                      },
                      {
                        field: 'phq_attendance',
                        caption: 'people expected',
                        dataSort: true,
                        width: '50%',
                      },
                    ]
              }
              onRowClick={row => {
                vehiclesToMove.length > 0
                  ? updateSelectedVehicle(row)
                  : updateCoordAndEvent(row);
              }}
              selected={selectedVehicle ? [selectedVehicle.id] : null}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};
const mapStateToProps = state => ({
  state: state,
  softBackgroundColor: state.ThemeOptions.softBackgroundColor,
  backgroundColor: state.ThemeOptions.backgroundColor,
  selectedVehicle: state.Vehicles.selectedVehicle,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateSelectedVehicle: vehicle => dispatch(updateSelectedVehicle(vehicle)),
  addServiceRequest: serviceRequest => dispatch(addServiceRequest(serviceRequest)),
  showNotification: notificationsOps => dispatch(showNotification(notificationsOps)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OptimalRelocations);
