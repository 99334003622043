import React from 'react';
import {Field, reduxForm} from 'redux-form';
import renderField from '../../components/FormField';
import {ControlLabel, FormGroup} from 'react-bootstrap';
import TextButton from '../../components/TextButton';
import {updateProvider} from '../../stores/provider/provider.actions';
import {connect, useSelector} from 'react-redux';
import {showNotification} from '../../stores/ui/layout.reducer';
import {notificationsOptions} from '../../helpers';
const ChangeProviderNameForm = ({handleSubmit, updateProvider, showNotification}) => {
  const {selectedProvider} = useSelector(state => state.User);

  const changeProviderName = async ({providerName}) => {
    const result = await updateProvider({
      providerId: selectedProvider.providerId,
      providerName: providerName,
    });
    let notificationsOps;
    if (result.type === 'UPDATE_PROVIDER_SUCCESS') {
      notificationsOps = notificationsOptions(
        'success',
        'Update Provider',
        'Provider updated successfully'
      );
    } else if (result.type === 'UPDATE_PROVIDER_FAILURE') {
      notificationsOps = notificationsOptions(
        'error',
        'Update Provider',
        'Provider update failed'
      );
    }
    showNotification(notificationsOps);
  };

  return (
    <form onSubmit={handleSubmit(changeProviderName)}>
      <FormGroup className="col-md-12 col-padding">
        <ControlLabel>new providerName</ControlLabel>
        <Field type="text" name="providerName" validate={[]} component={renderField} />
      </FormGroup>
      <FormGroup className="text-center">
        <TextButton caption="Submit" type="submit" />
      </FormGroup>
    </form>
  );
};

const mapDispatchToProps = dispatch => ({
  updateProvider: provider => dispatch(updateProvider(provider)),
  showNotification: notificationsOps => dispatch(showNotification(notificationsOps)),
});

export default reduxForm({
  form: 'changeProviderNameForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(connect(null, mapDispatchToProps)(ChangeProviderNameForm));
