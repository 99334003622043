//@flow

import React from 'react';
import {connect} from 'react-redux';
import {Navbar, Nav, NavItem} from 'react-bootstrap';
import {toggleMobileNavVisibility} from '../../stores/ui/layout.reducer';
import useWindowSize from './useWindowSize';
import {onLogout} from '../../stores/user/user.actions';

type Props = {
  logout: () => void,
  toggleMobileNavVisibility: () => void,
};

const Header = ({logout, toggleMobileNavVisibility}: Props) => {
  const windowSize = useWindowSize();
  return (
    <Navbar fluid={true}>
      <Navbar.Header style={{display: 'flex', justifyContent: 'flex-start'}}>
        <button
          type="button"
          className="navbar-toggle"
          onClick={toggleMobileNavVisibility}
          style={{marginLeft: 15}}
        >
          <span className="sr-only">Toggle navigation</span>
          <span className="icon-bar" />
          <span className="icon-bar" />
          <span className="icon-bar" />
        </button>
      </Navbar.Header>

      <Navbar.Collapse style={{marginLeft: 0, paddingLeft: 0}}>
        <Nav hidden={windowSize.width < 993 ? '' : 'hidden'}>
          <NavItem onClick={toggleMobileNavVisibility}>
            <i className="pe-7s-menu" style={{fontSize: 24}} />
          </NavItem>
        </Nav>
        <Nav pullRight>
          {/* <NavDropdown title={<i className="fa fa-globe" />} id="right-nav-bar">
            <MenuItem>English</MenuItem>
            <MenuItem>Deutsch</MenuItem>
          </NavDropdown> */}
          <NavItem onClick={logout}>Log out</NavItem>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleMobileNavVisibility: () => dispatch(toggleMobileNavVisibility()),
  logout: () => dispatch(onLogout()),
});

export default connect(null, mapDispatchToProps)(Header);
