import React from 'react';
import {Field, reduxForm} from 'redux-form';
import renderField from '../../../components/FormField';
import {ControlLabel, FormGroup} from 'react-bootstrap';
import TextButton from '../../../components/TextButton';
import {addVehicle} from '../../../stores/vehicle/vehicle.actions';
import {connect} from 'react-redux';
import {showNotification} from '../../../stores/ui/layout.reducer';
import {notificationsOptions} from '../../../helpers';

const coordinates = value =>
  value && !/\d+\.\d+,\d+\.\d+/.test(value) ? 'Invalid location format' : undefined;

const VehicleForm = ({handleSubmit, addVehicle, showNotification}) => {
  const initVehicle = async values => {
    const vehicle = {
      vehicleId: values.vehicleId,
      location: values.location,
    };
    const result = await addVehicle(vehicle);

    let notificationsOps;
    if (result.type === 'ADD_VEHICLE_SUCCESS') {
      notificationsOps = notificationsOptions(
        'success',
        'Add vehicle',
        'Vehicle added successfully'
      );
    } else if (result.type === 'ADD_VEHICLE_FAILURE') {
      notificationsOps = notificationsOptions(
        'error',
        'Create vehicle',
        'Vehicle could not be added'
      );
    }
    showNotification(notificationsOps);
  };

  return (
    <form onSubmit={handleSubmit(initVehicle)}>
      <ControlLabel>vehicleId</ControlLabel>
      <Field type="text" name="vehicleId" component={renderField} />
      <ControlLabel>location in lat, lng</ControlLabel>
      <Field type="text" name="location" component={renderField} validate={coordinates} />
      <FormGroup className="text-center" style={{marginTop: 8}}>
        <TextButton caption="Submit" type="submit" />
      </FormGroup>
    </form>
  );
};

const mapDispatchToProps = dispatch => ({
  addVehicle: vehicle => dispatch(addVehicle(vehicle)),
  showNotification: notificationsOps => dispatch(showNotification(notificationsOps)),
});

export default reduxForm({
  form: 'vehicleForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(connect(null, mapDispatchToProps)(VehicleForm));
