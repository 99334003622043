import React from 'react';
import {Col, Row, Grid} from 'react-bootstrap';
import Card from '../../components/Card';
import ServiceRequestForm from './ServiceRequestForm';
import {connect, useSelector} from 'react-redux';
import MapCard from '../../components/MapCard/MapCard';
import NotFound from '../../components/NotFound/NotFound';
import VehicleDetailsCard from '../../components/VehicleDetailsCard';
import {addServiceRequest} from '../../stores/serviceRequest/serviceRequest.actions';
import {IconSelector} from '../../components/IconSelector/IconSelector';
import {useHistory} from 'react-router-dom';
import {notificationsOptions} from '../../helpers';
import {showNotification} from '../../stores/ui/layout.reducer';

const ServiceRequestFormPage = ({
  vehicle /*createServiceRequest*/,
  addServiceRequest,
  showNotification,
}) => {
  const providerId = useSelector(state => state.User?.selectedProvider?.providerId);
  const history = useHistory();
  const {loading} = useSelector(state => state.ServiceRequests);

  if (!vehicle) {
    return <NotFound entityName={'vehicle'} />;
  }

  const vehicleId = vehicle.vehicleId;

  const vehicleMarker = {
    ...vehicle,
    icon: IconSelector({vehicleType: vehicle.vehicleType, obj: 'serviceRequest'}),
  };

  const handleSubmit = async values => {
    const serviceRequest = {
      description: values.description,
      vehicleId: vehicleId,
      serviceType: values.serviceType,
      // remove . and parseInt afterwards correspondonds to mult with 100
      // price is stored in uint256 (cents) on blockchain
      price: parseInt(values.price.replace('.', ''), 10),
      providerId,
    };
    const result = await addServiceRequest(serviceRequest);

    let notificationsOps;
    if (result.type === 'ADD_SERVICE_REQUEST_SUCCESS') {
      notificationsOps = notificationsOptions(
        'success',
        'Create service request',
        'Service request created successfully'
      );
      history.push(`/serviceRequests/${result.payload.serviceRequestId}`);
    } else if (result.type === 'ADD_SERVICE_REQUEST_FAILURE') {
      notificationsOps = notificationsOptions(
        'error',
        'Create service request',
        'Create service request failed'
      );
    }
    showNotification(notificationsOps);
  };

  return (
    <div className="main-content">
      <Grid
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Row className="row-margin">
          <Col lg={6} className="col-padding">
            <Card>
              <Card.Header hasDivider={true}>
                Request Service for {vehicle.vehicleId}
              </Card.Header>
              <Card.Content isLoading={loading}>
                <ServiceRequestForm
                  onSubmit={values => handleSubmit(values)}
                  initialValues={{price: '0.00€'}}
                />
              </Card.Content>
            </Card>
          </Col>
          <Col lg={6} className="col-padding">
            <VehicleDetailsCard vehicle={vehicle} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="col-padding">
            <MapCard
              title="Vehicle Map"
              data={{markers: [vehicleMarker]}}
              selectedMarker={vehicleMarker}
              onSelectedMarkerChange={null}
              mapHeight="60vh"
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => ({
  vehicle: state.Vehicles.selectedVehicle,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  addServiceRequest: serviceRequest => dispatch(addServiceRequest(serviceRequest)),
  showNotification: notificationsOps => dispatch(showNotification(notificationsOps)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceRequestFormPage);
