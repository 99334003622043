import React from 'react';
import {connect} from 'react-redux';
import './serviceRequests.scss';
import {useHistory} from 'react-router-dom';
import {Col, Row, Grid} from 'react-bootstrap';
import TableCard from '../../components/TableCard';
import ServiceRequestDetailsCard from '../../components/ServiceRequestDetailsCard';
import MapCard from '../../components/MapCard/MapCard';
import useFetchServiceRequests from '../../hooks/useFetchServiceRequests';
import Card from '../../components/Card';
import NotFound from '../../components/NotFound';
import {updateSelectedServiceRequest} from '../../stores/serviceRequest/serviceRequest.actions';
import {IconSelector} from '../../components/IconSelector/IconSelector';
import useFetchVehicles from '../../hooks/useFetchVehicles';

const ServiceRequests = ({selectedServiceRequest, updateSelectedServiceRequest}) => {
  const history = useHistory();
  const [vehiclesById, v_error, v_isLoading] = useFetchVehicles();
  const [serviceRequestsById, error, isLoading] = useFetchServiceRequests();
  const serviceRequests = serviceRequestsById
    ? Object.values(serviceRequestsById).filter(sr => vehiclesById[sr.vehicleId])
    : [];
  if (
    (!serviceRequestsById && !error) ||
    (!vehiclesById && !v_error) ||
    isLoading ||
    v_isLoading
  ) {
    return (
      <div className="main-content">
        <Card>
          <Card.Header>Loading...</Card.Header>
          <Card.Content isLoading={isLoading || v_isLoading} />
        </Card>
      </div>
    );
  }

  if (error) {
    return <NotFound entityName={'serviceRequests'} />;
  }

  const serviceRequestsMarkers = serviceRequests.reduce((markers, serviceRequest) => {
    const vehicle = vehiclesById[serviceRequest.vehicleId];
    if (vehicle) {
      var icon = IconSelector({vehicleType: vehicle.vehicleType, obj: 'serviceRequest'});
      const currentPosition = vehicle.currentPosition;
      return markers.concat([{...serviceRequest, currentPosition, icon}]);
    } else return markers;
  }, []);

  const selectedServiceRequestMarker = selectedServiceRequest
    ? serviceRequestsMarkers.find(
        serviceRequestMarker =>
          serviceRequestMarker.serviceRequestId ===
          selectedServiceRequest.serviceRequestId
      )
    : null;

  return (
    <div className="main-content">
      <Grid
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Col xs={12} lg={8} className="col-padding" style={{marginTop: 15}}>
          <MapCard
            title="ServiceRequest Map"
            data={{markers: serviceRequestsMarkers}}
            selectedMarker={selectedServiceRequestMarker}
            mapHeight="60vh"
            onSelectedMarkerChange={serviceRequestMarker => {
              const nextSelectedServiceRequest = serviceRequests.find(
                serviceRequest =>
                  serviceRequest.vehicleId === serviceRequestMarker.vehicleId
              );
              updateSelectedServiceRequest(nextSelectedServiceRequest);
            }}
          />
        </Col>
        <Col xs={6} lg={4} className="col-padding" style={{marginTop: 15}}>
          <Col xs={12} className="col-padding-zero">
            <ServiceRequestDetailsCard
              serviceRequest={selectedServiceRequest}
              onMoreDetails={() => {
                console.log(selectedServiceRequest);
                history.push(
                  '/serviceRequests/' + selectedServiceRequest.serviceRequestId
                );
              }}
              serviceRequests={serviceRequests}
            />
          </Col>
        </Col>
        <Row>
          <Col xs={12} className="col-padding" style={{marginTop: 15}}>
            <TableCard
              title={'ServiceRequest List'}
              data={serviceRequests}
              labels={[
                {
                  field: 'serviceRequestId',
                  caption: 'Id',
                  dataSort: true,
                  width: '300px',
                  isKey: true,
                },
                {
                  field: 'vehicleId',
                  caption: 'vehicleId',
                  dataSort: true,
                  width: '50%',
                },
                {
                  field: 'price',
                  caption: 'price',
                  dataSort: true,
                  width: '50%',
                },
              ]}
              keyClickUrl={row => `/serviceRequests/${row.serviceRequestId}`}
              onRowClick={row => {
                console.log(row);
                updateSelectedServiceRequest(row);
              }}
              selected={
                selectedServiceRequest ? [selectedServiceRequest.serviceRequestId] : null
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};
const mapStateToProps = state => ({
  softBackgroundColor: state.ThemeOptions.softBackgroundColor,
  backgroundColor: state.ThemeOptions.backgroundColor,
  selectedServiceRequest: state.ServiceRequests.selectedServiceRequest,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateSelectedServiceRequest: serviceRequest =>
    dispatch(updateSelectedServiceRequest(serviceRequest)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceRequests);
