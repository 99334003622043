import React from 'react';
import {NavListItem} from './NavListItem';

/**
 * navigation list for mobility provider view
 */
export const MobilityProviderNav = () => {
  return (
    <ul className="nav">
      <NavListItem to="/" caption="Dashboard" iconClass="pe-7s-graph" />
      <NavListItem to="/serviceOffers" caption="Marketplace" iconClass="pe-7s-global" />
      <NavListItem to="/vehicles" caption="Vehicles" iconClass="pe-7s-bicycle" />
      <NavListItem
        to="/serviceRequests"
        caption="Service Requests"
        iconClass="pe-7s-chat"
      />
      <NavListItem to="/jobs" caption="Jobs" iconClass="pe-7s-note2" />
      <NavListItem to="/stations" caption="Stations" iconClass="pe-7s-flag" />
      <NavListItem
        to="/optimalRelocations"
        caption="Additional Services"
        iconClass="pe-7s-compass"
      />
      <NavListItem to="/settings" caption="Settings" iconClass="pe-7s-config" />
    </ul>
  );
};
