import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import {
  fetchJobsFailure,
  fetchJobsStarted,
  fetchJobsSuccess,
} from '../stores/job/job.actions';
import {schema, normalize} from 'normalizr';
import {getJobs} from '../contracts/JobRegistry/contractMethods/getJobs';
import {getJobById} from '../contracts/JobRegistry/contractMethods/getJobById';
import {usePrevious} from './usePrevious';

const jobSchema = new schema.Entity('jobs', {}, {idAttribute: 'jobId'});

const jobListSchema = new schema.Array(jobSchema);

export default function useFetchJobs() {
  const dispatch = useDispatch();
  const {byId, error, loading, lastUpdated} = useSelector(state => state.Jobs);
  const {role} = useSelector(state => state.User);
  const providerId = useSelector(state => state.User.selectedProvider?.providerId);
  const prevProviderId = usePrevious(providerId);
  const prevRole = usePrevious(role);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const TTL = 1000 * 60;

    const roleChanged = prevRole !== role;
    const providerChanged = prevProviderId !== providerId;

    const wasFetchedBefore = lastUpdated;
    const isExpired =
      wasFetchedBefore && new Date().getTime() - lastUpdated.getTime() > TTL;
    if ((wasFetchedBefore && !isExpired && !roleChanged && !providerChanged) || loading)
      return;

    const fetchJobs = async () => {
      dispatch(fetchJobsStarted());
      try {
        const bcJobs = await getJobs();

        let jobs = await Promise.all(
          bcJobs.map(async (value, index) => {
            const resp = await getJobById(value['jobId']);
            return resp;
          })
        );
        // MobiliyProvider: client, ServiceProvider: contractor
        if (role === 'Mobility Provider') {
          jobs = jobs.filter(job => job.clientId === providerId);
        } else if (role === 'Service Provider') {
          jobs = jobs.filter(job => job.contractorId === providerId);
        }

        const normalizedData = normalize(jobs, jobListSchema);

        dispatch(
          fetchJobsSuccess(normalizedData.entities['jobs'] ?? {}, normalizedData.result)
        );
      } catch (error) {
        dispatch(fetchJobsFailure(error));
      }
    };

    fetchJobs();

    return () => source.cancel();
  }, [dispatch, lastUpdated, role, providerId, prevRole, prevProviderId, loading]);
  return [byId, error, loading];
}
