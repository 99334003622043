//@flow

import React from 'react';
import {ClipLoader} from 'react-spinners';

type Props = {
  isLoading: boolean,
};

/**
 * a spinner is used to show that the content of a component is still loading
 */

const SpinnerContainer = ({isLoading = false, children}: Props) => {
  return (
    <>
      {isLoading ? (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ClipLoader size={60} color={'lightgrey'} />
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default SpinnerContainer;
