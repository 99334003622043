// @flow

import type {ServiceRequest} from './serviceRequestRequest.types';

export const FETCH_SERVICE_REQUESTS_STARTED: 'FETCH_SERVICE_REQUESTS_STARTED' =
  'FETCH_SERVICE_REQUESTS_STARTED';
export const FETCH_SERVICE_REQUESTS_SUCCESS: 'FETCH_SERVICE_REQUESTS_SUCCESS' =
  'FETCH_SERVICE_REQUESTS_SUCCESS';
export const FETCH_SERVICE_REQUESTS_FAILURE: 'FETCH_SERVICE_REQUESTS_FAILURE' =
  'FETCH_SERVICE_REQUESTS_FAILURE';

export type FETCH_SERVICE_REQUESTS_STARTED_ACTION = {
  type: typeof FETCH_SERVICE_REQUESTS_STARTED,
};

export type FETCH_SERVICE_REQUESTS_SUCCESS_ACTION = {
  type: typeof FETCH_SERVICE_REQUESTS_SUCCESS,
  payload: {id: ServiceRequest},
  allIds: string[],
};

export type FETCH_SERVICE_REQUESTS_FAILURE_ACTION = {
  type: typeof FETCH_SERVICE_REQUESTS_FAILURE,
  payload: any,
};

export const FETCH_SERVICE_REQUEST_BY_ID_STARTED = 'FETCH_SERVICE_REQUEST_BY_ID_STARTED';
export const FETCH_SERVICE_REQUEST_BY_ID_SUCCESS = 'FETCH_SERVICE_REQUEST_BY_ID_SUCCESS';
export const FETCH_SERVICE_REQUEST_BY_ID_FAILURE = 'FETCH_SERVICE_REQUEST_BY_ID_FAILURE';

export type FETCH_SERVICE_REQUEST_BY_ID_STARTED_ACTION = {
  type: typeof FETCH_SERVICE_REQUEST_BY_ID_STARTED,
};

export type FETCH_SERVICE_REQUEST_BY_ID_SUCCESS_ACTION = {
  type: typeof FETCH_SERVICE_REQUEST_BY_ID_SUCCESS,
  payload: {id: ServiceRequest},
  allIds: string[],
};

export type FETCH_SERVICE_REQUEST_BY_ID_FAILURE_ACTION = {
  type: typeof FETCH_SERVICE_REQUEST_BY_ID_FAILURE,
  payload: any,
};

export const ADD_SERVICE_REQUEST_STARTED: 'ADD_SERVICE_REQUEST_STARTED' =
  'ADD_SERVICE_REQUEST_STARTED';
export const ADD_SERVICE_REQUEST_SUCCESS: 'ADD_SERVICE_REQUEST_SUCCESS' =
  'ADD_SERVICE_REQUEST_SUCCESS';
export const ADD_SERVICE_REQUEST_FAILURE: 'ADD_SERVICE_REQUEST_FAILURE' =
  'ADD_SERVICE_REQUEST_FAILURE';

export type ADD_SERVICE_REQUEST_STARTED_ACTION = {
  type: typeof ADD_SERVICE_REQUEST_STARTED,
};

export type ADD_SERVICE_REQUEST_SUCCESS_ACTION = {
  type: typeof ADD_SERVICE_REQUEST_SUCCESS,
  payload: ServiceRequest,
};

export type ADD_SERVICE_REQUEST_FAILURE_ACTION = {
  type: typeof ADD_SERVICE_REQUEST_FAILURE,
  payload: any,
};

export const UPDATE_SERVICE_REQUEST_STARTED: 'UPDATE_SERVICE_REQUEST_STARTED' =
  'UPDATE_SERVICE_REQUEST_STARTED';
export const UPDATE_SERVICE_REQUEST_SUCCESS: 'UPDATE_SERVICE_REQUEST_SUCCESS' =
  'UPDATE_SERVICE_REQUEST_SUCCESS';
export const UPDATE_SERVICE_REQUEST_FAILURE: 'UPDATE_SERVICE_REQUEST_FAILURE' =
  'UPDATE_SERVICE_REQUEST_FAILURE';

export type UPDATE_SERVICE_REQUEST_STARTED_ACTION = {
  type: typeof UPDATE_SERVICE_REQUEST_STARTED,
};

export type UPDATE_SERVICE_REQUEST_SUCCESS_ACTION = {
  type: typeof UPDATE_SERVICE_REQUEST_SUCCESS,
  payload: ServiceRequest,
};

export type UPDATE_SERVICE_REQUEST_FAILURE_ACTION = {
  type: typeof UPDATE_SERVICE_REQUEST_FAILURE,
  payload: any,
};

export const DELETE_SERVICE_REQUEST_STARTED: 'DELETE_SERVICE_REQUEST_STARTED' =
  'DELETE_SERVICE_REQUEST_STARTED';
export const DELETE_SERVICE_REQUEST_SUCCESS: 'DELETE_SERVICE_REQUEST_SUCCESS' =
  'DELETE_SERVICE_REQUEST_SUCCESS';
export const DELETE_SERVICE_REQUEST_FAILURE: 'DELETE_SERVICE_REQUEST_FAILURE' =
  'DELETE_SERVICE_REQUEST_FAILURE';

export type DELETE_SERVICE_REQUEST_STARTED_ACTION = {
  type: typeof DELETE_SERVICE_REQUEST_STARTED,
};

export type DELETE_SERVICE_REQUEST_SUCCESS_ACTION = {
  type: typeof DELETE_SERVICE_REQUEST_SUCCESS,
  payload: ServiceRequest,
};

export type DELETE_SERVICE_REQUEST_FAILURE_ACTION = {
  type: typeof DELETE_SERVICE_REQUEST_FAILURE,
  payload: any,
};

export const UPDATE_SELECTED_SERVICE_REQUEST: 'UPDATE_SELECTED_SERVICE_REQUEST' =
  'UPDATE_SELECTED_SERVICE_REQUEST';

export type UPDATE_SELECTED_SERVICE_REQUEST_ACTION = {
  type: typeof UPDATE_SELECTED_SERVICE_REQUEST,
  payload: ServiceRequest,
};

type Action =
  | FETCH_SERVICE_REQUESTS_STARTED_ACTION
  | FETCH_SERVICE_REQUESTS_SUCCESS_ACTION
  | FETCH_SERVICE_REQUESTS_FAILURE_ACTION
  | FETCH_SERVICE_REQUEST_BY_ID_STARTED_ACTION
  | FETCH_SERVICE_REQUEST_BY_ID_SUCCESS_ACTION
  | FETCH_SERVICE_REQUEST_BY_ID_FAILURE_ACTION
  | ADD_SERVICE_REQUEST_STARTED_ACTION
  | ADD_SERVICE_REQUEST_SUCCESS_ACTION
  | ADD_SERVICE_REQUEST_FAILURE_ACTION
  | UPDATE_SERVICE_REQUEST_STARTED_ACTION
  | UPDATE_SERVICE_REQUEST_SUCCESS_ACTION
  | UPDATE_SERVICE_REQUEST_FAILURE_ACTION
  | DELETE_SERVICE_REQUEST_STARTED_ACTION
  | DELETE_SERVICE_REQUEST_SUCCESS_ACTION
  | DELETE_SERVICE_REQUEST_FAILURE_ACTION
  | UPDATE_SELECTED_SERVICE_REQUEST_ACTION;

type State = {
  byId: {
    id?: ServiceRequest,
  },
  allIds: string[],
  loading: boolean,
  error: any,
  lastUpdated?: Date,
};

const initialState: State = {
  byId: {},
  allIds: [],
  loading: false,
  error: null,
};

export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case FETCH_SERVICE_REQUESTS_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_SERVICE_REQUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        byId: action.payload,
        allIds: action.allIds,
        lastUpdated: new Date(),
      };
    case FETCH_SERVICE_REQUESTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_SERVICE_REQUEST_BY_ID_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_SERVICE_REQUEST_BY_ID_SUCCESS:
      const serviceRequest = action.payload;
      return {
        ...state,
        loading: false,
        error: null,
        lastUpdated: new Date(),
        byId: {...state.byId, [serviceRequest.serviceRequestId]: {...serviceRequest}},
      };
    case ADD_SERVICE_REQUEST_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        byId: {
          ...state.byId,
          [action.payload.serviceRequestId]: {
            ...action.payload,
          },
        },
        allIds: state.allIds.find(id => id === action.payload.serviceRequestId)
          ? state.allIds
          : [...state.allIds, action.payload.serviceRequestId],
      };
    case ADD_SERVICE_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_SERVICE_REQUEST_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        byId: {...state.byId, [action.payload.serviceRequestId]: {...action.payload}},
      };
    case UPDATE_SERVICE_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case DELETE_SERVICE_REQUEST_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_SERVICE_REQUEST_SUCCESS:
      const entries = Object.entries(state.byId).filter(
        entry => entry[0] !== action.payload.serviceRequestId
      );
      const newById = Object.fromEntries(entries);

      const newAllIds = state.allIds.filter(id => id !== action.payload.serviceRequestId);
      return {
        ...state,
        loading: false,
        error: null,
        byId: newById,
        allIds: newAllIds,
      };
    case DELETE_SERVICE_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case UPDATE_SELECTED_SERVICE_REQUEST:
      return {
        ...state,
        loading: false,
        error: null,
        selectedServiceRequest: action.payload,
      };
    default:
      return state;
  }
};
