// @flow

import type {Vehicle} from './vehicle.types';

export const FETCH_VEHICLES_STARTED: 'FETCH_VEHICLES_STARTED' = 'FETCH_VEHICLES_STARTED';
export const FETCH_VEHICLES_SUCCESS: 'FETCH_VEHICLES_SUCCESS' = 'FETCH_VEHICLES_SUCCESS';
export const FETCH_VEHICLES_FAILURE: 'FETCH_VEHICLES_FAILURE' = 'FETCH_VEHICLES_FAILURE';

export type FETCH_VEHICLES_STARTED_ACTION = {
  type: typeof FETCH_VEHICLES_STARTED,
};

export type FETCH_VEHICLES_SUCCESS_ACTION = {
  type: typeof FETCH_VEHICLES_SUCCESS,
  payload: {id: Vehicle},
  allIds: string[],
};

export type FETCH_VEHICLES_FAILURE_ACTION = {
  type: typeof FETCH_VEHICLES_FAILURE,
  payload: any,
};

export const FETCH_VEHICLE_BY_ID_STARTED = 'FETCH_VEHICLE_BY_ID_STARTED';
export const FETCH_VEHICLE_BY_ID_SUCCESS = 'FETCH_VEHICLE_BY_ID_SUCCESS';
export const FETCH_VEHICLE_BY_ID_FAILURE = 'FETCH_VEHICLE_BY_ID_FAILURE';

export type FETCH_VEHICLE_BY_ID_STARTED_ACTION = {
  type: typeof FETCH_VEHICLE_BY_ID_STARTED,
};

export type FETCH_VEHICLE_BY_ID_SUCCESS_ACTION = {
  type: typeof FETCH_VEHICLE_BY_ID_SUCCESS,
  payload: [{id: VEHICLE}],
  allIds: string[],
};

export type FETCH_VEHICLE_BY_ID_FAILURE_ACTION = {
  type: typeof FETCH_VEHICLE_BY_ID_FAILURE,
  payload: any,
};

export const ADD_VEHICLE_STARTED: 'ADD_VEHICLE_STARTED' = 'ADD_VEHICLE_STARTED';
export const ADD_VEHICLE_SUCCESS: 'ADD_VEHICLE_SUCCESS' = 'ADD_VEHICLE_SUCCESS';
export const ADD_VEHICLE_FAILURE: 'ADD_VEHICLE_FAILURE' = 'ADD_VEHICLE_FAILURE';

export type ADD_VEHICLE_STARTED_ACTION = {
  type: typeof ADD_VEHICLE_STARTED,
};

export type ADD_VEHICLE_SUCCESS_ACTION = {
  type: typeof ADD_VEHICLE_SUCCESS,
  payload: Vehicle,
};

export type ADD_VEHICLE_FAILURE_ACTION = {
  type: typeof ADD_VEHICLE_FAILURE,
  payload: any,
};

export const UPDATE_VEHICLE_STARTED: 'UPDATE_VEHICLE_STARTED' = 'UPDATE_VEHICLE_STARTED';
export const UPDATE_VEHICLE_SUCCESS: 'UPDATE_VEHICLE_SUCCESS' = 'UPDATE_VEHICLE_SUCCESS';
export const UPDATE_VEHICLE_FAILURE: 'UPDATE_VEHICLE_FAILURE' = 'UPDATE_VEHICLE_FAILURE';

export type UPDATE_VEHICLE_STARTED_ACTION = {
  type: typeof UPDATE_VEHICLE_STARTED,
};

export type UPDATE_VEHICLE_SUCCESS_ACTION = {
  type: typeof UPDATE_VEHICLE_SUCCESS,
  payload: Vehicle,
};

export type UPDATE_VEHICLE_FAILURE_ACTION = {
  type: typeof UPDATE_VEHICLE_FAILURE,
  payload: any,
};

export const UPDATE_SELECTED_VEHICLE: 'UPDATE_SELECTED_VEHICLE' =
  'UPDATE_SELECTED_VEHICLE';

export type UPDATE_SELECTED_VEHICLE_ACTION = {
  type: typeof UPDATE_SELECTED_VEHICLE,
  payload: Vehicle,
};

type Action =
  | FETCH_VEHICLES_STARTED_ACTION
  | FETCH_VEHICLES_SUCCESS_ACTION
  | FETCH_VEHICLES_FAILURE_ACTION
  | FETCH_VEHICLE_BY_ID_STARTED_ACTION
  | FETCH_VEHICLE_BY_ID_SUCCESS_ACTION
  | FETCH_VEHICLE_BY_ID_FAILURE_ACTION
  | ADD_VEHICLE_STARTED_ACTION
  | ADD_VEHICLE_SUCCESS_ACTION
  | ADD_VEHICLE_FAILURE_ACTION
  | UPDATE_VEHICLE_STARTED_ACTION
  | UPDATE_VEHICLE_SUCCESS_ACTION
  | UPDATE_VEHICLE_FAILURE_ACTION
  | UPDATE_SELECTED_VEHICLE_ACTION;

type State = {
  byId: {
    id?: Vehicle,
  },
  allIds: string[],
  loading: boolean,
  error: any,
  lastUpdated?: Date,
};

const initialState: State = {
  byId: {},
  allIds: [],
  loading: false,
  error: null,
};

export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case FETCH_VEHICLES_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_VEHICLES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        byId: action.payload,
        allIds: [],
        lastUpdated: new Date(),
      };
    case FETCH_VEHICLES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_VEHICLE_BY_ID_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_VEHICLE_BY_ID_SUCCESS:
      const vehicle = action.payload;
      return {
        ...state,
        loading: false,
        error: null,
        byId: {...state.byId, [vehicle.vehicleId]: {...vehicle}},
        lastUpdated: new Date(),
      };
    case ADD_VEHICLE_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_VEHICLE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        byId: {
          ...state.byId,
          [action.payload.vehicleId]: {
            ...action.payload,
            relatedViolations: [],
            comments: [],
            attachments: [],
          },
        },
        allIds: state.allIds.find(id => id === action.payload.vehicleId)
          ? state.allIds
          : [...state.allIds, action.payload.vehicleId],
      };
    case ADD_VEHICLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_VEHICLE_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_VEHICLE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        byId: {...state.byId, [action.payload.vehicleId]: {...action.payload}},
      };
    case UPDATE_SELECTED_VEHICLE:
      return {
        ...state,
        loading: false,
        error: null,
        selectedVehicle: action.payload,
      };
    default:
      return state;
  }
};
