import React from 'react';
import ComponentRoleSwitcher from '../../components/ComponentRoleSwitcher/ComponentRoleSwitcher';
import MobilityProviderDashboard from './MobilityProviderDashboard';
import ServiceProviderDashboard from './ServiceProviderDashboard';

const Dashboard = () => {
  return (
    <ComponentRoleSwitcher
      mobilityProvider={<MobilityProviderDashboard />}
      serviceProvider={<ServiceProviderDashboard />}
    />
  );
};

export default Dashboard;
