//@flow

import React from 'react';
import {Col, Row} from 'react-bootstrap';
import Card from '../Card';
import IconButton from '../IconButton';
import {connect} from 'react-redux';
import NotFound from '../NotFound';

/**
 * a card component showing the details of a serviceOffer
 */
const ServiceOfferDetailsCard = ({
  handleEditServiceOffer,
  handleDeleteServiceOffer,
  serviceOffer,
}) => {
  const content = [];

  if (!serviceOffer) {
    return <NotFound entityName={'serviceOffers'} />;
  }

  Object.keys(serviceOffer).forEach(key => {
    if (key === 'areas' || key === 'operationArea' || key === 'serviceProviderAddress') {
    } else {
      content.push(
        <Row key={key}>
          <Col xs={6}>{key}</Col>
          <Col xs={6}>
            <p className="blue-link">{serviceOffer[key]}</p>
          </Col>
        </Row>
      );
    }
  });

  const deleteButton = handleDeleteServiceOffer ? (
    <IconButton
      iconClass="pe-7s-close-circle"
      tooltipText="delete ServiceOffer"
      onClick={() => handleDeleteServiceOffer()}
      style={{fontSize: 19}}
    />
  ) : null;

  const editButton = handleEditServiceOffer ? (
    <IconButton
      iconClass="pe-7s-pen"
      tooltipText="edit ServiceOffer"
      onClick={() => handleEditServiceOffer()}
      style={{fontSize: 19}}
    />
  ) : null;

  return (
    <Card>
      <Card.Header>ServiceOffer Details</Card.Header>
      <Card.Content>{content}</Card.Content>
      <Card.Legend>
        {deleteButton}
        {editButton}
      </Card.Legend>
    </Card>
  );
};

const mapStateToProps = (state, ownProps) => ({
  serviceOffer: ownProps.serviceOffer,
  handleEditServiceOffer: ownProps.onEditServiceOffer,
  handleDeleteServiceOffer: ownProps.onDeleteServiceOffer,
});

export default connect(mapStateToProps)(ServiceOfferDetailsCard);
