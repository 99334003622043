// @flow

import React from 'react';
import Card from '../Card';
import ChartistGraph from 'react-chartist';

type Props = {
  labels: Array<string>,
  data: Array<number>,
  isDonut?: boolean,
  caption: string,
  isLoading?: boolean,
};

const PieCard = ({labels, data, isDonut = false, caption, isLoading = false}: Props) => {
  const TEXT_COLOR_CLASS_NAMES = [
    'text-info',
    'text-danger',
    'text-warning',
    'text-purple',
    'text-green',
  ];
  const getDonutOptions = () =>
    isDonut
      ? {
          donut: true,
          donutSolid: true,
          donutWidth: 35,
        }
      : {};

  const total = data.reduce((acc, value) => acc + value, 0);

  const pieData =
    total > 0
      ? data.reduce(function(filtered, value) {
          if (value > 0) {
            filtered.push({
              value: value,
              label: value,
            });
          } else {
            filtered.push({value: 0, label: ' '});
          }
          return filtered;
        }, [])
      : [
          {
            value: 1,
            label: 0,
            className: 'ct-empty-donut',
          },
        ];
  console.log(pieData);
  return (
    <Card>
      <Card.Header>{caption}</Card.Header>
      <Card.Content isLoading={isLoading}>
        <ChartistGraph
          data={{
            series: pieData,
            labels: pieData.map(slice => slice.label),
          }}
          options={{
            ...getDonutOptions(total),
            startAngle: 45,
            total: total,
          }}
          type="Pie"
          className="ct-chart"
        />
      </Card.Content>
      <Card.Legend>
        {labels.map((label, index) => {
          return (
            <span key={index}>
              <i
                className={`fa fa-circle ${TEXT_COLOR_CLASS_NAMES[index]}`}
                style={{marginRight: 3}}
              />
              {label}
            </span>
          );
        })}
      </Card.Legend>
    </Card>
  );
};

export default PieCard;
