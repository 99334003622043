import axios from 'axios';

export const applyForServiceRequest = async (
  requestId,
  description,
  price,
  providerId
) => {
  const resp = await axios.post('api/contracts/applyforservicerequest/' + requestId, {
    description: description,
    price: price,
    providerId: providerId,
  });
  return resp.data;
};
