import axios from 'axios';

export const createVehicle = async (newVehicleId, location, providerId) => {
  const resp = await axios.post('api/contracts/createvehicle', {
    newVehicleId: newVehicleId,
    location: location,
    tokenURI: 'cb2.com',
    providerId: providerId,
  });
  return resp.data;
};
