import React from 'react';
import styled from 'styled-components';

const HoverButton = styled.button`
  background-color: ${props => props.backgroundColor + '!important'};
  border: none;
  color: white !important;

  &:hover {
    background-color: ${props => props.hoverBackgroundColor + '!important'};
  }
`;
/**
 * Button with text and hover function
 */
export const TextButton = ({
  onClick = () => {},
  caption,
  type,
  disabled,
  size = 'md',
}) => {
  return (
    <HoverButton
      onClick={onClick}
      type={type}
      className={`btn btn-${size} btn-rectangle`}
      disabled={disabled ? 'disabled' : ''}
    >
      {caption}
    </HoverButton>
  );
};
