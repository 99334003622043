import axios from 'axios';

export const getVehicles = async providerId => {
  const response = await axios.get('api/contracts/vehicles', {
    params: {
      providerId: providerId,
    },
  });
  return response.data;
};
