import React from 'react';
import {connect} from 'react-redux';
import '../Dashboard.scss';
import {useHistory, withRouter} from 'react-router-dom';
import {Col, Row, Grid} from 'react-bootstrap';
import TableCard from '../../../components/TableCard';
import {updateSelectedVehicle} from '../../../stores/vehicle/vehicle.actions';
import PieCard from '../../../components/PieCard/PieCard';
import useFetchVehicles from '../../../hooks/useFetchVehicles';
import NotFound from '../../../components/NotFound';
import Card from '../../../components/Card';
import MapCard from '../../../components/MapCard';
import {IconSelector} from '../../../components/IconSelector/IconSelector';
import VehicleForm from './VehicleForm';
import useFetchServiceRequests from '../../../hooks/useFetchServiceRequests';

const MobilityProviderDashboard = ({selectedVehicle, updateSelectedVehicle}) => {
  const history = useHistory();
  const [vehiclesById, v_error, v_isLoading] = useFetchVehicles();
  const vehicles = vehiclesById ? Object.values(vehiclesById) : [];
  const [serviceRequestIds, r_error, r_isLoading] = useFetchServiceRequests();
  const serviceRequests = serviceRequestIds ? Object.values(serviceRequestIds) : [];

  if (v_error || r_error) {
    return <NotFound entityName={'serviceOffers'} />;
  }

  const vehiclesInUse = vehicles.filter(vehicle => vehicle.status === 'inUse');
  const vehiclesPending = vehicles.filter(vehicle => vehicle.status === 'pending');

  const vehiclesInMaintenance = vehicles.filter(
    vehicle => vehicle.status === 'inMaintenance'
  );

  const vehicleMarker = vehicles.map(vehicle => {
    var icon = IconSelector({vehicleType: vehicle.vehicleType, status: vehicle.status});
    return {...vehicle, icon};
  });

  //TODO find jobs where serviceRequest ID === job.serviceRequestId api call
  const serviceRequestsWithApplicants = serviceRequests.map(serviceRequest => {
    const numOfApllicants = serviceRequest.jobIds.length;
    return {...serviceRequest, applicants: numOfApllicants};
  });

  return (
    <div className="main-content">
      <Grid
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Row>
          <Col sm={12} className="col-padding">
            <TableCard
              title={'Service Requests'}
              isLoading={r_isLoading}
              data={serviceRequestsWithApplicants}
              labels={[
                {
                  field: 'serviceRequestId',
                  caption: 'Id',
                  dataSort: true,
                  width: '50%',
                  isKey: true,
                },
                {
                  field: 'vehicleId',
                  caption: 'vehicleId',
                  dataSort: true,
                  width: '50%',
                },
                {
                  field: 'serviceType',
                  caption: 'type',
                  dataSort: true,
                  width: '50%',
                },
                {
                  field: 'price',
                  caption: 'price',
                  dataSort: true,
                  width: '20%',
                },
                {
                  field: 'applicants',
                  caption: 'applicants',
                  dataSort: true,
                  width: '20%',
                },
              ]}
              keyClickUrl={row => `/serviceRequests/${row.serviceRequestId}`}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={9} className="col-padding" style={{marginTop: 15}}>
            <TableCard
              title={'Vehicle List'}
              isLoading={v_isLoading}
              data={vehicles}
              labels={[
                {
                  field: 'vehicleId',
                  caption: 'ID',
                  dataSort: true,
                  width: '300px',
                  isKey: true,
                },
                {
                  field: 'displayName',
                  caption: 'name',
                  dataSort: true,
                  width: '50%',
                },
                {
                  field: 'provider',
                  caption: 'provider',
                  dataSort: true,
                  width: '50%',
                },
                {
                  field: 'status',
                  caption: 'Status',
                  dataSort: true,
                  width: '50%',
                },
              ]}
              onRowClick={row => updateSelectedVehicle(row)}
              selected={selectedVehicle ? [selectedVehicle.vehicleId] : null}
            />
          </Col>
          <Col sm={3} className="col-padding" style={{marginTop: 15}}>
            <PieCard
              isDonut={true}
              labels={['pending', 'in Use', 'in Maintenance']}
              data={[
                vehiclesPending.length,
                vehiclesInUse.length,
                vehiclesInMaintenance.length,
              ]}
              caption={'Vehicles by status'}
              isLoading={v_isLoading}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="col-padding" style={{marginTop: 15}}>
            <MapCard
              title="Vehicle Map"
              data={{markers: vehicleMarker}}
              selectedMarker={selectedVehicle}
              mapHeight="60vh"
              onSelectedMarkerChange={vehicle => updateSelectedVehicle(vehicle)}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="col-padding" style={{marginTop: 15}}>
            <Card>
              <Card.Header>Create vehicle test card</Card.Header>
              <Card.Content isLoading={v_isLoading}>
                <VehicleForm />
              </Card.Content>
            </Card>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => ({
  softBackgroundColor: state.ThemeOptions.softBackgroundColor,
  backgroundColor: state.ThemeOptions.backgroundColor,
  selectedVehicle: state.Vehicles.selectedVehicle,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateSelectedVehicle: vehicle => dispatch(updateSelectedVehicle(vehicle)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MobilityProviderDashboard)
);
