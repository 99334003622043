import React from 'react';
import {useParams} from 'react-router-dom';
import {Col} from 'react-bootstrap';
import {connect} from 'react-redux';
import StationDetailsCard from '../../components/StationDetailsCard';
import MapCard from '../../components/MapCard/MapCard';
import useFetchStationById from '../../hooks/useFetchStationById';
import Card from '../../components/Card';
import {IconSelector} from '../../components/IconSelector/IconSelector';
import TableCard from '../../components/TableCard';

const StationDetails = ({}) => {
  const {id} = useParams();
  const [station, error, isLoading] = useFetchStationById(id);

  if ((!station && !error) || isLoading) {
    return (
      <div className="main-content">
        <Card>
          <Card.Header>Loading...</Card.Header>
          <Card.Content isLoading={true} />
        </Card>
      </div>
    );
  }

  const icon = IconSelector({vehicleType: 'Station-Icon'});
  const currentPosition = {lat: station.location[0], lng: station.location[1]};
  const stationMarker = {currentPosition, icon: icon};

  return (
    <div>
      <div className="main-content">
        <Col lg={4} className="col-padding">
          <Col lg={12} className="col-padding">
            <StationDetailsCard station={station} />
            <TableCard
              title={'Vehicles in Station'}
              data={station.vehiclesInStation}
              labels={[
                {
                  field: 'vehicleId',
                  caption: 'Id',
                  dataSort: true,
                  width: '50%',
                  isKey: true,
                },
                {
                  field: 'providerId',
                  caption: 'providerId',
                  dataSort: true,
                  width: '50%',
                },
                {
                  field: 'stationId',
                  caption: 'stationId',
                  dataSort: true,
                  width: '50%',
                },
                {
                  field: 'availableStatus',
                  caption: 'status',
                  dataSort: true,
                  width: '50%',
                },
              ]}
              keyClickUrl={row => {}}
              onRowClick={row => {}}
              //selected={selectedStation ? [selectedStation.stationId] : null}
            />
          </Col>
        </Col>
        <Col lg={8} className="col-padding">
          <Col lg={12} className="col-padding">
            <MapCard
              title="Station Map"
              data={{markers: [stationMarker]}}
              selectedMarker={stationMarker}
              mapHeight="60vh"
            />
          </Col>
        </Col>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({});

export default connect(null, mapDispatchToProps)(StationDetails);
