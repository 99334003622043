import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import {
  fetchServiceOffersFailure,
  fetchServiceOffersStarted,
  fetchServiceOffersSuccess,
} from '../stores/serviceOffer/serviceOffer.actions';
import {schema, normalize} from 'normalizr';

import {getServiceOffers} from '../contracts/ServiceOfferRegistry/contractMethods/getServiceOffers';
import {getServiceOfferById} from '../contracts/ServiceOfferRegistry/contractMethods/getServiceOfferById';
import {usePrevious} from './usePrevious';

const serviceOfferSchema = new schema.Entity(
  'serviceOffers',
  {},
  {idAttribute: 'serviceOfferId'}
);

const serviceOfferListSchema = new schema.Array(serviceOfferSchema);

export default function useFetchServiceOffers() {
  const dispatch = useDispatch();
  const {byId, error, loading, lastUpdated} = useSelector(state => state.ServiceOffers);
  const chainId = useSelector(state => state.User.chainId);
  const prevChainId = usePrevious(chainId);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const TTL = 1000 * 60;

    const chainIdChanged = prevChainId !== chainId;

    const wasFetchedBefore = lastUpdated;
    const isExpired =
      wasFetchedBefore && new Date().getTime() - lastUpdated.getTime() > TTL;
    if ((wasFetchedBefore && !isExpired && !chainIdChanged) || loading) return;

    const fetchServiceOffers = async () => {
      dispatch(fetchServiceOffersStarted());
      try {
        //getServiceOffer also returns the ids of deleted offers

        const bcServiceOffers = await getServiceOffers();
        const existingServiceOffer = bcServiceOffers.filter(
          bcServiceOffers => bcServiceOffers.deleted === false
        );
        const detailServiceOffers = await Promise.all(
          existingServiceOffer.map(async serviceOffer => {
            let details = await getServiceOfferById(serviceOffer.serviceOfferId);
            return details;
          })
        );

        const normalizedData = normalize(detailServiceOffers, serviceOfferListSchema);
        dispatch(
          fetchServiceOffersSuccess(
            normalizedData.entities['serviceOffers'] ?? {},
            normalizedData.result
          )
        );
      } catch (error) {
        dispatch(fetchServiceOffersFailure(error));
      }
    };

    fetchServiceOffers();

    return () => source.cancel();
  }, [dispatch, lastUpdated, chainId, prevChainId, loading]);

  return [byId, error, loading];
}
