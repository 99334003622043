import {
  ADD_SERVICE_OFFER_FAILURE,
  ADD_SERVICE_OFFER_STARTED,
  ADD_SERVICE_OFFER_SUCCESS,
  FETCH_SERVICE_OFFERS_FAILURE,
  FETCH_SERVICE_OFFERS_STARTED,
  FETCH_SERVICE_OFFERS_SUCCESS,
  FETCH_SERVICE_OFFER_BY_ID_FAILURE,
  FETCH_SERVICE_OFFER_BY_ID_STARTED,
  FETCH_SERVICE_OFFER_BY_ID_SUCCESS,
  UPDATE_SERVICE_OFFER_FAILURE,
  UPDATE_SERVICE_OFFER_STARTED,
  UPDATE_SERVICE_OFFER_SUCCESS,
  DELETE_SERVICE_OFFER_STARTED,
  DELETE_SERVICE_OFFER_FAILURE,
  DELETE_SERVICE_OFFER_SUCCESS,
} from './serviceOffer.reducer';
import {b_deleteServiceOffer} from '../../contracts/ServiceOfferRegistry/contractMethods/deleteServiceOffer';
import {createServiceOffer} from '../../contracts/ServiceOfferRegistry/contractMethods/createServiceOffer';
import {b_updateServiceOffer} from '../../contracts/ServiceOfferRegistry/contractMethods/updateServiceOffer';

//fetch ServiceOffers
export const fetchServiceOffersStarted = () => ({
  type: FETCH_SERVICE_OFFERS_STARTED,
});
export const fetchServiceOffersSuccess = (serviceOffers, allIds) => ({
  type: FETCH_SERVICE_OFFERS_SUCCESS,
  payload: serviceOffers,
  allIds: allIds,
});
export const fetchServiceOffersFailure = error => ({
  type: FETCH_SERVICE_OFFERS_FAILURE,
  payload: error,
});
//fetchServiceOffersById
export const fetchServiceOfferByIdStarted = () => ({
  type: FETCH_SERVICE_OFFER_BY_ID_STARTED,
});
export const fetchServiceOfferByIdSuccess = serviceOffer => ({
  type: FETCH_SERVICE_OFFER_BY_ID_SUCCESS,
  payload: serviceOffer,
});
export const fetchServiceOfferByIdFailure = error => ({
  type: FETCH_SERVICE_OFFER_BY_ID_FAILURE,
  payload: error,
});

export const addServiceOfferStarted = () => ({type: ADD_SERVICE_OFFER_STARTED});
export const addServiceOfferSuccess = serviceOffer => ({
  type: ADD_SERVICE_OFFER_SUCCESS,
  payload: serviceOffer,
});
export const addServiceOfferFailure = error => ({
  type: ADD_SERVICE_OFFER_FAILURE,
  payload: error,
});

export const addServiceOffer = serviceOffer => {
  return async dispatch => {
    try {
      dispatch(addServiceOfferStarted());

      const response = await createServiceOffer(
        serviceOffer.title,
        serviceOffer.description,
        serviceOffer.serviceType,
        serviceOffer.operationArea
      );

      return dispatch(
        addServiceOfferSuccess({
          serviceOfferId: response.events.ServiceOfferCreated.returnValues.serviceOfferId,
          title: serviceOffer.title,
          serviceOfferType: serviceOffer.serviceType,
          operationArea: serviceOffer.operationArea,
          description: serviceOffer.description,
        })
      );
    } catch (error) {
      return dispatch(addServiceOfferFailure(error));
    }
  };
};

export const updateServiceOfferStarted = () => ({type: UPDATE_SERVICE_OFFER_STARTED});
export const updateServiceOfferSuccess = serviceOffer => ({
  type: UPDATE_SERVICE_OFFER_SUCCESS,
  payload: serviceOffer,
});
export const updateServiceOfferFailure = error => ({
  type: UPDATE_SERVICE_OFFER_FAILURE,
  payload: error,
});

export const updateServiceOffer = serviceOffer => {
  return async (dispatch, getState) => {
    dispatch(updateServiceOfferStarted());
    try {
      await b_updateServiceOffer(
        serviceOffer.serviceOfferId,
        serviceOffer.title,
        serviceOffer.description,
        serviceOffer.serviceType,
        serviceOffer.operationArea
      );

      return dispatch(
        updateServiceOfferSuccess({
          serviceOfferId: serviceOffer.serviceOfferId,
          title: serviceOffer.title,
          description: serviceOffer.description,
          serviceType: serviceOffer.serviceType,
          serviceOffer: serviceOffer.operationArea,
        })
      );
    } catch (error) {
      return dispatch(updateServiceOfferFailure(error));
    }
  };
};

export const deleteServiceOfferStarted = () => ({type: DELETE_SERVICE_OFFER_STARTED});
export const deleteServiceOfferSuccess = serviceOffer => ({
  type: DELETE_SERVICE_OFFER_SUCCESS,
  payload: serviceOffer,
});

export const deleteServiceOfferFailure = error => ({
  type: DELETE_SERVICE_OFFER_FAILURE,
  payload: error,
});

export const deleteServiceOffer = serviceOffer => {
  return async (dispatch, getState) => {
    dispatch(deleteServiceOfferStarted());
    try {
      await b_deleteServiceOffer(serviceOffer.serviceOfferId);

      return dispatch(deleteServiceOfferSuccess(serviceOffer));
    } catch (error) {
      return dispatch(deleteServiceOfferFailure(error));
    }
  };
};
