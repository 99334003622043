// @flow
export const FETCH_EVENTS_STARTED: 'FETCH_EVENTS_STARTED' = 'FETCH_EVENTS_STARTED';
export const FETCH_EVENTS_SUCCESS: 'FETCH_EVENTS_SUCCESS' = 'FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAILURE: 'FETCH_EVENTS_FAILURE' = 'FETCH_EVENTS_FAILURE';

export type FETCH_EVENTS_STARTED_ACTION = {
  type: typeof FETCH_EVENTS_STARTED,
};

export type FETCH_EVENTS_SUCCESS_ACTION = {
  type: typeof FETCH_EVENTS_SUCCESS,
  payload: {id: any},
  allIds: string[],
};

export type FETCH_EVENTS_FAILURE_ACTION = {
  type: typeof FETCH_EVENTS_FAILURE,
  payload: any,
};

type Action =
  | FETCH_EVENTS_STARTED_ACTION
  | FETCH_EVENTS_SUCCESS_ACTION
  | FETCH_EVENTS_FAILURE_ACTION;

type State = {
  byId: {
    id?: any,
  },
  allIds: string[],
  loading: boolean,
  error: any,
  lastUpdated?: Date,
};

const initialState: State = {
  byId: {},
  allIds: [],
  loading: false,
  error: null,
};

export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case FETCH_EVENTS_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        events: action.payload,
        lastUpdated: new Date(),
      };
    case FETCH_EVENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
