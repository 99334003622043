//@flow

import React from 'react';
import {Col, Row} from 'react-bootstrap';
import Card from '../Card';
import IconButton from '../IconButton';
import {connect} from 'react-redux';

/**
 * A card component showing the details of a station.
 * By clicking the details button, the user will be redirected to the details page.
 * if no service request is passed via props, the card advises the user to select a station.
 */
const StationDetailsCard = ({
  handleEditStation,
  handleDeleteStation,
  handleMoreDetails,
  handleApplyForStation,
  jobs = [],
  ...props
}) => {
  const content = [];

  let station = props.station
    ? props.station
    : {
        stationId: '',
        stationName: '',
        nrAvailable: '',
        nrMax: '',
        nrDesired: '',
      };

  if (!props.station)
    content.push(
      <div
        key={'placeholder'}
        style={{
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          right: '20%',
          top: 0,
          bottom: 0,
          margin: 'auto',
        }}
      >
        please select a station
      </div>
    );

  Object.keys(station).forEach((key, index) => {
    if (key !== 'location' && key !== 'vehiclesInStation') {
      content.push(
        <Row key={index}>
          <Col xs={6} className="col-padding-zero">
            {key}
          </Col>
          <Col xs={6} style={{alignItems: 'center'}}>
            {station[key]}
          </Col>
        </Row>
      );
    }
  });

  const detailsButton =
    handleMoreDetails && props.station ? (
      <IconButton
        iconClass="pe-7s-more"
        tooltipText="more Details"
        onClick={() => handleMoreDetails()}
        style={{fontSize: 19}}
      />
    ) : null;

  return (
    <Card>
      <Card.Header>Station Details</Card.Header>
      <Card.Content>{content}</Card.Content>
      <Card.Legend>
        <div style={{display: 'flex'}}>{detailsButton}</div>
      </Card.Legend>
    </Card>
  );
};

const mapStateToProps = (state, ownProps) => ({
  station: ownProps.station,
  handleMoreDetails: ownProps.onMoreDetails,
});

export default connect(mapStateToProps)(StationDetailsCard);
