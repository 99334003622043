import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import {
  fetchServiceOfferByIdFailure,
  fetchServiceOfferByIdStarted,
  fetchServiceOfferByIdSuccess,
} from '../stores/serviceOffer/serviceOffer.actions';

import {getServiceOfferById} from '../contracts/ServiceOfferRegistry/contractMethods/getServiceOfferById';

export default function useFetchServiceOfferById(id) {
  const dispatch = useDispatch();
  const {byId, error, loading, lastUpdated} = useSelector(state => state.ServiceOffers);
  const data = byId[id];

  useEffect(() => {
    const source = axios.CancelToken.source();
    const TTL = 1000 * 60;

    const wasFetchedBefore = lastUpdated;
    const isExpired =
      wasFetchedBefore && new Date().getTime() - lastUpdated.getTime() > TTL;
    if ((wasFetchedBefore && !isExpired) || loading) return;

    const fetchServiceOfferById = async () => {
      dispatch(fetchServiceOfferByIdStarted());

      try {
        const serviceOffer = await getServiceOfferById(id);
        dispatch(fetchServiceOfferByIdSuccess(serviceOffer));
      } catch (error) {
        dispatch(fetchServiceOfferByIdFailure(error));
      }
    };

    fetchServiceOfferById();

    return () => source.cancel();
  }, [dispatch, id, lastUpdated, loading]);

  return [data, error, loading];
}
