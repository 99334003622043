// @flow

import React, {ComponentType} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {ACCENT_COLORS} from '../../stores/ui/theme.reducer';
import {TinyColor} from '@ctrl/tinycolor';
import styled from 'styled-components';

type TableButtonProps = {
  backgroundColor?: string,
  hoverBackgroundColor?: string,
};

export const TableButton: ComponentType<TableButtonProps> = styled.button`
  background-color: ${props => props.backgroundColor};
  border: none;
  border-radius: 3px;
  margin: 0 0;
  margin-right: 3px;
  font-size: ${props => props.fontSize || '28px'};
  color: white;
  width: ${props => props.size || '30px'};
  height: ${props => props.size || '30px'};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${props => props.hoverBackgroundColor};
  }
`;

/**
 * Button with icon
 */

export const IconButton = ({
  tooltipText,
  onClick,
  iconClass,
  size,
  className,
  ...props
}) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={'file-incident'}>{tooltipText}</Tooltip>}
    >
      <TableButton
        size={size}
        backgroundColor={ACCENT_COLORS.PURPLE}
        hoverBackgroundColor={new TinyColor(ACCENT_COLORS.PURPLE).darken().toRgbString()}
        onClick={onClick}
        {...props}
      >
        <i className={iconClass} />
      </TableButton>
    </OverlayTrigger>
  );
};
