import React from 'react';
import {Col} from 'react-bootstrap';

/**
 * renders 404 status code message
 */
const NotFound = ({entityName}) => {
  return (
    <div
      className="main-content"
      style={{textAlign: 'center', color: '#777777', margin: 'auto'}}
    >
      <Col sm={12} className="col-padding">
        <h1 style={{marginTop: '0'}}>404</h1>
        <p>{`The specified ${entityName} doesn't exist yet.`}</p>
      </Col>
    </div>
  );
};

NotFound.defaultProps = {
  entityName: 'entity',
};

export default NotFound;
