//@flow

import {USER_UPDATE_ROLE_ACTION} from './user.reducer';
import {
  USER_UPDATE_ROLE,
  FETCH_PROVIDER_ROLES_STARTED,
  FETCH_PROVIDER_ROLES_SUCCESS,
  FETCH_PROVIDER_ROLES_FAILURE,
  UPDATE_SELECTED_PROVIDER,
} from './user.reducer';
import {getRolesByAddress} from '../../contracts/ProviderRegistry/contractMethods/getRolesByAddress';
import {getProviderById} from '../../contracts/ProviderRegistry/contractMethods/getProviderById';
import {schema, normalize} from 'normalizr';

export const onLogout = (): USER_LOGOUT_ACTION => {
  return {
    type: 'USER_LOGOUT',
  };
};

export const onLogin = (
  id: number,
  email: String,
  familyName: String,
  givenName: String,
  imageUrl: String
) => {
  return {
    type: 'USER_LOGIN_SUCCESS',
    id,
    email,
    familyName,
    givenName,
    imageUrl,
  };
};

export const onNewToken = (idToken: String) => {
  return {
    type: 'ON_NEW_TOKEN',
    idToken,
  };
};

export const setUserRole = (role: UserRole): USER_UPDATE_ROLE_ACTION => ({
  type: USER_UPDATE_ROLE,
  role: role,
});

export const setUserRoleAndUpdateAccentColor = (role: UserRole) => {
  return dispatch => {
    dispatch(setUserRole(role));
  };
};

//fetch liked providers
export const fetchProviderRolesStarted = () => ({
  type: FETCH_PROVIDER_ROLES_STARTED,
});
export const fetchProviderRolesSuccess = (providerRoles, allIds) => ({
  type: FETCH_PROVIDER_ROLES_SUCCESS,
  payload: providerRoles,
  allIds: allIds,
});
export const fetchProviderRolesFailure = error => ({
  type: FETCH_PROVIDER_ROLES_FAILURE,
  payload: error,
});

export const updateSelectedProviderRole = providerRole => ({
  type: UPDATE_SELECTED_PROVIDER,
  payload: providerRole,
});

export const fetchRolesByAddress = () => {
  const providerSchema = new schema.Entity('providers', {}, {idAttribute: 'providerId'});
  const providerListSchema = new schema.Array(providerSchema);
  return async (dispatch, getState) => {
    dispatch(fetchProviderRolesStarted());
    try {
      const roles = await getRolesByAddress();

      const detailedRoles = await Promise.all(
        roles.map(async provider => {
          let actualProviderName = await getProviderById(provider.providerId);
          provider.providerName = actualProviderName;
          return provider;
        })
      );
      const normalizedData = normalize(detailedRoles, providerListSchema);
      return dispatch(
        fetchProviderRolesSuccess(
          normalizedData.entities['providers'] ?? {},
          normalizedData.result
        )
      );
    } catch (error) {
      return dispatch(fetchProviderRolesFailure(error));
    }
  };
};
