import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';

import './assets/styles/base.scss';

import Main from './pages/Layout';

const App = () => {
  return (
    <Router>
      <Main />
    </Router>
  );
};
export default App;
