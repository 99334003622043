//@flow

import React, {useState} from 'react';
import './ServiceOfferDetails.scss';
import {useParams, useHistory} from 'react-router-dom';
import ServiceOfferDetailsCard from '../../components/ServiceOfferDetailsCard';
import {Col, Grid} from 'react-bootstrap';
import Card from '../../components/Card';
import useFetchServiceOfferById from '../../hooks/useFetchServiceOfferById';
import {
  deleteServiceOffer,
  updateServiceOffer,
} from '../../stores/serviceOffer/serviceOffer.actions';
import NotFound from '../../components/NotFound/NotFound';
import MapCard from '../../components/MapCard/MapCard';
import {connect} from 'react-redux';
import ServiceOfferEditCard from './ServiceOfferEditCard';
import {notificationsOptions} from '../../helpers';
import {showNotification} from '../../stores/ui/layout.reducer';
import useGeoArea from '../../hooks/useGeoArea';

const ServiceOfferDetails = ({
  user,
  deleteServiceOffer,
  editServiceOffer,
  showNotification,
}) => {
  const history = useHistory();
  const {id} = useParams();
  const [serviceOffer, error, isLoading] = useFetchServiceOfferById(id);
  const [editMode, changeEditMode] = useState(false);
  const [geoArea, setGeoArea, operationAreaLoading] = useGeoArea(
    serviceOffer?.operationArea
  );

  if ((!serviceOffer && !error) || isLoading) {
    return (
      <div className="main-content">
        <Card>
          <Card.Header>Loading...</Card.Header>
          <Card.Content isLoading={isLoading} />
        </Card>
      </div>
    );
  }

  if (error) {
    return <NotFound entityName={'serviceOffer'} />;
  }

  const handleDeleteServiceOffer = async () => {
    const result = await deleteServiceOffer({
      serviceOfferId: serviceOffer.serviceOfferId,
    });

    let notificationsOps;
    if (result.type === 'DELETE_SERVICE_OFFER_SUCCESS') {
      notificationsOps = notificationsOptions(
        'success',
        'Delete service offer',
        'Service offer deleted successfully'
      );
      history.push(`/serviceOffers`);
    } else if (result.type === 'DELETE_SERVICE_OFFER_FAILURE') {
      notificationsOps = notificationsOptions(
        'error',
        'Delete service offer',
        'Failed to delete service offer'
      );
    }
    showNotification(notificationsOps);
  };

  const handleEditServiceOffer = () => {
    changeEditMode(true);
  };

  const handleSubmit = async values => {
    const _serviceOffer = {
      serviceOfferId: serviceOffer.serviceOfferId,
      title: values.title,
      serviceType: values.serviceType,
      operationArea: values.operationArea,
      description: values.description,
    };
    const result = await editServiceOffer(_serviceOffer);
    changeEditMode(false);

    let notificationsOps;
    if (result.type === 'UPDATE_SERVICE_OFFER_SUCCESS') {
      notificationsOps = notificationsOptions(
        'success',
        'Update service offer',
        'Service offer updated successfully'
      );
      history.push(`/serviceOffers`);
    } else if (result.type === 'UPDATE_SERVICE_OFFER_FAILURE') {
      notificationsOps = notificationsOptions(
        'error',
        'Update service offer',
        'Failed to update service offer'
      );
    }
    showNotification(notificationsOps);
  };

  const ServiceOfferCard = editMode ? (
    <ServiceOfferEditCard
      serviceOffer={serviceOffer}
      onSubmit={values => handleSubmit(values)}
    />
  ) : (
    <ServiceOfferDetailsCard
      serviceOffer={serviceOffer}
      user={user}
      onDeleteServiceOffer={() => handleDeleteServiceOffer()}
      onEditServiceOffer={() => handleEditServiceOffer()}
    />
  );

  return (
    <div className="main-content">
      <Grid
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Col xs={6} lg={4} className="col-padding" style={{marginTop: 15}}>
          <Col className="col-padding-zero" xs={12}>
            {ServiceOfferCard}
          </Col>
        </Col>
        <Col xs={12} lg={8} className="col-padding" style={{marginTop: 15}}>
          <MapCard
            data={serviceOffer}
            title="Operation Area"
            mapHeight="60vh"
            loading={operationAreaLoading}
            loadingText={'loading operation area...'}
            geoArea={geoArea}
          />
        </Col>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  softBackgroundColor: state.ThemeOptions.softBackgroundColor,
  backgroundColor: state.ThemeOptions.backgroundColor,
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  deleteServiceOffer: serviceOfferId => dispatch(deleteServiceOffer(serviceOfferId)),
  editServiceOffer: serviceOffer => dispatch(updateServiceOffer(serviceOffer)),
  showNotification: notificationsOps => dispatch(showNotification(notificationsOps)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceOfferDetails);
