import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import {persistReducer, persistStore} from 'redux-persist';
import localForage from 'localforage';

import {rootReducer} from './root.reducer';
import axiosHandler from './user/axios.middleware';

export default (initialState = {}) => {
  // ======================================================
  // Middleware Configuration
  // ======================================================
  // const middleware = [thunk, loggerMiddleware];

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = [];
  const __DEV__ = process.env.NODE_ENV !== 'production';
  if (__DEV__) {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  let config = {
    key: 'root',
    storage: localForage,
    whitelist: ['User', 'Settings', 'ThemeOptions'],
    debug: __DEV__,
  };

  let configureReducer = persistReducer(config, rootReducer);

  let store = createStore(
    configureReducer,
    initialState,
    compose(applyMiddleware(thunk, axiosHandler), ...enhancers)
  );

  let persistor = persistStore(store);

  return {store, persistor};
};
