// @flow

export const FETCH_STATIONS_STARTED: 'FETCH_STATIONS_STARTED' = 'FETCH_STATIONS_STARTED';
export const FETCH_STATIONS_SUCCESS: 'FETCH_STATIONS_SUCCESS' = 'FETCH_STATIONS_SUCCESS';
export const FETCH_STATIONS_FAILURE: 'FETCH_STATIONS_FAILURE' = 'FETCH_STATIONS_FAILURE';

export type FETCH_STATIONS_STARTED_ACTION = {
  type: typeof FETCH_STATIONS_STARTED,
};

export type FETCH_STATIONS_SUCCESS_ACTION = {
  type: typeof FETCH_STATIONS_SUCCESS,
  payload: {id: Station},
  allIds: string[],
};

export type FETCH_STATIONS_FAILURE_ACTION = {
  type: typeof FETCH_STATIONS_FAILURE,
  payload: any,
};

export const FETCH_STATION_BY_ID_STARTED = 'FETCH_STATION_BY_ID_STARTED';
export const FETCH_STATION_BY_ID_SUCCESS = 'FETCH_STATION_BY_ID_SUCCESS';
export const FETCH_STATION_BY_ID_FAILURE = 'FETCH_STATION_BY_ID_FAILURE';

export type FETCH_STATION_BY_ID_STARTED_ACTION = {
  type: typeof FETCH_STATION_BY_ID_STARTED,
};

export type FETCH_STATION_BY_ID_SUCCESS_ACTION = {
  type: typeof FETCH_STATION_BY_ID_SUCCESS,
  payload: {id: Station},
  allIds: string[],
};

export type FETCH_STATION_BY_ID_FAILURE_ACTION = {
  type: typeof FETCH_STATION_BY_ID_FAILURE,
  payload: any,
};

export const UPDATE_SELECTED_STATION: 'UPDATE_SELECTED_STATION' =
  'UPDATE_SELECTED_STATION';

export type UPDATE_SELECTED_STATION_ACTION = {
  type: typeof UPDATE_SELECTED_STATION,
  payload: Station,
};

type Action =
  | FETCH_STATIONS_STARTED_ACTION
  | FETCH_STATIONS_SUCCESS_ACTION
  | FETCH_STATIONS_FAILURE_ACTION
  | FETCH_STATION_BY_ID_STARTED_ACTION
  | FETCH_STATION_BY_ID_SUCCESS_ACTION
  | FETCH_STATION_BY_ID_FAILURE_ACTION
  | UPDATE_SELECTED_STATION_ACTION;

type State = {
  byId: {
    id?: any,
  },
  allIds: string[],
  loading: boolean,
  error: any,
  lastUpdated?: Date,
};

const initialState: State = {
  byId: {},
  allIds: [],
  loading: false,
  error: null,
};

export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case FETCH_STATIONS_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_STATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        byId: action.payload,
        allIds: action.allIds,
        lastUpdated: new Date(),
      };
    case FETCH_STATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_STATION_BY_ID_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_STATION_BY_ID_SUCCESS:
      const station = action.payload;
      return {
        ...state,
        loading: false,
        error: null,
        lastUpdated: new Date(),
        byId: {...state.byId, [station.stationId]: {...station}},
      };
    case UPDATE_SELECTED_STATION:
      return {
        ...state,
        loading: false,
        error: null,
        selectedStation: action.payload,
      };
    default:
      return state;
  }
};
