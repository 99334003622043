import axios from 'axios';

export const updateServiceRequest = async (
  requestId,
  description,
  serviceType,
  price
) => {
  const resp = await axios.post('api/contracts/updateservicerequest/' + requestId, {
    description: description,
    serviceType: serviceType,
    price: price,
  });
  return resp.data;
};
