import {
  error as showError,
  success as showSuccess,
  info as showInfo,
  warning as showWarning,
} from 'react-notification-system-redux';

const SET_MOBILE_NAV_VISIBILITY = 'LAYOUT/SET_MOBILE_NAV_VISIBILITY';
const SET_OPAQUE_BACKGROUND = 'SET_OPAQUE_BACKGROUND';

export const setMobileNavVisibility = visibility => ({
  type: SET_MOBILE_NAV_VISIBILITY,
  visibility,
});

export const toggleMobileNavVisibility = () => (dispatch, getState) => {
  let visibility = getState().Layout.mobileNavVisibility;
  dispatch(setMobileNavVisibility(!visibility));
};

export const showNotification = notification => (dispatch, getState) => {
  const notificationType = notification.type;
  delete notification.type;

  switch (notificationType) {
    case 'success':
      dispatch(showSuccess(notification));
      break;
    case 'error':
      dispatch(showError(notification));
      break;
    case 'warning':
      dispatch(showWarning(notification));
      break;
    case 'info':
      dispatch(showInfo(notification));
      break;
    default:
      dispatch(showError(notification));
  }
};

export default function reducer(
  state = {
    mobileNavVisibility: false,
  },
  action
) {
  switch (action.type) {
    case SET_MOBILE_NAV_VISIBILITY:
      return {
        ...state,
        mobileNavVisibility: action.visibility,
      };
    case SET_OPAQUE_BACKGROUND:
      return {
        ...state,
        opaqueBackground: action.opaqueBackground,
      };
    default:
      return state;
  }
}
