import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
  <footer className="footer">
    <div className="container-fluid">
      <p className="copyright pull-right">
        © 2021 <Link to="/">CB</Link>, City Beamer
      </p>
    </div>
  </footer>
);

export default Footer;
