import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import {
  fetchProvidersFailure,
  fetchProvidersStarted,
  fetchProvidersSuccess,
} from '../stores/provider/provider.actions';
import {schema, normalize} from 'normalizr';
import {getProviders} from '../contracts/ProviderRegistry/contractMethods/getProviders';
import {getProviderById} from '../contracts/ProviderRegistry/contractMethods/getProviderById';

const providerSchema = new schema.Entity('providers', {}, {idAttribute: 'providerId'});

const providerListSchema = new schema.Array(providerSchema);

export default function useFetchProviders() {
  const dispatch = useDispatch();
  const {byId, error, loading, lastUpdated} = useSelector(state => state.Providers);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const TTL = 1000 * 60;

    const wasFetchedBefore = lastUpdated;
    const isExpired =
      wasFetchedBefore && new Date().getTime() - lastUpdated.getTime() > TTL;
    if ((wasFetchedBefore && !isExpired) || loading) return;

    const fetchProviders = async () => {
      dispatch(fetchProvidersStarted());
      try {
        const bcProviders = await getProviders();

        const detailProviders = await Promise.all(
          bcProviders.map(async provider => {
            let providerName = await getProviderById(provider.providerId);
            return {...provider, providerName: providerName};
          })
        );

        const normalizedData = normalize(detailProviders, providerListSchema);
        dispatch(
          fetchProvidersSuccess(
            normalizedData.entities['providers'] ?? {},
            normalizedData.result
          )
        );
      } catch (error) {
        dispatch(fetchProvidersFailure(error));
      }
    };

    fetchProviders();

    return () => source.cancel();
  }, [dispatch, lastUpdated, loading]);

  return [byId, error, loading];
}
