// @flow

import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {IconButton} from '../IconButton/IconButton';

export const FlexContainer = styled.div`
  display: inline-flex;
  justify-content: ${props => props.justifyContent};
  margin: 0 26px;
`;

const StandardTableFooter = ({
  components,
  changePage,
  changeSizePerPage,
  currPage,
  pageStartIndex,
  sizePerPage,
  sizePerPageList,
  toggleDropDown,
  buttons = [],
  totalPages,
  backgroundColor,
  softBackgroundColor,
  pagination,
}) => {
  const isFirstPage = currPage < 2;
  const isLastPage = currPage > totalPages - 1;
  return (
    <div>
      <div>
        {buttons.map((button, index) => {
          return <span key={index}>{button}</span>;
        })}
      </div>
      {pagination ? (
        <FlexContainer justifyContent="flex-end">
          <IconButton
            iconClass="pe-7s-angle-left"
            tooltipText="Prev page"
            className={isFirstPage ? 'disabled' : ''}
            disabled={isFirstPage ? 'disabled' : ''}
            onClick={isFirstPage ? null : () => changePage(currPage - 1)}
            style={{fontSize: 19}}
          />
          <IconButton
            iconClass="pe-7s-angle-right"
            tooltipText="Next page"
            className={isLastPage ? 'disabled' : ''}
            disabled={isLastPage ? 'disabled' : ''}
            onClick={isLastPage ? null : () => changePage(currPage + 1)}
            style={{fontSize: 19}}
          />
        </FlexContainer>
      ) : null}
    </div>
  );
};

const mapStateToProps = state => ({
  softBackgroundColor: state.ThemeOptions.softBackgroundColor,
  backgroundColor: state.ThemeOptions.backgroundColor,
});

export default connect(mapStateToProps)(StandardTableFooter);
