import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {usePrevious} from './usePrevious';
import axios from 'axios';
import {
  fetchStationsFailure,
  fetchStationsStarted,
  fetchStationsSuccess,
} from '../stores/station/station.action';
import {schema, normalize} from 'normalizr';
import {getStations} from '../contracts/Stations/getStations';
import {getVehiclesByStation} from '../contracts/Stations/getVehiclesByStation';
const stationSchema = new schema.Entity('stations', {}, {idAttribute: 'stationId'});

const stationListSchema = new schema.Array(stationSchema);

export default function useFetchStations() {
  const dispatch = useDispatch();
  const {byId, error, loading, lastUpdated} = useSelector(state => state.Stations);
  const {role} = useSelector(state => state.User);
  const providerId = useSelector(state => state.User.selectedProvider?.providerId);
  const prevProviderId = usePrevious(providerId);

  useEffect(() => {
    const providerChanged = prevProviderId !== providerId;

    const source = axios.CancelToken.source();
    const TTL = 1000 * 60;

    const wasFetchedBefore = lastUpdated;
    const isExpired =
      wasFetchedBefore && new Date().getTime() - lastUpdated.getTime() > TTL;
    if ((wasFetchedBefore && !isExpired && !providerChanged) || loading) return;

    const fetchStations = async () => {
      dispatch(fetchStationsStarted());
      try {
        const stations_ = await getStations();

        let stations = await Promise.all(
          stations_.map(async (station, index) => {
            const resp = await getVehiclesByStation(station.stationId);
            return {
              vehiclesInStation: resp,
              ...station,
            };
          })
        );

        const normalizedData = normalize(stations, stationListSchema);
        dispatch(
          fetchStationsSuccess(
            normalizedData.entities['stations'] ?? {},
            normalizedData.result
          )
        );
      } catch (error) {
        dispatch(fetchStationsFailure(error));
      }
    };

    fetchStations();

    return () => source.cancel();
  }, [dispatch, lastUpdated, role, providerId, prevProviderId, loading]);

  return [byId, error, loading];
}
