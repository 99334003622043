import React from 'react';
import {Route} from 'react-router-dom';
import {connect} from 'react-redux';
import cx from 'classnames';
import {setMobileNavVisibility} from '../../stores/ui/layout.reducer';
import {withRouter} from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';
import SideBar from '../../components/SideBar';
import Dashboard from '../Dashboard';
import Vehicles from '../Vehicles';
import ServiceOffers from '../ServiceOffers';
import ServiceOfferDetails from '../ServiceOfferDetails';
import ServiceOfferFormPage from '../ServiceOfferForm';
import ServiceRequests from '../ServiceRequests';
import ServiceRequestDetails from '../ServiceRequestDetails';
import Jobs from '../Jobs';
import JobDetails from '../JobDetails';
import JobForm from '../JobForm';
import Settings from '../Settings';
import Notifications from 'react-notification-system-redux';
import ServiceRequestForm from '../ServiceRequestForm';
import OptimalRelocations from '../Services';
import Login from '../Login/Login';
import Stations from '../Stations';
import StationDetails from '../StationDetails';
import {useSelector} from 'react-redux';

const Main = ({
  mobileNavVisibility,
  hideMobileMenu,
  history,
  userRole,
  notifications,
  ...props
}) => {
  history.listen(() => {
    if (mobileNavVisibility === true) {
      hideMobileMenu();
    }
  });
  const loggedIn = useSelector(state => state.User.loggedIn);

  if (loggedIn) {
    return (
      <>
        <Notifications notifications={notifications} />
        {
          <div
            className={cx({
              'nav-open': mobileNavVisibility === true,
            })}
          >
            <div className="wrapper">
              <div className="close-layer" onClick={hideMobileMenu} />
              <SideBar />

              <div className="main-panel">
                <Header />

                <Route path="/" component={Dashboard} exact />
                <Route path="/serviceOffers" component={ServiceOffers} exact />
                <Route path="/serviceOffer/new" component={ServiceOfferFormPage} exact />
                <Route path="/serviceOffers/:id" component={ServiceOfferDetails} exact />
                <Route path="/serviceRequests" component={ServiceRequests} exact />
                <Route
                  path="/serviceRequests/:id"
                  component={ServiceRequestDetails}
                  exact
                />
                <Route path="/serviceRequest/new" component={ServiceRequestForm} exact />
                <Route path="/settings" component={Settings} exact />
                <Route path="/vehicles" component={Vehicles} exact />
                <Route path="/jobs" component={Jobs} exact />
                <Route path="/job/new" component={JobForm} exact />
                <Route path="/jobs/:id" component={JobDetails} exact />
                <Route path="/optimalRelocations" component={OptimalRelocations} exact />
                <Route path="/stations" component={Stations} exact />
                <Route path="/stations/:id" component={StationDetails} exact />

                <Footer />
              </div>
            </div>
          </div>
        }
      </>
    );
  } else {
    return (
      <>
        <Notifications notifications={notifications} />
        <Route path="/" component={Login} />;
      </>
    );
  }
};

const mapStateToProp = state => ({
  mobileNavVisibility: state.Layout.mobileNavVisibility,
  userRole: state.User.role,
  notifications: state.notifications,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  hideMobileMenu: () => dispatch(setMobileNavVisibility(false)),
});

export default withRouter(connect(mapStateToProp, mapDispatchToProps)(Main));
