// @flow

import React from 'react';
import {IconButton} from '../../components/IconButton/IconButton';
import {Field, reduxForm} from 'redux-form';
import renderField from '../../components/FormField';
import {ControlLabel, FormGroup} from 'react-bootstrap';
import {Col, Row} from 'react-bootstrap';

const required = value => (!value ? 'This field is required' : undefined);

const SearchBar = ({handleSubmit, submitting, parcels}) => {
  return (
    <Row>
      <Col xs={12}>
        <form className="form-searchbar">
          <Row
            className="row-margin"
            style={{flexWrap: 'wrap', justifyContent: 'flex-start'}}
          >
            <div className="searchField-container">
              <FormGroup style={{paddingLeft: 8}}>
                <ControlLabel>Search for Service Offers</ControlLabel>
                <Field type="text" name="title" validate={[]} component={renderField} />
              </FormGroup>
              <FormGroup className="text-center" style={{marginRight: 64}}>
                <IconButton
                  iconClass="pe-7s-search"
                  caption="Submit"
                  type="submit"
                  tooltipText="search"
                  disabled={submitting}
                  style={{
                    fontSize: 19,
                    marginTop: 27,
                  }}
                />
              </FormGroup>
            </div>
            <div className="select-category-container">
              <FormGroup style={{width: 150, paddingLeft: 8}}>
                <ControlLabel>Category</ControlLabel>
                <Field
                  className="form-control"
                  name="category"
                  component="select"
                  validate={[required]}
                >
                  <option disabled hidden label="please choose" value={''} />
                  <option value="supply">supply</option>
                  <option value="relocation">relocation</option>
                  <option value="maintenance">maintenance</option>
                  <option value="others">others</option>
                </Field>
              </FormGroup>
            </div>
            <div className="select-city-container">
              <FormGroup style={{width: 150, paddingLeft: 8}}>
                <ControlLabel>City / Post Code</ControlLabel>
                <Field type="text" name="city" component={renderField}></Field>
              </FormGroup>
            </div>
          </Row>
        </form>
      </Col>
    </Row>
  );
};

export default reduxForm({
  form: 'searchServiceOfferForm',
  enableReinitialize: true,
  destroyOnUnmount: false,
})(SearchBar);
