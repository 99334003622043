import React from 'react';
import {Field, reduxForm} from 'redux-form';
import renderField from '../../components/FormField';
import {ControlLabel, FormGroup} from 'react-bootstrap';
import {Col, Row} from 'react-bootstrap';
import TextButton from '../../components/TextButton';

const ServiceOfferForm = ({handleSubmit, submitting}) => {
  return (
    <form onSubmit={handleSubmit}>
      <FormGroup className="col-md-12 col-padding">
        <ControlLabel>title</ControlLabel>
        <Field type="text" name="title" validate={[]} component={renderField} />
      </FormGroup>
      <Row>
        <Col xs={6}>
          <FormGroup className="col-md-12 col-padding">
            <ControlLabel>service type</ControlLabel>
            <Field
              className="form-control"
              name="serviceType"
              component="select"
              validate={[]}
            >
              <option disabled hidden label="please choose" value={''} />
              <option value="relocation">relocation</option>
              <option value="maintenance">maintenance</option>
              <option value="others">others</option>
            </Field>
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup className="col-md-12 col-padding">
            <ControlLabel>operation area</ControlLabel>
            <Field
              className="form-control"
              name="operationArea"
              component="select"
              validate={[]}
            >
              <option disabled hidden label="please choose" value={''} />
              <option value="amsterdam">Amsterdam</option>
              <option value="berlin">Berlin</option>
              <option value="hamburg">Hamburg</option>
              <option value="koeln">Köln</option>
              <option value="tilburg">Tilburg</option>
            </Field>
          </FormGroup>
        </Col>
      </Row>
      <FormGroup className="col-md-12 col-padding">
        <ControlLabel>description</ControlLabel>
        <Field type="textbox" name="description" validate={[]} component={renderField} />
      </FormGroup>
      <FormGroup className="text-center">
        <TextButton caption="Submit" type="submit" disabled={submitting} />
      </FormGroup>
    </form>
  );
};

export default reduxForm({
  form: 'serviceOfferForm',
  enableReinitialize: true,
})(ServiceOfferForm);
