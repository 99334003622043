import axios from 'axios';
import {
  FETCH_EVENTS_FAILURE,
  FETCH_EVENTS_STARTED,
  FETCH_EVENTS_SUCCESS,
} from './events.reducer';

//fetch Events
export const fetchEventsStarted = () => ({
  type: FETCH_EVENTS_STARTED,
});
export const fetchEventsSuccess = (serviceRequests, allIds) => ({
  type: FETCH_EVENTS_SUCCESS,
  payload: serviceRequests,
  allIds: allIds,
});
export const fetchEventsFailure = error => ({
  type: FETCH_EVENTS_FAILURE,
  payload: error,
});

export const fetchEvents = coord => {
  return async (dispatch, getState) => {
    dispatch(fetchEventsStarted());
    try {
      var today = new Date();
      var date =
        today.getFullYear() +
        '-' +
        String(today.getMonth() + 1).padStart(2, '0') +
        '-' +
        String(today.getDate()).padStart(2, '0');

      const searchParams = {
        within: '25km@' + coord[0] + ',' + coord[1] + '',
        'start.gte': date,
        sort: 'start',
      };
      const response = await axios.post('api/filterEvents', searchParams);
      dispatch(fetchEventsSuccess(response.data.events));
    } catch (error) {
      dispatch(fetchEventsFailure(error));
    }
  };
};
