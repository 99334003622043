//@flow

import React from 'react';
import Card from '../../components/Card';
import {connect} from 'react-redux';
import {ServiceRequestForm} from '../../pages/ServiceRequestForm';

const ServiceRequestEditCard = ({serviceRequest, onSubmit}) => {
  if (!serviceRequest) {
    return (
      <Card>
        <Card.Header>Service Request Details</Card.Header>
      </Card>
    );
  }

  const handleSubmit = values => {
    onSubmit(values);
  };

  return (
    <Card>
      <Card.Header>Service Request Details</Card.Header>
      <Card.Content>
        <ServiceRequestForm
          onSubmit={values => handleSubmit(values)}
          initialValues={serviceRequest}
        />
      </Card.Content>
    </Card>
  );
};

const mapStateToProps = (state, ownProps) => ({
  serviceRequest: ownProps.serviceRequest,
});

export default connect(mapStateToProps)(ServiceRequestEditCard);
