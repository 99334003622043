import React from 'react';
import {connect} from 'react-redux';
import {Col, Row, Grid} from 'react-bootstrap';
import useFetchStations from '../../hooks/useFetchStations';
import NotFound from '../../components/NotFound';
import TableCard from '../../components/TableCard';
import {updateSelectedStation} from '../../stores/station/station.action';
import MapCard from '../../components/MapCard/MapCard';
import {IconSelector} from '../../components/IconSelector/IconSelector';
import StationDetailsCard from '../../components/StationDetailsCard';
import {useHistory} from 'react-router-dom';
const Stations = ({selectedStation, updateSelectedStation}) => {
  const history = useHistory();

  const [stationsById, error, isLoading] = useFetchStations();
  const stations = stationsById ? Object.values(stationsById) : [];

  if (error) {
    return <NotFound entityName={'stations'} />;
  }

  const stationsMarkers = stations.map(station => {
    const icon = IconSelector({vehicleType: 'Station-Icon'});
    const currentPosition = {lat: station.location[0], lng: station.location[1]};
    const stationId = station.stationId;
    return {stationId, currentPosition, icon};
  });

  const selectedStationMarker = selectedStation
    ? stationsMarkers.find(
        stationMarker => stationMarker.stationId === selectedStation.stationId
      )
    : null;

  return (
    <div className="main-content">
      <Grid
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Col xs={12} lg={8} className="col-padding" style={{marginTop: 15}}>
          <MapCard
            title="Station Map"
            data={{markers: stationsMarkers}}
            selectedMarker={selectedStationMarker}
            mapHeight="60vh"
            onSelectedMarkerChange={stationMarker => {
              const nextSelectedStation = stations.find(
                station => station.stationId === stationMarker.stationId
              );
              updateSelectedStation(nextSelectedStation);
            }}
          />
        </Col>
        <Col xs={6} lg={4} className="col-padding" style={{marginTop: 15}}>
          <Col xs={12} className="col-padding-zero">
            <StationDetailsCard
              station={selectedStation}
              onMoreDetails={() => {
                history.push('/stations/' + selectedStation.stationId);
              }}
            />
          </Col>
        </Col>
        <Row>
          <Col xs={12} className="col-padding" style={{marginTop: 15}}>
            <TableCard
              title={'Station List'}
              data={stations}
              labels={[
                {
                  field: 'stationId',
                  caption: 'Id',
                  dataSort: true,
                  width: '300px',
                  isKey: true,
                },
                {
                  field: 'stationName',
                  caption: 'name',
                  dataSort: true,
                  width: '50%',
                },
                {
                  field: 'nrMax',
                  caption: 'Docks',
                  dataSort: true,
                  width: '50%',
                },
                {
                  field: 'nrAvailable',
                  caption: 'Vehicles',
                  dataSort: true,
                  width: '50%',
                },
              ]}
              keyClickUrl={row => `/stations/${row.stationId}`}
              onRowClick={row => {
                updateSelectedStation(row);
              }}
              selected={selectedStation ? [selectedStation.stationId] : null}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => ({
  softBackgroundColor: state.ThemeOptions.softBackgroundColor,
  backgroundColor: state.ThemeOptions.backgroundColor,
  selectedStation: state.Stations.selectedStation,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateSelectedStation: station => dispatch(updateSelectedStation(station)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Stations);
