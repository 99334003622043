//@flow

export type User = {
  id: number,
  email: string,
  familyName: string,
  givenName: string,
  imageUrl: string,
};

type ProviderRole = {
  providerId: number,
  providerName: string,
  roleHash: number,
  isAdmin: boolean,
  selected: boolean,
};

const MOBILITY_PROVIDER = 'Mobility Provider';
const SERVICE_PROVIDER = 'Service Provider';

export const USER_ROLES = {
  MOBILITY_PROVIDER,
  SERVICE_PROVIDER,
};
export type UserRole = MOBILITY_PROVIDER | SERVICE_PROVIDER;

type State = {
  +loading: boolean,
  +error: any | null,
  +role: UserRole,
  +selectedProvider: ProviderRole,
  +chainId: any,
  +pendingTransaction: Boolean,
  lastUpdated?: Date,
};

const INITIAL_STATE = {
  loading: false,
  error: null,
  role: MOBILITY_PROVIDER,
  byId: {},
  allIds: [],
  selectedProvider: null,
  chainId: null,
  pendingTransaction: false,
};

export type ON_NEW_TOKEN_ACTION = {
  type: 'ON_NEW_TOKEN',
  idToken: string,
};

export type USER_LOGIN_SUCCESS_ACTION = {
  type: 'USER_LOGIN_SUCCESS',
  id: number,
  expiresAt: number,
  email: string,
  familyName: string,
  givenName: string,
  imageUrl: string,
  sub: string,
};

export type USER_LOGOUT_ACTION = {type: 'USER_LOGOUT'};

export const USER_UPDATE_ROLE = 'USER_UPDATE_ROLE';
export type USER_UPDATE_ROLE_ACTION = {type: USER_UPDATE_ROLE, role: UserRole};

export const FETCH_PROVIDER_ROLES_STARTED: 'FETCH_PROVIDER_ROLES_STARTED' =
  'FETCH_PROVIDER_ROLES_STARTED';
export const FETCH_PROVIDER_ROLES_SUCCESS: 'FETCH_PROVIDER_ROLES_SUCCESS' =
  'FETCH_PROVIDER_ROLES_SUCCESS';
export const FETCH_PROVIDER_ROLES_FAILURE: 'FETCH_PROVIDER_ROLES_FAILURE' =
  'FETCH_PROVIDER_ROLES_FAILURE';

export type FETCH_PROVIDER_ROLES_STARTED_ACTION = {
  type: typeof FETCH_PROVIDER_ROLES_STARTED,
};

export type FETCH_PROVIDER_ROLES_SUCCESS_ACTION = {
  type: typeof FETCH_PROVIDER_ROLES_SUCCESS,
  payload: {id: ProviderRole},
  allIds: string[],
};

export type FETCH_PROVIDER_ROLES_FAILURE_ACTION = {
  type: typeof FETCH_PROVIDER_ROLES_FAILURE,
  payload: any,
};

export const UPDATE_SELECTED_PROVIDER: 'UPDATE_SELECTED_PROVIDER' =
  'UPDATE_SELECTED_PROVIDER';

export type UPDATE_SELECTED_PROVIDER_ACTION = {
  type: typeof UPDATE_SELECTED_PROVIDER,
  payload: ProviderRole,
};

type Action =
  | ON_NEW_TOKEN_ACTION
  | USER_LOGIN_SUCCESS_ACTION
  | USER_LOGOUT_ACTION
  | USER_UPDATE_ROLE_ACTION
  | FETCH_PROVIDER_ROLES_FAILURE_ACTION
  | FETCH_PROVIDER_ROLES_STARTED_ACTION
  | FETCH_PROVIDER_ROLES_SUCCESS_ACTION
  | UPDATE_SELECTED_PROVIDER_ACTION
  | UPDATE_PENDING_TRANSACTION_ACTION;

export default (state: State = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case 'ON_NEW_TOKEN':
      return {
        ...state,
        idToken: action.idToken, //JWT
      };
    case 'USER_LOGIN_SUCCESS':
      return {
        ...state,
        id: action.id,
        loading: false,
        loggedIn: true,
        expiresAt: action.expiresAt, //millisecond since 01.01.1970 (equals approx. UNIX * 1000)
        email: action.email,
        familyName: action.familyName,
        givenName: action.givenName,
        imageUrl: action.imageUrl,
        sub: action.sub,
      };
    case 'USER_LOGOUT':
      return {
        ...state,
        loggedIn: false,
        loading: false,
      };
    case USER_UPDATE_ROLE:
      return {
        ...state,
        role: action.role,
      };
    case FETCH_PROVIDER_ROLES_STARTED:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PROVIDER_ROLES_SUCCESS:
      const selected = state.selectedProvider
        ? state.selectedProvider
        : action.payload[action.allIds[0]];
      return {
        ...state,
        loading: false,
        byId: action.payload,
        allIds: action.allIds,
        selectedProvider: selected,
        lastUpdated: new Date(),
      };
    case FETCH_PROVIDER_ROLES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_SELECTED_PROVIDER:
      return {
        ...state,
        selectedProvider: action.payload,
      };
    default:
      return state;
  }
};
