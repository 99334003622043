// @flow

import React from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import StandardTableFooter from './StandardTableFooter';
import {Link} from 'react-router-dom';
import {JSX} from '@babel/types';

export type TableLabel = {
  isKey?: boolean,
  field: string,
  caption: string,
  dataSort?: boolean,
  width?: number | string,
  dataFormat?: (cell: any, row?: {}) => string | JSX,
};

type Props = {
  labels: Array<TableLabel>,
  data: Array<any>,
  tableButtons?: Array<any>,
  rowButtons?: Array<any>,
  sizePerPage?: number,
  onRowClick?: (row: any) => void,
  rowClickUrl?: Function | string,
  keyClickUrl?: Function | string,
  pagination?: boolean,
  multiSelection?: boolean,
  onSelectionChange?: (rows: any) => void,
  selected?: Array<number>,
};

/**
 * a table component with pagination
 */
const StandardTable = ({
  labels = [],
  data = [],
  tableButtons = [],
  rowButtons = [],
  sizePerPage = 5,
  onRowClick = null,
  rowClickUrl = null,
  keyClickUrl = null,
  pagination = true,
  multiSelection = false,
  onSelectionChange = () => {},
  selected = null,
  ...rest
}: Props) => {
  const options = {
    sizePerPage: sizePerPage,
    paginationPanel: props => (
      <StandardTableFooter buttons={tableButtons} pagination={pagination} {...props} />
    ),
    onRowClick: onRowClick,
  };

  const renderCell = (cell, row, label) => {
    let component;

    if (label.isKey && keyClickUrl) {
      component = (
        <Link
          to={{
            pathname: keyClickUrl instanceof Function ? keyClickUrl(row) : keyClickUrl,
            state: row,
          }}
          className="blue-link"
        >
          {cell}
        </Link>
      );
    } else if (!label.isKey && rowClickUrl) {
      component = (
        <Link
          to={{
            pathname: rowClickUrl instanceof Function ? rowClickUrl(row) : rowClickUrl,
            state: row,
          }}
          onClick={e => {
            e.stopPropagation();
            if (onRowClick) onRowClick(row);
          }}
          className="blue-link"
        >
          {label.dataFormat ? label.dataFormat(cell) : cell}
        </Link>
      );
    } else {
      component = label.dataFormat ? label.dataFormat(cell) : cell;
    }

    return component;
  };

  // TODO: Selected Flag instead of whole data
  let selectedRows = [];

  const onSelect = (row, isSelected, e) => {
    if (isSelected && selectedRows.findIndex(item => item === row) < 0) {
      selectedRows.push(row);
    } else if (!isSelected && selectedRows.findIndex(item => item === row) > -1) {
      const index = selectedRows.findIndex(item => item === row);
      selectedRows.splice(index, 1);
    }
    onSelectionChange(selectedRows);
  };

  const onSelectAll = (isSelected, rows) => {
    if (isSelected) selectedRows = rows;
    else selectedRows = [];
    onSelectionChange(selectedRows);
  };

  return (
    <BootstrapTable
      {...rest}
      data={data}
      bordered={false}
      hover={true}
      striped
      pagination
      height="230px"
      options={options}
      selectRow={{
        hideSelectColumn: true,
        mode: 'checkbox',
        bgColor: '#0D4D9940',
        onSelect: onSelect,
        onSelectAll: onSelectAll,
        selected: selected,
      }}
    >
      {labels.map(label => (
        <TableHeaderColumn
          key={label.field}
          isKey={label.isKey}
          dataSort={label.dataSort}
          dataField={label.field}
          width={label.width}
          dataFormat={(cell, row) => renderCell(cell, row, label)}
        >
          {label.caption}
        </TableHeaderColumn>
      ))}
      {rowButtons.length > 0 ? (
        <TableHeaderColumn
          dataFormat={(cell, row) => (
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
              {rowButtons.map((button, index) => {
                return <span key={index}>{button(row, cell)}</span>;
              })}
            </div>
          )}
          width="100px"
        >
          Actions
        </TableHeaderColumn>
      ) : null}
    </BootstrapTable>
  );
};

export default StandardTable;
