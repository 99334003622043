import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import {
  fetchVehiclesFailure,
  fetchVehiclesStarted,
  fetchVehiclesSuccess,
} from '../stores/vehicle/vehicle.actions';
import {schema, normalize} from 'normalizr';
import {getVehicles} from '../contracts/VehicleRegistry/contractMethods/getVehicles';
import {usePrevious} from './usePrevious';

const vehicleSchema = new schema.Entity('vehicles', {}, {idAttribute: 'vehicleId'});

const vehicleListSchema = new schema.Array(vehicleSchema);

export default function useFetchVehicles() {
  const dispatch = useDispatch();
  const {byId, error, loading, lastUpdated} = useSelector(state => state.Vehicles);
  const {role, selectedProvider} = useSelector(state => state.User);
  const providerId = selectedProvider?.providerId;
  const prevProviderId = usePrevious(providerId);
  const prevRole = usePrevious(role);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const TTL = 1000 * 60;

    const roleChanged = prevRole !== role;
    const providerChanged = prevProviderId !== providerId;

    const wasFetchedBefore = lastUpdated;
    const isExpired =
      wasFetchedBefore && new Date().getTime() - lastUpdated.getTime() > TTL;
    if ((wasFetchedBefore && !isExpired && !roleChanged && !providerChanged) || loading)
      return;

    const fetchVehicles = async () => {
      dispatch(fetchVehiclesStarted());
      try {
        let bcVehicles;
        if (role === 'Mobility Provider') {
          bcVehicles = await getVehicles(providerId);
        } else {
          bcVehicles = await getVehicles();
        }

        const normalizedData = normalize(bcVehicles, vehicleListSchema);
        dispatch(
          fetchVehiclesSuccess(
            normalizedData.entities['vehicles'] ?? {},
            normalizedData.result
          )
        );
      } catch (error) {
        dispatch(fetchVehiclesFailure(error));
      }
    };

    fetchVehicles();

    return () => source.cancel();
  }, [dispatch, lastUpdated, role, providerId, prevRole, prevProviderId, loading]);

  return [byId, error, loading];
}
