// @flow

export type Settings = {
  backendBaseUrl: string,
};

const INITIAL_STATE: Settings = {
  backendBaseUrl:
    process.env.NODE_ENV === 'production'
      ? '/'
      : process.env.REACT_APP_API_BASEURL.split(',')[0].trim(),
};

export const SET_BACKEND_BASE_URL = 'SET_BACKEND_BASE_URL';

export const setBackendBaseUrl = backendBaseUrl => ({
  type: SET_BACKEND_BASE_URL,
  backendBaseUrl,
});

export default (state: Settings = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_BACKEND_BASE_URL:
      return {
        ...state,
        backendBaseUrl: action.backendBaseUrl,
      };
    default:
      return state;
  }
};
