import React from 'react';
import {NavListItem} from './NavListItem';

/**
 * navigation list for service provider view
 */
export const ServiceProviderNav = () => {
  return (
    <ul className="nav">
      <NavListItem to="/" caption="Dashboard" iconClass="pe-7s-graph" />
      <NavListItem to="/serviceOffers" caption="Marketplace" iconClass="pe-7s-global" />
      <NavListItem
        to="/serviceRequests"
        caption="Service Requests"
        iconClass="pe-7s-chat"
      />
      <NavListItem to="/jobs" caption="Jobs" iconClass="pe-7s-note2" />
      <NavListItem to="/settings" caption="Settings" iconClass="pe-7s-config" />
    </ul>
  );
};
