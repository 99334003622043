/* ignore */
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchEvents} from '../stores/events/events.action';
import {usePrevious} from './usePrevious';

export default function useEvents(eventsCoord) {
  const {events, error, loading} = useSelector(state => state.Events);
  const dispatch = useDispatch();
  const prevCoords = usePrevious(eventsCoord);
  const coordsChanged = prevCoords !== eventsCoord;

  useEffect(() => {
    if (loading || !coordsChanged) return;
    async function getEvents(eventsCoord) {
      dispatch(fetchEvents(eventsCoord));
    }
    getEvents(eventsCoord);
  }, [dispatch, loading, eventsCoord, coordsChanged]);

  return [events, error, loading];
}
