//@flow

import React from 'react';
import {Col, Row} from 'react-bootstrap';
import Card from '../Card';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

/**
 * A card component showing the details of a job.
 */
const JobDetailsCard = ({handleEditJob, handleDeleteJob, ...props}) => {
  const content = [];
  const job = props.job
    ? props.job
    : {
        jobId: '',
        vehicleId: '',
        providerId: '',
        type: '',
        price: '',
        status: '',
        description: '',
      };

  if (!job) {
    return (
      <Card>
        <Card.Header>Job Details</Card.Header>
      </Card>
    );
  }

  Object.keys(job).forEach(key => {
    content.push(
      <Row>
        <Col xs={6} className="col-padding-zero">
          {key}
        </Col>
        <Col xs={6}>
          <Link className="blue-link">{job[key]}</Link>
        </Col>
      </Row>
    );
  });

  return (
    <Card>
      <Card.Header>Job Details</Card.Header>
      <Card.Content>{content}</Card.Content>
      <Card.Legend></Card.Legend>
    </Card>
  );
};

const mapStateToProps = (state, ownProps) => ({
  job: ownProps.job,
  handleEditJob: ownProps.onEditJob,
  handleDeleteJob: ownProps.onDeleteJob,
});

export default connect(mapStateToProps)(JobDetailsCard);
