import React from 'react';
import {Field, reduxForm, change, initialize} from 'redux-form';
import renderField from '../../components/FormField';
import {ControlLabel} from 'react-bootstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import RedX from '../../assets/images/red-x.png';

const VehiclesPerEventForm = ({
  onNumOfVehicleChanged,
  onRemoveCoord,
  initialValues,
  ...props
}) => {
  const coord = props.coord;
  const coordId = coord.id;
  const newCoord = props.newCoord;

  const assignVehicle = (lat, lng, demand) => {
    let vehiclesPerAddr = [...newCoord];
    vehiclesPerAddr.forEach(dest => {
      if (dest.lat === lat && dest.lng === lng) {
        dest.demand = demand;
      }
    });
    return vehiclesPerAddr;
  };

  const handleChange = values => {
    onNumOfVehicleChanged(values);
  };

  return (
    <form>
      <ControlLabel style={{marginTop: '5px'}}>
        How Many Vehicles for
        <div style={{display: 'flex', alignItems: 'center'}}>
          <p>
            <b>{coordId} ?</b>
            <button className="red-x-bottom" onClick={() => onRemoveCoord(coordId)}>
              <img className="red-x-image" src={RedX} />
            </button>
          </p>
        </div>
      </ControlLabel>
      <ControlLabel></ControlLabel>
      <Field
        validate={[]}
        component={renderField}
        name="numOfVehicles"
        type="text"
        placeholder="Number of vehicles"
        onChange={(e, value) => handleChange(assignVehicle(coord.lat, coord.lng, value))}
      ></Field>
    </form>
  );
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({change, initialize}, dispatch);
};

export default reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(connect(null, mapDispatchToProps)(VehiclesPerEventForm));
