import {
  FETCH_PROVIDERS_FAILURE,
  FETCH_PROVIDERS_STARTED,
  FETCH_PROVIDERS_SUCCESS,
  FETCH_PROVIDER_BY_ID_FAILURE,
  FETCH_PROVIDER_BY_ID_STARTED,
  FETCH_PROVIDER_BY_ID_SUCCESS,
  UPDATE_PROVIDER_FAILURE,
  UPDATE_PROVIDER_STARTED,
  UPDATE_PROVIDER_SUCCESS,
  ADD_PROVIDER_FAILURE,
  ADD_PROVIDER_STARTED,
  ADD_PROVIDER_SUCCESS,
} from './provider.reducer';
import {createProvider} from '../../contracts/ProviderRegistry/contractMethods/createProvider';
import {renameProvider} from '../../contracts/ProviderRegistry/contractMethods/renameProvider';
import {fetchRolesByAddress} from '../user/user.actions';

export const fetchProvidersStarted = () => ({
  type: FETCH_PROVIDERS_STARTED,
});
export const fetchProvidersSuccess = (providers, allIds) => ({
  type: FETCH_PROVIDERS_SUCCESS,
  payload: providers,
  allIds: allIds,
});
export const fetchProvidersFailure = error => ({
  type: FETCH_PROVIDERS_FAILURE,
  payload: error,
});
//fetchProvidersById
export const fetchProviderByIdStarted = () => ({type: FETCH_PROVIDER_BY_ID_STARTED});
export const fetchProviderByIdSuccess = provider => ({
  type: FETCH_PROVIDER_BY_ID_SUCCESS,
  payload: provider,
});
export const fetchProviderByIdFailure = error => ({
  type: FETCH_PROVIDER_BY_ID_FAILURE,
  payload: error,
});

export const updateProviderStarted = () => ({type: UPDATE_PROVIDER_STARTED});
export const updateProviderSuccess = provider => ({
  type: UPDATE_PROVIDER_SUCCESS,
  payload: provider,
});
export const updateProviderFailure = error => ({
  type: UPDATE_PROVIDER_FAILURE,
  payload: error,
});

export const addProviderStarted = () => ({type: ADD_PROVIDER_STARTED});

export const addProviderSuccess = provider => ({
  type: ADD_PROVIDER_SUCCESS,
  payload: provider,
});

export const addProviderFailure = error => ({
  type: ADD_PROVIDER_FAILURE,
  payload: error,
});

export const updateProvider = provider => {
  return async (dispatch, getState) => {
    dispatch(updateProviderStarted());
    try {
      await renameProvider(provider.providerId, provider.providerName);
      return dispatch(
        updateProviderSuccess({
          providerId: provider.providerId,
          providerName: provider.providerName,
        })
      );
    } catch (error) {
      return dispatch(updateProviderFailure(error));
    }
  };
};

export const addProvider = provider => {
  return async (dispatch, getState) => {
    dispatch(addProviderStarted());
    try {
      const result = await createProvider(provider.providerName);
      await dispatch(fetchRolesByAddress()); // update providers linked to account in redux store
      return dispatch(
        addProviderSuccess({
          providerId: result.events.ProviderAdded.returnValues.providerId,
          providerName: provider.providerName,
          adminAddress: provider.adminAddress,
        })
      );
    } catch (error) {
      return dispatch(addProviderFailure(error));
    }
  };
};
