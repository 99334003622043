import React from 'react';
import {connect, useSelector} from 'react-redux';
import './vehicles.scss';
import {useHistory} from 'react-router-dom';
import {Col, Row, Grid} from 'react-bootstrap';
import TableCard from '../../components/TableCard';
import VehicleDetailsCard from './../../components/VehicleDetailsCard';
import MapCard from '../../components/MapCard/MapCard';
import {updateSelectedVehicle} from '../../stores/vehicle/vehicle.actions';
import useFetchVehicles from '../../hooks/useFetchVehicles';
import Card from '../../components/Card';
import NotFound from '../../components/NotFound';
import {IconSelector} from '../../components/IconSelector/IconSelector';

const Vehicles = ({updateSelectedVehicle}) => {
  const history = useHistory();
  const [vehiclesById, error, isLoading] = useFetchVehicles();
  const selectedVehicle = useSelector(state => state.Vehicles.selectedVehicle);
  const vehicles = vehiclesById ? Object.values(vehiclesById) : [];

  if (!vehicles && !error) {
    return (
      <div className="main-content">
        <Col sm={12} className="col-padding">
          <Card>
            <Card.Header>Loading...</Card.Header>
            <Card.Content isLoading={isLoading} />
          </Card>
        </Col>
      </div>
    );
  }
  if (error) {
    return <NotFound entityName={'vehicles'} />;
  }

  const vehicleMarkers = vehicles.map(vehicle => {
    var icon = IconSelector({vehicleType: vehicle.vehicleType});
    return {...vehicle, icon: icon};
  });

  const handleRequestService = () => {
    history.push('/serviceRequest/new');
  };

  console.log(vehicles);
  return (
    <div className="main-content">
      <Grid
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Row>
          <Col xs={6} lg={4} className="col-padding" style={{marginTop: 15}}>
            <VehicleDetailsCard
              vehicle={selectedVehicle}
              onRequestService={() => handleRequestService()}
            />
          </Col>
          <Col xs={12} lg={8} className="col-padding" style={{marginTop: 15}}>
            <MapCard
              title="Vehicle Map"
              data={{markers: vehicleMarkers}}
              selectedMarker={selectedVehicle}
              mapHeight="60vh"
              onSelectedMarkerChange={vehicle => updateSelectedVehicle(vehicle)}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="col-padding" style={{marginTop: 15}}>
            <TableCard
              title={'Vehicle List'}
              data={vehicles}
              labels={[
                {
                  field: 'vehicleId',
                  caption: 'id',
                  dataSort: true,
                  width: '300px',
                  isKey: true,
                },
                {
                  field: 'displayName',
                  caption: 'name',
                  dataSort: true,
                  width: '50%',
                },
                {
                  field: 'provider',
                  caption: 'provider',
                  dataSort: true,
                  width: '50%',
                },
                {
                  field: 'status',
                  caption: 'Status',
                  dataSort: true,
                  width: '50%',
                },
              ]}
              onRowClick={row => updateSelectedVehicle(row)}
              selected={selectedVehicle ? [selectedVehicle.vehicleId] : null}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

const mapDispatchToProps = {
  updateSelectedVehicle: updateSelectedVehicle,
};

export default connect(null, mapDispatchToProps)(Vehicles);
