import {
  ADD_JOB_FAILURE,
  ADD_JOB_STARTED,
  ADD_JOB_SUCCESS,
  FETCH_JOBS_FAILURE,
  FETCH_JOBS_STARTED,
  FETCH_JOBS_SUCCESS,
  FETCH_JOB_BY_ID_FAILURE,
  FETCH_JOB_BY_ID_STARTED,
  FETCH_JOB_BY_ID_SUCCESS,
  UPDATE_JOB_FAILURE,
  UPDATE_JOB_STARTED,
  UPDATE_JOB_SUCCESS,
  DELETE_JOB_STARTED,
  DELETE_JOB_FAILURE,
  DELETE_JOB_SUCCESS,
  UPDATE_SELECTED_JOB,
  ACCEPT_JOB_FAILURE,
  ACCEPT_JOB_STARTED,
  ACCEPT_JOB_SUCCESS,
  REJECT_JOB_FAILURE,
  REJECT_JOB_STARTED,
  REJECT_JOB_SUCCESS,
} from './job.reducer';

import {applyForServiceRequest} from '../../contracts/ServiceRequestRegistry/contractMethods/applyForServiceRequest';
import {acceptJob as b_acceptJob} from '../../contracts/JobRegistry/contractMethods/acceptJob';
import {rejectJob as b_rejectJob} from '../../contracts/JobRegistry/contractMethods/rejectJob';
import {getJobs} from '../../contracts/JobRegistry/contractMethods/getJobs';

//fetch Jobs
export const fetchJobsStarted = () => ({
  type: FETCH_JOBS_STARTED,
});
export const fetchJobsSuccess = (jobs, allIds) => ({
  type: FETCH_JOBS_SUCCESS,
  payload: jobs,
});
export const fetchJobsFailure = error => ({
  type: FETCH_JOBS_FAILURE,
  payload: error,
});
//fetchJobsById
export const fetchJobByIdStarted = () => ({
  type: FETCH_JOB_BY_ID_STARTED,
});
export const fetchJobByIdSuccess = job => ({
  type: FETCH_JOB_BY_ID_SUCCESS,
  payload: job,
});
export const fetchJobByIdFailure = error => ({
  type: FETCH_JOB_BY_ID_FAILURE,
  payload: error,
});

export const addJobStarted = () => ({type: ADD_JOB_STARTED});
export const addJobSuccess = job => ({
  type: ADD_JOB_SUCCESS,
  payload: job,
});
export const addJobFailure = error => ({
  type: ADD_JOB_FAILURE,
  payload: error,
});

export const addJob = job => {
  return async (dispatch, getState) => {
    const state = getState();
    const providerId = state.User.selectedProvider.providerId;

    dispatch(addJobStarted());
    try {
      const response = await applyForServiceRequest(
        job.serviceRequestId,
        job.description,
        job.price,
        providerId
      );
      const bcJobs = await getJobs();

      return dispatch(
        addJobSuccess({
          jobId: bcJobs[bcJobs.length - 1].jobId,
          description: job.description,
          vehicleId: job.vehicleId,
          price: `${job.price / 100.0}€`,
          clientId: job.clientId,
          contractorId: job.contractorId,
          serviceRequestId: job.serviceRequestId,
          status: 'pending',
        })
      );
    } catch (error) {
      return dispatch(addJobFailure(error));
      //TODO show error message
    }
  };
};

export const updateJobStarted = () => ({type: UPDATE_JOB_STARTED});
export const updateJobSuccess = job => ({
  type: UPDATE_JOB_SUCCESS,
  payload: job,
});
export const updateJobFailure = error => ({
  type: UPDATE_JOB_FAILURE,
  payload: error,
});

export const updateJob = job => {
  return async (dispatch, getState) => {
    dispatch(updateJobStarted());
    try {
      // TODO: Update next two lines if endpoint available
      // const response = await axios.post('/jobs', job);
      const response = {data: job};
      dispatch(updateJobSuccess(response.data));
    } catch (error) {
      dispatch(updateJobFailure(error));
    }
  };
};

export const deleteJobStarted = () => ({type: DELETE_JOB_STARTED});
export const deleteJobSuccess = job => ({
  type: DELETE_JOB_SUCCESS,
  payload: job,
});
export const deleteJobFailure = error => ({
  type: DELETE_JOB_FAILURE,
  payload: error,
});

export const deleteJob = job => {
  return async (dispatch, getState) => {
    dispatch(deleteJobStarted());
    try {
      // TODO: Update next two lines if endpoint available
      // const response = await axios.post('/jobs', job);
      dispatch(deleteJobSuccess(job.jobId));
    } catch (error) {
      dispatch(deleteJobFailure(error));
    }
  };
};

export const updateSelectedJob = job => {
  return {
    type: UPDATE_SELECTED_JOB,
    payload: job,
  };
};

export const acceptJobStarted = () => ({type: ACCEPT_JOB_STARTED});
export const acceptJobSuccess = job => ({
  type: ACCEPT_JOB_SUCCESS,
  payload: job,
});

export const acceptJobFailure = error => ({
  type: ACCEPT_JOB_FAILURE,
  payload: error,
});

export const acceptJob = job => {
  return async (dispatch, getState) => {
    dispatch(acceptJobStarted());

    try {
      await b_acceptJob(job.jobId).then(res => console.log(res));
      return dispatch(
        acceptJobSuccess({
          jobId: job.jobId,
          status: 'accepted',
        })
      );
    } catch (error) {
      return dispatch(acceptJobFailure(error));
    }
  };
};

export const rejectJobStarted = () => ({type: REJECT_JOB_STARTED});
export const rejectJobSuccess = job => ({
  type: REJECT_JOB_SUCCESS,
  payload: job,
});

export const rejectJobFailure = error => ({
  type: REJECT_JOB_FAILURE,
  payload: error,
});

export const rejectJob = job => {
  return async (dispatch, getState) => {
    dispatch(rejectJobStarted());
    try {
      await b_rejectJob(job.jobId).then(res => console.log(res));
      return dispatch(
        rejectJobSuccess({
          jobId: job.jobId,
          status: 'rejected',
        })
      );
    } catch (error) {
      return dispatch(rejectJobFailure(error));
    }
  };
};
