//@flow

import React from 'react';
import {Col, Row} from 'react-bootstrap';
import Card from '../Card';
import IconButton from '../IconButton';
import {connect, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

/**
 * A card component showing the details of a service request.
 * By clicking the details button, the user will be redirected to the details page.
 * The initiator of the request can edit or delete the request by using the respective buttons.
 * A service provider can apply for a request by using the apply button.
 * if no service request is passed via props, the card advises the user to select a serviceRequest.
 */
const ServiceRequestDetailsCard = ({
  handleEditServiceRequest,
  handleDeleteServiceRequest,
  handleMoreDetails,
  handleApplyForServiceRequest,
  jobs = [],
  ...props
}) => {
  const content = [];

  const role = useSelector(state => state.User.role);
  const {selectedProvider} = useSelector(state => state.User);

  let serviceRequest = props.serviceRequest
    ? props.serviceRequest
    : {
        serviceRequestId: '',
        vehicleId: '',
        type: '',
        price: '',
        description: '',
        requesterId: '',
        jobIds: [],
      };
  const isOwner = serviceRequest.requesterId === selectedProvider?.providerId;

  if (!props.serviceRequest)
    content.push(
      <div
        key={'placeholder'}
        style={{
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          right: '20%',
          top: 0,
          bottom: 0,
          margin: 'auto',
        }}
      >
        please select a service request
      </div>
    );

  Object.keys(serviceRequest).forEach((key, index) => {
    if (key !== 'jobIds') {
      content.push(
        <Row key={index}>
          <Col xs={6} className="col-padding-zero">
            {key}
          </Col>
          <Col xs={6} style={{alignItems: 'center'}}>
            {serviceRequest[key]}
          </Col>
        </Row>
      );
    }
  });

  //Add the jobIds of the own applications
  if (
    selectedProvider &&
    role === 'Service Provider' &&
    serviceRequest.jobIds.length > 0
  ) {
    const jobIds = serviceRequest['jobIds'].filter(id => {
      const job = jobs.find(j => j.jobId === id);
      return (
        job?.clientId === selectedProvider?.providerId ||
        job?.contractorId === selectedProvider?.providerId
      );
    });
    serviceRequest = {...serviceRequest, jobIds: jobIds.toString()};
    content.push(
      <Row key={''}>
        <Col xs={6} className="col-padding-zero">
          jobIds
        </Col>
        <Col xs={6} style={{alignItems: 'center'}}>
          <Col xs={6} style={{alignItems: 'center', padding: 0}}>
            {jobIds.map(jobId => {
              if (jobId !== jobIds[jobIds.length - 1])
                return <Link key={jobId} to={`/jobs/${jobId}`}>{`${jobId}, `}</Link>;
              else
                return (
                  <Link key={jobId} to={`/jobs/${jobId}`}>
                    {jobId}
                  </Link>
                );
            })}
          </Col>
        </Col>
      </Row>
    );
  }

  const detailsButton =
    handleMoreDetails && props.serviceRequest ? (
      <IconButton
        iconClass="pe-7s-more"
        tooltipText="more Details"
        onClick={() => handleMoreDetails()}
        style={{fontSize: 19}}
      />
    ) : null;

  const deleteButton =
    handleDeleteServiceRequest && props.serviceRequest && role === 'Mobility Provider' ? (
      <IconButton
        iconClass="pe-7s-close-circle"
        tooltipText="delete ServiceRequest"
        onClick={() => handleDeleteServiceRequest()}
        style={{fontSize: 19}}
      />
    ) : (
      false
    );

  const editButton =
    handleEditServiceRequest && props.serviceRequest && role === 'Mobility Provider' ? (
      <IconButton
        iconClass="pe-7s-pen"
        tooltipText="edit ServiceRequest"
        onClick={() => handleEditServiceRequest()}
        style={{fontSize: 19, margin: 0}}
      />
    ) : (
      false
    );

  const applyButton =
    handleApplyForServiceRequest &&
    props.serviceRequest &&
    role === 'Service Provider' &&
    !isOwner ? (
      <IconButton
        iconClass="pe-7s-mail"
        tooltipText="apply for request"
        onClick={() => handleApplyForServiceRequest()}
        style={{fontSize: 19}}
        className="btn"
      />
    ) : (
      false
    );

  return (
    <Card>
      <Card.Header>Service Request Details</Card.Header>
      <Card.Content>{content}</Card.Content>
      <Card.Legend>
        <div style={{display: 'flex'}}>
          {deleteButton}
          {editButton}
          {detailsButton}
          {applyButton}
        </div>
      </Card.Legend>
    </Card>
  );
};

const mapStateToProps = (state, ownProps) => ({
  serviceRequest: ownProps.serviceRequest,
  handleEditServiceRequest: ownProps.onEditServiceRequest,
  handleDeleteServiceRequest: ownProps.onDeleteServiceRequest,
  handleMoreDetails: ownProps.onMoreDetails,
  handleApplyForServiceRequest: ownProps.onApplyForServiceRequest,
});

export default connect(mapStateToProps)(ServiceRequestDetailsCard);
