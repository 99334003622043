//flow
import React, {useState} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {Col} from 'react-bootstrap';
import Card from '../../components/Card';
import {connect} from 'react-redux';
import MapCard from '../../components/MapCard/MapCard';
import NotFound from '../../components/NotFound/NotFound';
import VehicleDetailsCard from '../../components/VehicleDetailsCard';
import JobDetailsCard from '../../components/JobDetailsCard';
import useFetchJobById from '../../hooks/useFetchJobById';
import {updateJob} from '../../stores/job/job.actions';
import JobEditCard from './JobEditCard';
import {IconSelector} from '../../components/IconSelector/IconSelector';
import useFetchVehicleById from '../../hooks/useFetchVehicleById';

const JobDetails = ({deleteJob, updateJob, selectedJob}) => {
  const {id} = useParams();
  const history = useHistory();
  const [jobs, j_error, j_isLoading] = useFetchJobById(id);
  if (!selectedJob) {
    selectedJob = id;
  }
  const [vehicle, v_error, v_isLoading] = useFetchVehicleById(selectedJob.vehicleId);
  const [editMode, changeEditMode] = useState(false);

  if (!selectedJob || (!vehicle && !v_error) || v_isLoading) {
    return (
      <div className="main-content">
        <Card>
          <Card.Header>Loading...</Card.Header>
          <Card.Content isLoading={v_isLoading || j_isLoading} />
        </Card>
      </div>
    );
  }

  if (v_error) {
    return <NotFound entityName={'vehicle'} />;
  }

  var icon = IconSelector({vehicleType: vehicle.vehicleType, obj: 'job'});
  const vehicleMarker = {...vehicle, icon: icon};

  const handleSubmit = values => {
    updateJob(values)
      .then(changeEditMode(false))
      .catch(error => {
        console.log(error);
      });
  };

  const JobCard = editMode ? (
    <JobEditCard job={selectedJob} onSubmit={values => handleSubmit(values)} />
  ) : (
    <JobDetailsCard
      job={selectedJob}
      onEditJob={() => handleEditJob()}
      onApplyForJob={() =>
        history.push({
          pathname: '/job/new',
          state: {jobId: selectedJob.jobId},
        })
      }
    />
  );

  const handleEditJob = () => {
    changeEditMode(true);
  };

  return (
    <div className="main-content">
      <Col lg={4} className="col-padding">
        <Col lg={12} className="col-padding">
          {JobCard}
        </Col>
        <Col lg={12} className="col-padding">
          <VehicleDetailsCard vehicle={vehicle} />
        </Col>
      </Col>
      <Col lg={8} className="col-padding">
        <Col lg={12} className="col-padding">
          <MapCard
            title="Vehicle Map"
            data={{markers: [vehicleMarker]}}
            selectedMarker={vehicleMarker}
            mapHeight="50vh"
          />
        </Col>
      </Col>
    </div>
  );
};

const mapStateToProps = state => ({selectedJob: state.Jobs.selectedJob});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateJob: job => dispatch(updateJob(job)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobDetails);
