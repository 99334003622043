import React from 'react';
import {Field, reduxForm, change, initialize} from 'redux-form';
import renderField from '../../components/FormField';
import {ControlLabel, FormGroup} from 'react-bootstrap';
import TextButton from '../../components/TextButton';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

const unit = '€';

const OptimalReolcationForm = ({
  handleSubmit,
  initialValues,
  price,
  budget,
  ...props
}) => {
  const addUnit = (e, newValue) => {
    e.preventDefault();
    const i = newValue.indexOf('.');
    let asFloat;
    if (newValue.length === 0) asFloat = '0.00';
    else if (i < 0) asFloat = newValue + '.00';
    else if (newValue.length - 1 === i) asFloat = newValue + '00';
    else if (i === newValue.length - 2) asFloat = newValue + '0';
    else asFloat = newValue;
    if (e.currentTarget.name === 'price')
      props.change('optimalRelocationForm', 'price', asFloat + unit);
    else props.change('optimalRelocationForm', 'budget', asFloat + unit);
  };

  const removeUnit = e => {
    const value = e.target.value;
    if (value !== undefined && value.length > 0)
      if (e.currentTarget.name === 'price')
        props.change(
          'optimalRelocationForm',
          'price',
          value.substring(0, value.length - 1)
        );
      else
        props.change(
          'optimalRelocationForm',
          'budget',
          value.substring(0, value.length - 1)
        );
  };

  const checkIfNumber = (e, value, prevValue) => {
    if (typeof value === 'undefined') return '0.00€';
    const i = value.indexOf('.');
    const decimals = i > 0 ? value.length - 1 - value.indexOf('.') : 0;
    const tryToAddDigit = value.length > prevValue.length;

    return isNaN(value) ||
      (isNaN(parseFloat(value)) && value !== '') ||
      (decimals > 2 && tryToAddDigit)
      ? e.preventDefault()
      : value;
  };

  return (
    <form onSubmit={handleSubmit}>
      <ControlLabel style={{marginTop: '5px'}}>Max Budget ?</ControlLabel>
      <Field
        type="text"
        name="budget"
        placeholder="00.00€"
        onChange={(e, value, prevValue) => checkIfNumber(e, value, prevValue)}
        onFocus={e => removeUnit(e)}
        onBlur={(e, newValue) => addUnit(e, newValue)}
        component={renderField}
      />
      <ControlLabel style={{marginTop: '5px'}}>Price per kilometer</ControlLabel>
      <Field
        type="text"
        name="price"
        placeholder="00.00€"
        onChange={(e, value, prevValue) => checkIfNumber(e, value, prevValue)}
        onFocus={e => removeUnit(e)}
        onBlur={(e, newValue) => addUnit(e, newValue)}
        component={renderField}
      />

      <ControlLabel style={{marginTop: '10px'}}>Get Recommendations </ControlLabel>
      <div>
        <TextButton caption="Submit" type="submit" />
      </div>
    </form>
  );
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({change, initialize}, dispatch);
};

const mapStateToProps = state => ({
  price: state.form.optimalRelocationForm.price,
  budget: state.form.optimalRelocationForm.budget,
});

export default reduxForm({
  form: 'optimalRelocationForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(connect(mapStateToProps, mapDispatchToProps)(OptimalReolcationForm));
