import L from 'leaflet';

const bikeMarker = L.icon({
  iconUrl: require('../../assets/images/bikeMarker.png'),
  iconSize: [50, 54.6], // size of the icon
  iconAnchor: [25, 54.6], // point of the icon which will correspond to marker's location
});

const bikeMarker_red = L.icon({
  iconUrl: require('../../assets/images/bikeMarker_red.png'),
  iconSize: [50, 54.6],
  iconAnchor: [25, 54.6],
});

const bikeMarker_blue = L.icon({
  iconUrl: require('../../assets/images/bikeMarker_blue.png'),
  iconSize: [50, 54.6],
  iconAnchor: [25, 54.6],
});

const bikeMarker_orange = L.icon({
  iconUrl: require('../../assets/images/bikeMarker_orange.png'),
  iconSize: [50, 54.6],
  iconAnchor: [25, 54.6],
});

const bikeMarker_purple = L.icon({
  iconUrl: require('../../assets/images/bikeMarker_purple.png'),
  iconSize: [50, 54.6],
  iconAnchor: [25, 54.6],
});
const bikeMarkerJob = L.icon({
  iconUrl: require('../../assets/images/bikeMarkerJob.png'),
  iconSize: [53.4, 57.4],
  iconAnchor: [25, 57.4],
});
const bikeMarkerJob_orange = L.icon({
  iconUrl: require('../../assets/images/bikeMarkerJob_orange.png'),
  iconSize: [53.4, 57.4],
  iconAnchor: [25, 57.4],
});
const bikeMarkerJob_purple = L.icon({
  iconUrl: require('../../assets/images/bikeMarkerJob_purple.png'),
  iconSize: [53.4, 57.4],
  iconAnchor: [25, 57.4],
});
const bikeMarkerJob_blue = L.icon({
  iconUrl: require('../../assets/images/bikeMarkerJob_blue.png'),
  iconSize: [53.4, 57.4],
  iconAnchor: [25, 57.4],
});
const bikeMarkerJob_green = L.icon({
  iconUrl: require('../../assets/images/bikeMarkerJob_green.png'),
  iconSize: [53.4, 57.4],
  iconAnchor: [25, 57.4],
});
const bikeMarkerJob_red = L.icon({
  iconUrl: require('../../assets/images/bikeMarkerJob_red.png'),
  iconSize: [53.4, 57.4],
  iconAnchor: [25, 57.4],
});
const bikeMarkerMaintain_red = L.icon({
  iconUrl: require('../../assets/images/bikeMarkerMaintain_red.png'),
  iconSize: [54.2, 58.2],
  iconAnchor: [25, 58.2],
});
const bikeMarkerMaintain_orange = L.icon({
  iconUrl: require('../../assets/images/bikeMarkerMaintain_orange.png'),
  iconSize: [54.2, 58.2],
  iconAnchor: [25, 58.2],
});
const bikeMarkerMaintain_blue = L.icon({
  iconUrl: require('../../assets/images/bikeMarkerMaintain_blue.png'),
  iconSize: [54.2, 58.2],
  iconAnchor: [25, 58.2],
});
const bikeMarkerMaintain_green = L.icon({
  iconUrl: require('../../assets/images/bikeMarkerMaintain_green.png'),
  iconSize: [54.2, 58.2],
  iconAnchor: [25, 58.2],
});
const bikeMarkerMaintain_purple = L.icon({
  iconUrl: require('../../assets/images/bikeMarkerMaintain_purple.png'),
  iconSize: [54.2, 58.2],
  iconAnchor: [25, 58.2],
});
const bikeMarkerMaintain = L.icon({
  iconUrl: require('../../assets/images/bikeMarkerMaintain.png'),
  iconSize: [54.2, 58.2],
  iconAnchor: [25, 58.2],
});

const scooterMarker = L.icon({
  iconUrl: require('../../assets/images/scooterMarker.png'),
  iconSize: [50, 54.6],
  iconAnchor: [25, 54.6],
});

const scooterMarker_red = L.icon({
  iconUrl: require('../../assets/images/scooterMarker_red.png'),
  iconSize: [50, 54.6],
  iconAnchor: [25, 54.6],
});

const scooterMarker_blue = L.icon({
  iconUrl: require('../../assets/images/scooterMarker_blue.png'),
  iconSize: [50, 54.6],
  iconAnchor: [25, 54.6],
});

const scooterMarker_orange = L.icon({
  iconUrl: require('../../assets/images/scooterMarker_orange.png'),
  iconSize: [50, 54.6],
  iconAnchor: [25, 54.6],
});

const scooterMarker_purple = L.icon({
  iconUrl: require('../../assets/images/scooterMarker_purple.png'),
  iconSize: [50, 54.6],
  iconAnchor: [25, 54.6],
});
const scooterMarkerJob = L.icon({
  iconUrl: require('../../assets/images/scooterMarkerJob.png'),
  iconSize: [53.4, 57.4],
  iconAnchor: [25, 57.4],
});
const scooterMarkerJob_green = L.icon({
  iconUrl: require('../../assets/images/scooterMarkerJob_green.png'),
  iconSize: [53.4, 57.4],
  iconAnchor: [25, 57.4],
});
const scooterMarkerJob_orange = L.icon({
  iconUrl: require('../../assets/images/scooterMarkerJob_orange.png'),
  iconSize: [53.4, 57.4],
  iconAnchor: [25, 57.4],
});
const scooterMarkerJob_purple = L.icon({
  iconUrl: require('../../assets/images/scooterMarkerJob_purple.png'),
  iconSize: [53.4, 57.4],
  iconAnchor: [25, 57.4],
});
const scooterMarkerJob_blue = L.icon({
  iconUrl: require('../../assets/images/scooterMarkerJob_blue.png'),
  iconSize: [53.4, 57.4],
  iconAnchor: [25, 57.4],
});
const scooterMarkerJob_red = L.icon({
  iconUrl: require('../../assets/images/scooterMarkerJob_red.png'),
  iconSize: [53.4, 57.4],
  iconAnchor: [25, 57.4],
});
const scooterMarkerMaintain_red = L.icon({
  iconUrl: require('../../assets/images/scooterMarkerMaintain_red.png'),
  iconSize: [54.2, 58.2],
  iconAnchor: [25, 58.2],
});
const scooterMarkerMaintain_orange = L.icon({
  iconUrl: require('../../assets/images/scooterMarkerMaintain_orange.png'),
  iconSize: [54.2, 58.2],
  iconAnchor: [25, 58.2],
});
const scooterMarkerMaintain_blue = L.icon({
  iconUrl: require('../../assets/images/scooterMarkerMaintain_blue.png'),
  iconSize: [54.2, 58.2],
  iconAnchor: [25, 58.2],
});
const scooterMarkerMaintain_green = L.icon({
  iconUrl: require('../../assets/images/scooterMarkerMaintain_green.png'),
  iconSize: [54.2, 58.2],
  iconAnchor: [25, 58.2],
});
const scooterMarkerMaintain_purple = L.icon({
  iconUrl: require('../../assets/images/scooterMarkerMaintain_purple.png'),
  iconSize: [54.2, 58.2],
  iconAnchor: [25, 58.2],
});
const scooterMarkerMaintain = L.icon({
  iconUrl: require('../../assets/images/scooterMarkerMaintain.png'),
  iconSize: [54.2, 58.2],
  iconAnchor: [25, 58.2],
});

const event_icon = L.icon({
  iconUrl: require('../../assets/images/event_icon.png'),
  iconSize: [30, 30],
  iconAnchor: [16, 10],
});

const station_icon = L.icon({
  iconUrl: require('../../assets/images/Station.png'),
  iconSize: [30, 30],
  iconAnchor: [1.5, 30],
});

export {
  bikeMarker,
  bikeMarker_blue,
  bikeMarker_orange,
  bikeMarker_red,
  bikeMarker_purple,
  bikeMarkerJob,
  bikeMarkerJob_orange,
  bikeMarkerJob_red,
  bikeMarkerJob_blue,
  bikeMarkerJob_green,
  bikeMarkerJob_purple,
  bikeMarkerMaintain_blue,
  bikeMarkerMaintain_orange,
  bikeMarkerMaintain_purple,
  bikeMarkerMaintain_red,
  bikeMarkerMaintain_green,
  bikeMarkerMaintain,
  scooterMarker,
  scooterMarkerJob,
  scooterMarker_blue,
  scooterMarker_orange,
  scooterMarker_red,
  scooterMarker_purple,
  scooterMarkerJob_orange,
  scooterMarkerJob_red,
  scooterMarkerJob_green,
  scooterMarkerJob_blue,
  scooterMarkerJob_purple,
  scooterMarkerMaintain_blue,
  scooterMarkerMaintain_green,
  scooterMarkerMaintain_orange,
  scooterMarkerMaintain_purple,
  scooterMarkerMaintain_red,
  scooterMarkerMaintain,
  event_icon,
  station_icon,
};
