import axios from 'axios';

export const createServiceOffer = async (
  title,
  description,
  serviceType,
  operationArea
) => {
  const resp = await axios.post('api/contracts/createserviceoffer', {
    title: title,
    description: description,
    serviceType: serviceType,
    operationArea: operationArea,
  });
  return resp.data;
};
