import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import {
  fetchJobByIdFailure,
  fetchJobByIdStarted,
  fetchJobByIdSuccess,
} from '../stores/job/job.actions';
import {getJobById} from '../contracts/JobRegistry/contractMethods/getJobById';

export default function useFetchJobById(id) {
  const dispatch = useDispatch();
  const {byId, error, loading, lastUpdated} = useSelector(state => state.Jobs);
  const data = byId[id];
  useEffect(() => {
    const source = axios.CancelToken.source();
    const TTL = 1000 * 60;

    const wasFetchedBefore = lastUpdated;
    const isExpired =
      wasFetchedBefore && new Date().getTime() - lastUpdated.getTime() > TTL;
    if ((wasFetchedBefore && !isExpired) || loading) return;

    const fetchJobById = async () => {
      try {
        dispatch(fetchJobByIdStarted());

        const job = await getJobById(id);
        dispatch(fetchJobByIdSuccess(job));
      } catch (error) {
        dispatch(fetchJobByIdFailure(error));
      }
    };

    fetchJobById();

    return () => source.cancel();
  }, [dispatch, id, lastUpdated, loading]);

  return [data, error, loading];
}
