import React from 'react';
import GoogleLogin from 'react-google-login';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {onLogin, onNewToken} from '../../stores/user/user.actions';
import axios from 'axios';
import {fetchRolesByAddress} from '../../stores/user/user.actions';
import {showNotification} from '../../stores/ui/layout.reducer';
import {notificationsOptions} from '../../helpers';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';

const Login = ({showNotification, onNewToken, fetchRolesByAddress, onLogin}) => {
  const history = useHistory();

  // Use google response to authorize the user at the backend
  const responseGoogle = async response => {
    console.log('Google call', axios.defaults.baseURL);
    axios.defaults.baseURL =
      process.env.NODE_ENV !== 'production'
        ? process.env.REACT_APP_API_BASEURL.split(',')[0]
        : '/';
    const rsp = await axios.post('api/signup', {
      token: response.tokenId,
    });

    const {user, token} = rsp.data;

    if (rsp.data.name !== 'Error') {
      /* the token is used to identify the user on the backend side. 
      The token is set as the standard token for all backend calls during this session*/
      onNewToken(token);

      const result = await fetchRolesByAddress(); // The provider which belong to the users ethereum address are fetched
      const payload = result.payload;

      /* If the provider list is empty an error is shown. If the providerlist contains at least one provider,
      the first provider from the list is selected automatically*/

      let notificationsOps;
      if (result.type === 'FETCH_PROVIDER_ROLES_FAILURE' || result.allIds.length === 0) {
        notificationsOps = notificationsOptions(
          'info',
          'Fetch Providers',
          'There are currently no providers linked to your account',
          {
            label: 'Create a new provider',
            callback: () => history.push('/settings'),
          }
        );
      } else if (result.type === 'FETCH_PROVIDER_ROLES_SUCCESS') {
        notificationsOps = notificationsOptions(
          'success',
          'Fetch Providers',
          `You are currently using ${
            Object.values(payload)[0].providerName
          } as provider. \n
           You can create and select other providers on the settings page`
        );
      }
      showNotification(notificationsOps);
      onLogin(user.id, user.email, user.familyName, user.givenName, user.imageUrl);
    }
  };

  const citation = 'Make Mobility Great Again';

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div
        style={{
          height: '45vh',
          backgroundColor: '#0d4d9940',
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'center',
        }}
      >
        <img
          alt="CityBeamerLogo"
          src="CityBeamer_Logo_2.png"
          style={{
            position: 'relative',
            top: '4vh',
            height: '40%',
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '45vh',
        }}
      >
        <GoogleLogin
          clientId={process.env.REACT_APP_CLIENT_ID}
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={'single_host_origin'}
        />
      </div>

      <div
        className="bg"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-end',
        }}
      >
        <p
          style={{
            fontFamily: 'Montserrat Alternates',
            textTransform: 'uppercase',
            alignSelf: 'center',
          }}
        >
          {citation}
        </p>
        <p style={{fontSize: 'small', alignSelf: 'flex-end', marginRight: '1vw'}}></p>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  showNotification: notificationsOps => dispatch(showNotification(notificationsOps)),
  fetchRolesByAddress: () => dispatch(fetchRolesByAddress()),
  onNewToken: newToken => dispatch(onNewToken(newToken)),
  onLogin: (id, email, familyName, givenName, imageUrl) =>
    dispatch(onLogin(id, email, familyName, givenName, imageUrl)),
});

export default withRouter(connect(null, mapDispatchToProps)(Login));
