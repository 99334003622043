import {reducer as formReducer} from 'redux-form';
import {reducer as notifications} from 'react-notification-system-redux';
import ThemeOptions from './ui/theme.reducer';
import Layout from './ui/layout.reducer';
import User from './user/user.reducer';
import ServiceOffers, {
  ADD_SERVICE_OFFER_SUCCESS,
} from './serviceOffer/serviceOffer.reducer';
import Vehicles from './vehicle/vehicle.reducer';
import Settings from './user/setting.reducer';
import Jobs from './job/job.reducer';
import ServiceRequests from './serviceRequest/serviceRequest.reducer';
import Providers from './provider/provider.reducer';
import Events from './events/events.reducer';
import Stations from './station/station.reducer';
import {combineReducers} from 'redux';

const customFormReducer = formReducer.plugin({
  serviceForm: (state, action) => {
    switch (action.type) {
      case ADD_SERVICE_OFFER_SUCCESS:
        return state;
      default:
        return state;
    }
  },
  jobForm: (state, action) => {
    switch (action.type) {
      default:
        return state;
    }
  },
  serviceRequestForm: (state, action) => {
    switch (action.type) {
      default:
        return state;
    }
  },
  searchServiceOfferForm: (state, action) => {
    switch (action.type) {
      default:
        return state;
    }
  },
  optimalRelocationForm: (state, action) => {
    switch (action.type) {
      default:
        return state;
    }
  },
});

export const appReducer = combineReducers({
  ThemeOptions,
  Layout,
  notifications,
  form: customFormReducer,
  User,
  Vehicles,
  ServiceOffers,
  Settings,
  Jobs,
  ServiceRequests,
  Providers,
  Events,
  Stations,
});
