import axios from 'axios';
import {error as showError} from 'react-notification-system-redux';

export default store => {
  const baseUrls =
    process.env.NODE_ENV !== 'production'
      ? process.env.REACT_APP_API_BASEURL.split(',')
      : '/';
  axios.defaults.baseURL = baseUrls[0];

  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};
