import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import {
  fetchVehicleByIdFailure,
  fetchVehicleByIdStarted,
  fetchVehicleByIdSuccess,
} from '../stores/vehicle/vehicle.actions';

import {getVehicleById} from '../contracts/VehicleRegistry/contractMethods/getVehicleById';

export default function useFetchVehicleById(id) {
  const dispatch = useDispatch();
  const {byId, error, loading, lastUpdated} = useSelector(state => state.Vehicles);
  const data = byId[id];

  useEffect(() => {
    const source = axios.CancelToken.source();
    const TTL = 1000 * 60;

    const wasFetchedBefore = lastUpdated;
    const isExpired =
      wasFetchedBefore && new Date().getTime() - lastUpdated.getTime() > TTL;
    if ((wasFetchedBefore && !isExpired) || loading || !id) return;

    const fetchVehicleById = async () => {
      try {
        dispatch(fetchVehicleByIdStarted());

        let vehicle = await getVehicleById(id);
        let currentPosition = vehicle.currentPosition;

        dispatch(fetchVehicleByIdSuccess({...vehicle, currentPosition}));
      } catch (error) {
        dispatch(fetchVehicleByIdFailure(error));
      }
    };

    fetchVehicleById();

    return () => source.cancel();
  }, [dispatch, id, lastUpdated, loading]);

  return [data, error, loading];
}
