// @flow

import axios from 'axios';
import {REHYDRATE} from 'redux-persist';
import {SET_BACKEND_BASE_URL} from './setting.reducer';
import {Middleware} from 'redux';

const axiosHandler: Middleware = () => next => action => {
  switch (action.type) {
    case 'ON_NEW_TOKEN':
      axios.defaults.headers.common['Authorization'] = `Bearer ${action.idToken}`;
      break;
    case 'USER_LOGOUT':
      axios.defaults.headers.common['Authorization'] = undefined;
      break;
    case SET_BACKEND_BASE_URL:
      //axios.defaults.baseURL = action.backendBaseUrl;
      break;
    case REHYDRATE:
      const hasUser = action.payload && action.payload.User;
      const hasIdToken = hasUser && action.payload.User.idToken;
      if (hasIdToken) {
        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${action.payload.User.idToken}`;
      }

      const hasSettings = action.payload && action.payload.Settings;
      if (hasSettings) {
        axios.defaults.baseURL = action.payload.Settings.backendBaseUrl;
      }
      break;
    default:
  }
  return next(action);
};

export default axiosHandler;
