import {
  FETCH_STATIONS_FAILURE,
  FETCH_STATIONS_STARTED,
  FETCH_STATIONS_SUCCESS,
  FETCH_STATION_BY_ID_FAILURE,
  FETCH_STATION_BY_ID_STARTED,
  FETCH_STATION_BY_ID_SUCCESS,
  UPDATE_SELECTED_STATION,
} from './station.reducer';

//fetch Stations
export const fetchStationsStarted = () => ({
  type: FETCH_STATIONS_STARTED,
});
export const fetchStationsSuccess = (stations, allIds) => ({
  type: FETCH_STATIONS_SUCCESS,
  payload: stations,
  allIds: allIds,
});
export const fetchStationsFailure = error => ({
  type: FETCH_STATIONS_FAILURE,
  payload: error,
});
//fetchStationsById
export const fetchStationByIdStarted = () => ({
  type: FETCH_STATION_BY_ID_STARTED,
});
export const fetchStationByIdSuccess = station => ({
  type: FETCH_STATION_BY_ID_SUCCESS,
  payload: station,
});
export const fetchStationByIdFailure = error => ({
  type: FETCH_STATION_BY_ID_FAILURE,
  payload: error,
});

export const updateSelectedStation = station => {
  return {
    type: UPDATE_SELECTED_STATION,
    payload: station,
  };
};
